import React from 'react';
import ReactDOM from 'react-dom';
import { Spacer } from '../../screens/delivery/delivery.styles';
import { Button, Variant } from '../button';
import { ButtonRow } from '../button-row';
import {
    ButtonContainer,
    CloseModalButton,
    Header,
    ModalWrapper,
    Root,
    Shim,
    Text,
    Link,
} from './info-popup.styles';

interface InfoPopupProps {
    text?: string;
    header?: string;
    closePress?: () => void;
    onSubmit?: () => void;
    positiveButtonText?: string;
    textComponent?: React.ReactNode;
    mobileOnly?: boolean;
    links?: Array<{ text: string; link: string }>;
    link?: string;
    linkText?: string;
    centerButtons?: boolean;
}

const rootElement = document.getElementById('root');

export function InfoPopupComponent(props: InfoPopupProps) {
    const {
        text,
        header,
        closePress,
        onSubmit,
        positiveButtonText = 'Ok',
        textComponent,
        link,
        links,
        linkText,
        centerButtons = false,
    } = props;
    return (
        <ModalWrapper actionModal={!!onSubmit}>
            {closePress && !onSubmit && <CloseModalButton onClick={closePress}>X</CloseModalButton>}
            <Header>{header}</Header>
            {textComponent || (
                <Text>
                    {text}
                    {link && <Link href={link}>{linkText || link}</Link>}
                    {!link &&
                        links &&
                        links.map((linkConfig, i) => (
                            <p key={`info-popup-link-${i}`}>
                                {linkConfig.text} <Link href={linkConfig.link}>{linkText}</Link>.
                            </p>
                        ))}
                </Text>
            )}
            {onSubmit && (
                <ButtonContainer>
                    <ButtonRow>
                        {closePress ? (
                            <Button variant={Variant.Negative} onClick={() => closePress()}>
                                Avbryt
                            </Button>
                        ) : centerButtons ? null : (
                            <Spacer />
                        )}
                        {onSubmit && (
                            <Button onClick={() => onSubmit()}>{positiveButtonText}</Button>
                        )}
                    </ButtonRow>
                </ButtonContainer>
            )}
        </ModalWrapper>
    );
}

export function InfoPopup({ closePress, mobileOnly, ...rest }: InfoPopupProps) {
    return ReactDOM.createPortal(
        <Root mobileOnly={mobileOnly}>
            <Shim onClick={closePress} />
            <InfoPopupComponent {...rest} closePress={closePress} />
        </Root>,
        rootElement as Element
    );
}

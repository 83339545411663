import { InfoMessageBox } from './info-message.styles';
import { ModalWrapper, Text } from '../info-popup/info-popup.styles';

export const InfoMessage = ({ text }: { text: string }) => {
    return (
        <InfoMessageBox>
            <ModalWrapper>
                <Text>{text}</Text>
            </ModalWrapper>
        </InfoMessageBox>
    );
};

import { Bzzt } from '../types';

export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const containsNumber = (str: string) => {
    return /.*[0-9].*/.test(str);
};

export const ItemTypeTranslation: Record<Bzzt.ItemType, { singular: string; plural: string }> = {
    [Bzzt.ItemType.Box]: {
        singular: 'paket',
        plural: 'paket',
    },
    [Bzzt.ItemType.Bag]: {
        singular: 'kasse',
        plural: 'kassar',
    },
    [Bzzt.ItemType.Other]: {
        singular: 'annat',
        plural: 'annat',
    },
    [Bzzt.ItemType.Parcel]: {
        singular: 'paket',
        plural: 'paket',
    },
};

import { Bzzt } from '../../types';
import { TimeButton, TimeButtonLeft, TimeButtonRight } from './time.styles';

type NonBookableTimeProps = {
    deliveryOffer: Bzzt.DeliveryOffer;
};

const NonBookableTime: React.FC<NonBookableTimeProps> = ({ deliveryOffer }) => {
    return (
        <TimeButton selected={false}>
            <TimeButtonLeft>
                <div style={{ padding: '0px 5px', fontSize: '0.5rem' }}>
                    {/* deliveryOffer.message */ 'Ej bokningsbar'}
                </div>
            </TimeButtonLeft>
            <TimeButtonRight>{deliveryOffer.priceCategory}</TimeButtonRight>
        </TimeButton>
    );
};

export default NonBookableTime;

import { Icon } from '../icon';
import {
    CheckboxWrapper,
    Label,
    HiddenCheckbox,
    StyledCheckbox,
    CheckboxCont,
} from './checkbox.styles';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    reverse?: boolean;
    noMargin?: boolean;
    labelAlign?: 'left' | 'right';
}

export function Checkbox({
    label,
    reverse = false,
    noMargin = false,
    checked = false,
    labelAlign = 'left',
    ...rest
}: CheckboxProps) {
    return (
        <CheckboxWrapper noMargin={noMargin} reverse={reverse}>
            {/* <Input {...rest} reverse={reverse} type="checkbox" checked={checked} /> */}
            <CheckboxCont>
                <HiddenCheckbox type="checkbox" checked={checked} {...rest} />
                <StyledCheckbox>
                    {checked && <Icon type="checkbox" size="xs" absolute />}
                </StyledCheckbox>
            </CheckboxCont>

            <Label align={labelAlign}>{label}</Label>
        </CheckboxWrapper>
    );
}

import styled from 'styled-components';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';

export const ContactList = styled.div`
    width: 100%;
`;

export const ContactListItem = styled.div<{ header?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    &:nth-child(even) {
        background-color: ${colors.lightyellow};
    }
    cursor: ${({ header }) => (header ? 'default' : 'pointer')};

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        // border: 1px solid ${colors.black};
        // border-radius: 5px;
        // margin-bottom: 10px;
        &:first-child {
            display: none;
        }
    }
`;

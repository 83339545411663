import { Icon } from '../icon';
import { Root, Input } from './radio-button.styles';

export function RadioButton(props: any) {
    const { icon, checked, size, onClick, ...rest } = props;

    return (
        <Root {...rest}>
            <Input type="radio" checked={checked} {...rest} onClick={onClick} />
            {!!icon && <Icon type={icon} checked={checked} size={size} isButton />}
        </Root>
    );
}

import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { AppContainer } from './app.styles';
import { useBookingStore } from './stores/useBookingStore';
import { useUserStore } from './stores/useUserStore';
import { Authenticated } from './routers/authenticated';
import { PrivateRoute } from './utils/private-route';
import { Logout } from './screens/logout';
import { Login } from './screens/login';
import { Header } from './fragments/header';

export const App = () => {
    const history = useHistory();
    const userStore = useUserStore();
    const bookingStore = useBookingStore();

    useEffect(() => {
        // If the user is unauthenticated, redirect to login
        if (userStore.unauthenticated()) {
            return history.push('/login');
        }

        // Ignore, we only want this to run once on app start
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userStore.token && !bookingStore.initialized()) {
            bookingStore
                .initialize(userStore.token)
                .then(() => {})
                .catch(() => {});
        }
    }, [userStore.token, bookingStore]);

    return (
        <AppContainer>
            <Header />
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/logout" component={Logout} />
                <PrivateRoute path="/" component={Authenticated} />
            </Switch>
        </AppContainer>
    );
};

import { RouteComponentProps, useParams } from 'react-router-dom';
import { Screen } from '../../structure/screen';
import { Root, Text } from './page.styles';
import { Title } from '../../fragments/title';
import { content } from './page.content';

export const Page: React.FC<RouteComponentProps> = () => {
    const { slug }: any = useParams();

    const pageContent = content.find((page) => page.slug === slug);

    return (
        (pageContent && (
            <Screen
                topContent={
                    <Title marginTop={'1rem'} noPaddingBottom={true}>
                        {pageContent.title}
                    </Title>
                }
                content={
                    <Root>
                        <Text dangerouslySetInnerHTML={{ __html: pageContent.content }}></Text>
                    </Root>
                }
            />
        )) || <Screen topContent={'Page not found'} />
    );
};

import { Svg } from '../icon.styles';
import { colors } from '../../../colors';

export const ArrowRound = () => (
    <Svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 273 268">
        <path
            fill={colors.black}
            d="M124 .6C70.2 5.7 24.2 41.5 7.4 91.6c-6.5 19.5-8.5 45.8-4.9 65.9 4.8 26.8 17.4 50.8 37 70.6 19.1 19.3 42 31.5 70.5 37.6 11.7 2.4 38.9 2.4 51.3 0 49.3-9.9 89.2-44.3 104.2-90.1 5.2-16 6.9-28.1 6.2-46.5-.4-12.1-1.1-17.9-3.1-25.9-6.4-25.6-18.6-46.4-37.9-64.8C211.5 20 188.3 8 161.9 2.9c-9.7-1.8-29.5-3-37.9-2.3zm37 111.6c32.2.1 58.8.3 59.3.5.4.3-1.6 3.8-4.4 7.9C195 150.8 146 198.9 136 199c-2.4 0-32.9-27.7-55-49.9-18.8-18.9-26.1-27.2-29.4-33.6-1.4-2.8-1.4-3 .2-3.7.9-.4 12.7-.5 26.2-.2s50.8.6 83 .6z"
        />
    </Svg>
);

import styled from 'styled-components';
import { dimensions } from '../../dimensions';

export const Root = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50vw;
    width: 100%;
    @media (max-width: ${dimensions.tabletBreakpoint}) {
        width: 100%;
    }
    & > * {
        margin: 0px 20px;
    }

    @media print {
        display: none;
    }
`;

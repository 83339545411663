import styled from 'styled-components';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';

export const Root = styled.div<{ mobileOnly?: boolean }>`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
    display: ${({ mobileOnly }) => (mobileOnly ? 'none' : 'flex')};
    @media (max-width: ${dimensions.mobileBreakpoint}) {
        display: flex;
    }
`;

export const Shim = styled.div`
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
`;

export const ModalWrapper = styled.div<{ actionModal?: boolean }>`
    position: relative;
    display: flex;
    text-align: left;
    flex-direction: column;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: ${({ actionModal }) => (actionModal ? colors.white : colors.yellow)};
    color: #000;
    border-radius: 15px;
    padding: 40px;
    max-width: 90vw;
    min-width: 500px;

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        padding: 20px;
        min-width: 400px;
    }

    @media (max-width: ${dimensions.mobileBreakpointExtraSmall}) {
        padding: 20px;
        min-width: calc(100% - 40px - 20px);
        max-width: 100vw;
        margin: 10px;
    }
`;

export const Header = styled.div`
    font-size: 1rem;
    font-family: Russo One;
    font-weight: 400;
`;

export const CloseModalButton = styled.button`
    position: absolute;
    top: 15px;
    right: 20px;
    background: ${colors.yellow};
    border: none;
    font-size: 40px;
    font-weight: bold;
    font-family: Russo One;
    cursor: pointer;
    color: ${colors.black};

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        padding: 0px;
    }
`;

export const Text = styled.pre`
    font-size: 0.7rem;
    overflow: hidden;
    white-space: pre-wrap;
    margin: 0;
`;

export const Link = styled.a`
    font-size: 0.7rem;
    color: ${colors.black};
    overflow: hidden;
    white-space: pre-wrap;
    margin: 0;
    vertical-align: bottom;
    line-height: 0.85rem;
`;

export const ButtonContainer = styled.div`
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

import { useEffect, useMemo, useState } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { BookingItem } from '../../fragments/booking-item';
import { Item } from '../../fragments/booking-item/booking-item.styles';
import { Checkbox } from '../../fragments/checkbox';
import { BookingFilter } from '../../fragments/booking-filter';
import { Title } from '../../fragments/title';
import { Screen } from '../../structure/screen';
import { BookingList, BookingListItem, Root } from './bookings.styles';
import { getBookings } from '../../utils/booking-action';
import { Bzzt } from '../../types';

interface RouteProps extends RouteComponentProps<{}>, React.Props<{}> {}

export const Bookings = ({}: RouteProps) => {
    const history = useHistory();
    const [filterStartDate, setFilterStartDate] = useState<Date>();
    const [filterEndDate, setFilterEndDate] = useState<Date>();
    const [showActive, setShowActive] = useState<Boolean>(false);
    const [bookings, setBookings] = useState<Bzzt.Booking[] | null>(null);

    useEffect(() => {
        const fetchBookings = async () => {
            const startTime = new Date(filterStartDate || new Date());
            startTime.setHours(0, 0, 0, 0);

            const endTime = new Date(filterEndDate || new Date());
            endTime.setHours(23, 59, 59, 999);

            const { data, errorMessage } = await getBookings(startTime, endTime);
            if (errorMessage) {
                return console.error('error: ', errorMessage);
            }
            if (data) {
                setBookings(data);
            }
        };
        fetchBookings();
    }, [filterEndDate, filterStartDate]);

    const filteredBookings = useMemo(() => {
        if (!bookings) return null;

        let bookingsToFilter = [...bookings];

        if (showActive) {
            bookingsToFilter = bookingsToFilter.filter((booking) => {
                return booking.state === 'booked';
            });
        }

        return bookingsToFilter;
    }, [bookings, showActive]);

    const handleBookingClicked = (id?: string) => {
        if (id) history.push(`/bookings/${id}`);
    };

    return (
        <Screen
            slim={false}
            topContent={<Title marginTop={'1rem'}>Bokningar.</Title>}
            content={
                <Root>
                    <BookingFilter
                        labelStart="Start"
                        labelEnd="Slut"
                        onChange={({ start, end }) => {
                            setFilterStartDate(start ?? filterStartDate);
                            setFilterEndDate(end ?? filterEndDate);
                        }}
                    />
                    <Checkbox
                        label="Visa endast aktiva"
                        labelAlign="right"
                        onChange={(e) => setShowActive(e.target.checked)}
                        checked={!!showActive}
                    />
                    <BookingList>
                        <BookingListItem header>
                            <Item>Orderid/Referens:</Item>
                            <Item>Status:</Item>
                            <Item>Hämtar:</Item>
                            <Item>Lämnar:</Item>
                            <Item>Till:</Item>
                            <Item>Datum:</Item>
                        </BookingListItem>
                        {filteredBookings?.map((booking: Bzzt.Booking) => (
                            <BookingItem
                                booking={booking}
                                key={booking.user}
                                onClick={() => handleBookingClicked(booking.reference)}
                            />
                        ))}
                    </BookingList>
                </Root>
            }
        />
    );
};

import { forwardRef } from 'react';
import { Icon } from '../icon';
import { IconWrapper, Wrapper, Root } from './text-input.styles';

function TextInputComponent(props: any, ref: any) {
    const { icon, ...rest } = props;

    return (
        <Wrapper icon={!!icon}>
            {!!icon && (
                <IconWrapper>
                    <Icon type={icon} size="xs" />
                </IconWrapper>
            )}
            <Root icon={!!icon} {...rest} ref={ref} />
        </Wrapper>
    );
}

export const TextInput = forwardRef(TextInputComponent);

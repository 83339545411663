import { ActionRow, Root, Content, Top } from './screen.styles';

export function Screen({
    topContent,
    content,
    actionRowContent,
    slim = true,
    compact = false,
}: {
    topContent?: React.ReactNode;
    content?: React.ReactNode;
    actionRowContent?: React.ReactNode;
    slim?: boolean;
    compact?: boolean;
}) {
    return (
        <Root slim={slim} compact={compact}>
            <Top>{topContent}</Top>
            <Content>{content}</Content>
            <ActionRow>{actionRowContent}</ActionRow>
        </Root>
    );
}

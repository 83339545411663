import { useEffect, useState } from 'react';
import { useHistory, RouteComponentProps, useParams } from 'react-router-dom';
import { Button, Variant } from '../../fragments/button';
import { ButtonRow } from '../../fragments/button-row';
import { InfoPopup } from '../../fragments/info-popup';
import { Title } from '../../fragments/title';
import { Screen } from '../../structure/screen';
import { ItemTypeTranslation } from '../../utils';
import { Root, Text, Header } from './booking.styles';
import { cancelBooking, getBooking } from '../../utils/booking-action';
import { Bzzt } from '../../types';
import { Loading } from '../loading';
import usePrintHandling from '../../hooks/usePrint';

interface RouteProps extends RouteComponentProps<{}>, React.Props<{}> {}

export const BookingDetails = ({}: RouteProps) => {
    const history = useHistory();
    const { id }: any = useParams();
    const { handlePrintById } = usePrintHandling();
    const [cancelBookingModalVisible, setCancelBookingModalVisible] = useState(false);
    const [booking, setBooking] = useState<Bzzt.Booking | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!id) return;

        const fetchBooking = async (reference: string) => {
            setLoading(true);
            const { data, errorMessage } = await getBooking(reference);
            if (errorMessage) {
                // TODO(charlieroth): Do something with error message
                setBooking(null);
            } else if (data) {
                setBooking(data);
            }
            setLoading(false);
        };

        fetchBooking(id);
    }, [id]);

    const canCancel = () => {
        return booking && booking.state === 'booked';
    };

    const handleCancelClicked = () => {
        setCancelBookingModalVisible(true);
    };

    const handleCancelConfirmation = async () => {
        const { errorMessage } = await cancelBooking(id);
        if (errorMessage) {
            // TODO(charlieroth): Do something with error
            console.error('Failed to cancel booking');
        } else {
            history.goBack();
        }
    };

    if (loading) {
        return (
            <Screen
                topContent={<Title marginTop={'1rem'}>Orderinformation</Title>}
                content={<Loading />}
            />
        );
    }

    return (
        <Screen
            topContent={<Title marginTop={'1rem'}>Orderinformation</Title>}
            content={
                <Root>
                    <Text>
                        {`Leveransdag:
                        ${new Date(
                            booking?.dropoffTime ||
                                booking?.estimatedDropoffTime ||
                                booking?.earliestDropoffTime ||
                                0
                        ).toLocaleDateString()}`}
                    </Text>
                    <Text>{`Orderid/referens: ${booking?.externalOrderId ?? ''}`}</Text>
                    <Text>{`Status: ${booking?.state_translated ?? booking?.state}`}</Text>
                    {booking &&
                        Object.entries(booking.items).map(([slug, item]) => {
                            const itemType =
                                (item.type && ItemTypeTranslation[item.type]?.plural) || '';
                            const itemSize = item.size
                                ? item.size.substring(0, 1).toUpperCase()
                                : '';

                            return (
                                <Text key={`item-${slug}`}>
                                    {`Antal ${itemType}`}
                                    {': '}
                                    {itemSize} {item.count}
                                </Text>
                            );
                        })}
                    <Header>Hämtas när och var:</Header>
                    <Text>
                        {`Tid:
                        ${new Date(booking?.earliestPickupTime || 0).toLocaleTimeString([], {
                            timeStyle: 'short',
                        })} - ${new Date(booking?.latestPickupTime || 0).toLocaleTimeString([], {
                            timeStyle: 'short',
                        })}`}
                    </Text>
                    <Text>{`Namn/företag: ${booking?.pickup?.name ?? ''}`}</Text>
                    <Text>{`Telefonnummer: ${booking?.pickup?.phoneNumber ?? ''}`}</Text>
                    <Text>{`Adress: ${booking?.pickup?.address ?? ''}`}</Text>
                    <Text>{`Instruktioner: ${booking?.pickup?.instructions ?? ''}`}</Text>
                    <Header>Lämnas när och var:</Header>
                    <Text>
                        {`Tid:
                        ${new Date(booking?.earliestDropoffTime || 0).toLocaleTimeString([], {
                            timeStyle: 'short',
                        })} - ${new Date(booking?.latestDropoffTime || 0).toLocaleTimeString([], {
                            timeStyle: 'short',
                        })}`}
                    </Text>
                    <Text>{`Namn/företag: ${booking?.dropoff?.name ?? ''}`}</Text>
                    <Text>{`Telefonnummer: ${booking?.dropoff?.phoneNumber ?? ''}`}</Text>
                    <Text>{`Adress: ${booking?.dropoff?.address ?? ''}`}</Text>
                    <Text>{`Instruktioner: ${booking?.dropoff?.instructions ?? ''}`}</Text>
                    {cancelBookingModalVisible && (
                        <InfoPopup
                            header="Vill du verkligen avboka?"
                            positiveButtonText="Ja"
                            closePress={() => setCancelBookingModalVisible(false)}
                            onSubmit={handleCancelConfirmation}
                        />
                    )}
                </Root>
            }
            actionRowContent={
                <ButtonRow>
                    <Button
                        onClick={() => handlePrintById(booking?.reference ?? '')}
                        variant={Variant.Print}
                        textSize="small"
                    >
                        Skriv ut fraktsedel
                    </Button>
                    <Button
                        disabled={canCancel() === false}
                        onClick={handleCancelClicked}
                        variant={Variant.Negative}
                    >
                        Avboka
                    </Button>
                </ButtonRow>
            }
        />
    );
};

import styled from 'styled-components';
import { colors } from './colors';
import { dimensions } from './dimensions';

export const AppContainer = styled.div`
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    height: 100vh;
    width: 100vw;
    max-width: 100%;

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        grid-template-rows: auto auto 1fr;
    }

    @media print {
        grid-template-rows: auto;
    }
`;

export const Flex = styled.div<{ direction?: 'row' | 'column' }>`
    display: flex;
    flex-direction: ${({ direction }) => (direction === 'column' ? 'column' : 'row')};
    justify-content: space-between;
    align-items: center;
`;

export const Text = styled.div<{ align?: 'left' | 'center' | 'right'; disabled?: boolean }>`
    font-size: 0.65rem;
    font-weight: normal;
    font-family: sofia-pro, sans-serif;
    width: 100%;
    text-align: ${({ align }) =>
        align === 'center' ? 'center' : align === 'right' ? 'right' : 'left'};
    color: ${({ disabled }) => (disabled ? colors.grey : colors.black)};
`;

export const Label = styled.label<{
    column?: boolean;
    noWrap?: boolean;
    weight?: 'normal' | 'bold';
}>`
    font-size: 0.9em;
    padding-right: 10px;
    align-items: center;
    font-weight: ${({ weight }) => (weight ? weight : 'normal')};
    justify-content: ${({ column }) => (column ? 'flex-start' : 'center')};
    display: flex;
    text-align: left;
    white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};
    font-family: sofia-pro, sans-serif;

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        justify-content: flex-start;
        margin-bottom: 6px;
    }
`;

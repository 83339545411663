import { useHistory, useLocation } from 'react-router-dom';
import { BackButton } from '../../fragments/back-button';
import { Button } from '../../fragments/button';
import { ButtonRow } from '../../fragments/button-row';
import { DatePicker } from '../../fragments/date-picker';
import { Title } from '../../fragments/title';
import { Screen } from '../../structure/screen';
import {
    Root,
    TimeButtonGrid,
    TimeButtonsLeft,
    TimeButtonsRight,
    PickersContainer,
} from './time.styles';
import 'react-datepicker/dist/react-datepicker.css';
import { TimePicker } from '../../fragments/time-picker';
import { useEffect, useState } from 'react';
import { ProgressIndicator } from '../../fragments/progress-indicator';
import { Label } from '../../app.styles';
import useAnalytics, { AnalyticsEvent } from '../../hooks/useAnalytics';
import { checkAvailability, requestDeliveryOffers } from '../../utils/booking-action';
import { BookableMessage } from './bookable-message';
import BookableTime from './bookable-time';
import NonBookableTime from './non-bookable-time';
import { Bzzt } from '../../types';
import {
    bookingCandidateItemsToParcelStringArray,
    bookingFlowAccessControl,
} from '../../utils/booking-action-utils';
import { BookingFlowStep, BookingTimeFormState, ErrorKey } from '../../types/bzzt';
import { ToggleSwitch } from '../../fragments/toggle-switch';
import { ERROR_MESSAGES } from '../../constants';
import { afterDeliveryCutoff, createFormState } from './utils';
import { useUserStore } from '../../stores/useUserStore';
import { useBookingStore } from '../../stores/useBookingStore';
import simpleApi from '../../utils/simple-api/simple-api';
import { InfoMessage } from '../../fragments/info-message';

export const Time = () => {
    const history = useHistory();
    const location = useLocation();
    const bookingStore = useBookingStore();
    const userStore = useUserStore();
    const analytics = useAnalytics();

    const [deliveryOffers, setDeliveryOffers] = useState<Bzzt.DeliveryOffer[]>([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    const [timeWindowAvailable, setTimeWindowAvailable] = useState(false);
    const [selectedDeliveryOfferId, setDeliveryOfferId] = useState<string | null>(null);
    const [showSuccessIcon, setShowSuccessIcon] = useState(false);
    const [showFailureIcon, setShowFailureIcon] = useState(false);
    const [isClosed, setIsClosed] = useState<boolean>(false);
    const [locationInfoMsg, setLocationInfoMsg] = useState<string>('');
    const [deliveryDate, setDeliveryDate] = useState<Date>(new Date());
    const interval = 5;

    // TODO: Move bookingView to form state so we can set the default
    // view based on either user config or previous booking value
    const [formState, setFormState] = useState<BookingTimeFormState>(() => {
        const closing = new Date();
        closing.setHours(21, 0, 0, 0);

        return createFormState({
            opening: new Date(),
            closing: closing,
            interval,
            userPreferredBookingView: userStore.config?.config.defaultBookingView,
            previousBooking: bookingStore.bookingCandidate,
            previousOfferWindow: bookingStore.offerSearchWindow ?? undefined,
        });
    });

    useEffect(() => {
        const fetchLocationInfo = async (date: Date, from: Bzzt.Location) => {
            const { data } = await simpleApi.getLocationInfo({
                date: date.toISOString(),
                from: from,
            });
            if (data) {
                let closingTime = new Date(data.closed);
                if (data.temp_closed_from && data.temp_closed_to) {
                    const tempClosedFromDate = new Date(data.temp_closed_from);
                    const tempClosedToDate = new Date(data.temp_closed_to);

                    // Shows the message for the entire day by discarding the hours before comparing dates
                    if (
                        date.setHours(1, 0, 0) >= tempClosedFromDate.setHours(1, 0, 0) &&
                        date.setHours(1, 0, 0) <= tempClosedToDate.setHours(1, 0, 0)
                    ) {
                        setLocationInfoMsg(data.temp_closed_message);
                        if (tempClosedFromDate < closingTime && tempClosedToDate > closingTime) {
                            closingTime = new Date(data.temp_closed_from);
                        }
                    } else {
                        setLocationInfoMsg('');
                    }
                }

                const newFormState = createFormState({
                    opening: new Date(data.open),
                    closing: closingTime,
                    interval,
                    userPreferredBookingView: formState.view,
                    selectedDeliveryDate: deliveryDate,
                });
                setFormState(newFormState);

                setIsClosed(
                    afterDeliveryCutoff(
                        newFormState.offerSearchWindow.start.date,
                        new Date(data.closed)
                    )
                );
            }
        };
        if (bookingStore.bookingCandidate.pickup?.location) {
            fetchLocationInfo(deliveryDate, bookingStore.bookingCandidate.pickup?.location);
        }
    }, [bookingStore.bookingCandidate.pickup, formState.view, deliveryDate]);

    useEffect(() => {
        bookingStore.resetDeliveryOfferId();

        // NOTE(charlieroth): Ignore the linter warning here, we only want to run this once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!bookingStore.initialized) return;

        const redirectTo = bookingFlowAccessControl(location, bookingStore.bookingCandidate);
        if (redirectTo) {
            history.replace(redirectTo);
        }
    }, [location, history, bookingStore.initialized, bookingStore.bookingCandidate]);

    useEffect(() => {
        const fetchDeliveryOffers = async () => {
            setMessage(null);
            setLoading(true);

            try {
                // set error if end is before start
                if (formState.offerSearchWindow.end < formState.offerSearchWindow.start) {
                    setMessage(ERROR_MESSAGES.in_the_past);
                    return;
                }

                const { deliveryOffers: requestedDeliveryOffers, errorMessage } =
                    await requestDeliveryOffers(
                        bookingStore,
                        formState.offerSearchWindow.start.date.toISOString(),
                        formState.offerSearchWindow.end.date.toISOString()
                    );

                if (errorMessage) {
                    setMessage(errorMessage);
                } else if (requestedDeliveryOffers) {
                    setDeliveryOffers(requestedDeliveryOffers);
                }
            } catch (error) {
                console.error('Error fetching delivery offers:', error);
                setMessage(ERROR_MESSAGES.default);
            } finally {
                setLoading(false);
            }
        };

        if (formState.view === 'normal') {
            fetchDeliveryOffers();
        }
    }, [
        bookingStore,
        formState.offerSearchWindow.start,
        formState.offerSearchWindow.end,
        formState.view,
    ]);

    useEffect(() => {
        const checkTimeWindowAvailability = async () => {
            try {
                const { data, errorMessage } = await checkAvailability({
                    from: bookingStore.bookingCandidate.pickup!.location!,
                    to: bookingStore.bookingCandidate.dropoff!.location!,
                    parcels: bookingCandidateItemsToParcelStringArray(
                        bookingStore.bookingCandidate.items
                    ),
                    time_windows: [
                        {
                            earliest_pickup_time: formState.earliestPickupTime.date.toISOString(),
                            latest_pickup_time: formState.latestPickupTime.date.toISOString(),
                            earliest_dropoff_time: formState.earliestDropoffTime.date.toISOString(),
                            latest_dropoff_time: formState.latestDropoffTime.date.toISOString(),
                        },
                    ],
                });

                if (errorMessage) {
                    setMessage(errorMessage);
                    setTimeWindowAvailable(false);
                } else if (data) {
                    const timeWindowRequested = data.time_windows[0];
                    if (timeWindowRequested.status !== 'success') {
                        setShowSuccessIcon(false);
                        setShowFailureIcon(true);
                        setMessage(
                            ERROR_MESSAGES[
                                (timeWindowRequested.reason?.toLowerCase() as ErrorKey) || 'default'
                            ]
                        );
                        setTimeWindowAvailable(false);
                    } else {
                        setShowFailureIcon(false);
                        setShowSuccessIcon(true);
                        setMessage('Klicka "Boka" för att fortsätta.');
                        setTimeWindowAvailable(true);
                    }
                }
            } catch {
                setTimeWindowAvailable(false);
                setMessage(ERROR_MESSAGES.default);
            }
        };

        if (formState.view === 'detailed') {
            checkTimeWindowAvailability();
        }
    }, [
        formState.earliestDropoffTime,
        formState.earliestPickupTime,
        formState.latestDropoffTime,
        formState.latestPickupTime,
        formState.view,
        bookingStore.bookingCandidate,
    ]);

    const handleDatePickerChange = (selectedDeliveryDate: Date) => {
        selectedDeliveryDate.setHours(12, 0, 0, 0);
        setDeliveryDate(selectedDeliveryDate);

        setDeliveryOfferId(null);
    };

    const handleOfferSearchWindowStartChange = (date: Date) => {
        const newOfferSearchWindowStart = new Date(date);
        newOfferSearchWindowStart.setFullYear(formState.offerSearchWindow.start.date.getFullYear());
        newOfferSearchWindowStart.setMonth(formState.offerSearchWindow.start.date.getMonth());
        newOfferSearchWindowStart.setDate(formState.offerSearchWindow.start.date.getDate());

        setFormState((state) => ({
            ...state,
            offerSearchWindow: {
                ...state.offerSearchWindow,
                start: {
                    ...state.offerSearchWindow.start,
                    date: newOfferSearchWindowStart,
                },
            },
        }));

        setDeliveryOfferId(null);
    };

    const handleOfferSearchWindowEndChange = (date: Date) => {
        const newOfferSearchWindowEnd = new Date(date);
        newOfferSearchWindowEnd.setFullYear(formState.offerSearchWindow.end.date.getFullYear());
        newOfferSearchWindowEnd.setMonth(formState.offerSearchWindow.end.date.getMonth());
        newOfferSearchWindowEnd.setDate(formState.offerSearchWindow.end.date.getDate());

        setFormState((state) => ({
            ...state,
            offerSearchWindow: {
                ...state.offerSearchWindow,
                end: {
                    ...state.offerSearchWindow.end,
                    date: newOfferSearchWindowEnd,
                },
            },
        }));

        setDeliveryOfferId(null);
    };

    const handleEarliestPickupTimeChange = (date: Date) => {
        const newEarliestPickupTime = new Date(date);
        newEarliestPickupTime.setFullYear(formState.earliestPickupTime.date.getFullYear());
        newEarliestPickupTime.setMonth(formState.earliestPickupTime.date.getMonth());
        newEarliestPickupTime.setDate(formState.earliestPickupTime.date.getDate());

        setFormState((state) => ({
            ...state,
            earliestPickupTime: {
                ...state.earliestPickupTime,
                date: newEarliestPickupTime,
            },
        }));

        setDeliveryOfferId(null);
    };

    const handleLatestPickupTimeChange = (date: Date) => {
        const newLatestPickupTime = new Date(date);
        newLatestPickupTime.setFullYear(formState.latestPickupTime.date.getFullYear());
        newLatestPickupTime.setMonth(formState.latestPickupTime.date.getMonth());
        newLatestPickupTime.setDate(formState.latestPickupTime.date.getDate());

        setFormState((state) => ({
            ...state,
            latestPickupTime: {
                ...state.latestPickupTime,
                date: newLatestPickupTime,
            },
        }));

        setDeliveryOfferId(null);
    };

    const handleEarliestDropoffTimeChange = (date: Date) => {
        const newEarliestDropoffTime = new Date(date);
        newEarliestDropoffTime.setFullYear(formState.earliestDropoffTime.date.getFullYear());
        newEarliestDropoffTime.setMonth(formState.earliestDropoffTime.date.getMonth());
        newEarliestDropoffTime.setDate(formState.earliestDropoffTime.date.getDate());

        setFormState((state) => ({
            ...state,
            earliestDropoffTime: {
                ...state.earliestDropoffTime,
                date: newEarliestDropoffTime,
            },
        }));

        setDeliveryOfferId(null);
    };

    const handleLatestDropoffTimeChange = (date: Date) => {
        const newLatestDropoffTime = new Date(date);
        newLatestDropoffTime.setFullYear(formState.latestDropoffTime.date.getFullYear());
        newLatestDropoffTime.setMonth(formState.latestDropoffTime.date.getMonth());
        newLatestDropoffTime.setDate(formState.latestDropoffTime.date.getDate());

        setFormState((state) => ({
            ...state,
            latestDropoffTime: {
                ...state.latestDropoffTime,
                date: newLatestDropoffTime,
            },
        }));

        setDeliveryOfferId(null);
    };

    const handleBookableTimeClicked = (deliveryOffer: Bzzt.DeliveryOffer) => {
        setDeliveryOfferId(deliveryOffer.id);
    };

    const toggleBookingView = () => {
        setFormState((prevState) => ({
            ...prevState,
            view: prevState.view === 'normal' ? 'detailed' : 'normal',
        }));
    };

    const handleBackClicked = () => {
        history.push(BookingFlowStep.DeliveryDropoff);
    };

    const handleBookClicked = async () => {
        if (!timeWindowAvailable) {
            setMessage(ERROR_MESSAGES.not_available);
        }

        bookingStore.completeStepFourDetailed({
            view: 'detailed',
            earliestPickupTime: formState.earliestPickupTime.date.toISOString(),
            latestPickupTime: formState.latestPickupTime.date.toISOString(),
            earliestDropoffTime: formState.earliestDropoffTime.date.toISOString(),
            latestDropoffTime: formState.latestDropoffTime.date.toISOString(),
        });
        history.push(BookingFlowStep.DeliveryOverview);
    };

    const handleNextClicked = async () => {
        if (!selectedDeliveryOfferId) {
            return;
        }

        const selectedDeliveryOffer = deliveryOffers.find(
            (deliveryOffer) => deliveryOffer.id === selectedDeliveryOfferId
        );

        void analytics.track({
            email: userStore?.email ?? '',
            event: AnalyticsEvent.OfferSelected,
            properties: {
                selectedDeliveryOffer: selectedDeliveryOfferId!,
            },
        });

        bookingStore.completeStepFourNormal({
            view: 'normal',
            offerSearchWindow: {
                start: formState.offerSearchWindow.start.date,
                end: formState.offerSearchWindow.end.date,
            },
            deliveryOfferId: selectedDeliveryOfferId,
            earliestPickupTime: selectedDeliveryOffer!.earliestPickupTime,
            latestPickupTime: selectedDeliveryOffer!.latestPickupTime,
            earliestDropoffTime: selectedDeliveryOffer!.earliestDropoffTime,
            latestDropoffTime: selectedDeliveryOffer!.latestDropoffTime,
        });

        history.push(BookingFlowStep.DeliveryOverview);
    };

    const twoColumns = deliveryOffers.length > 3;

    return (
        <Screen
            topContent={<Title marginTop={'1rem'}>Dag och tid?</Title>}
            slim={false}
            content={
                <Root>
                    <PickersContainer>
                        <DatePicker
                            selectedValue={formState.deliveryDate.date.toISOString()}
                            options={formState.deliveryDate.options}
                            onChange={handleDatePickerChange}
                        />
                        {!isClosed && (
                            <ToggleSwitch
                                labelText={'detaljerade tider'}
                                checked={formState.view === 'detailed'}
                                onChange={toggleBookingView}
                            />
                        )}
                        {formState.view === 'normal' &&
                            !isClosed &&
                            formState.offerSearchWindow.start.options.length > 0 && (
                                <>
                                    <TimePicker
                                        label="Tidigast upphämtning"
                                        selectedValue={formState.offerSearchWindow.start.date.toISOString()}
                                        options={formState.offerSearchWindow.start.options}
                                        onChange={handleOfferSearchWindowStartChange}
                                    />
                                    <TimePicker
                                        label="Senast avlämning"
                                        selectedValue={formState.offerSearchWindow.end.date.toISOString()}
                                        options={formState.offerSearchWindow.end.options}
                                        onChange={handleOfferSearchWindowEndChange}
                                    />
                                </>
                            )}
                        {formState.view === 'detailed' &&
                            !isClosed &&
                            formState.earliestPickupTime.options.length > 0 && (
                                <>
                                    <TimePicker
                                        label="Hämtas tidigast"
                                        selectedValue={formState.earliestPickupTime.date.toISOString()}
                                        options={formState.earliestPickupTime.options}
                                        onChange={handleEarliestPickupTimeChange}
                                    />
                                    <TimePicker
                                        label="Hämtas senast"
                                        selectedValue={formState.latestPickupTime.date.toISOString()}
                                        options={formState.latestPickupTime.options}
                                        onChange={handleLatestPickupTimeChange}
                                    />
                                    <TimePicker
                                        label="Lämnas tidigast"
                                        selectedValue={formState.earliestDropoffTime.date.toISOString()}
                                        options={formState.earliestDropoffTime.options}
                                        onChange={handleEarliestDropoffTimeChange}
                                    />
                                    <TimePicker
                                        label="Lämnas senast"
                                        selectedValue={formState.latestDropoffTime.date.toISOString()}
                                        options={formState.latestDropoffTime.options}
                                        onChange={handleLatestDropoffTimeChange}
                                    />
                                </>
                            )}
                    </PickersContainer>

                    {locationInfoMsg && <InfoMessage text={locationInfoMsg} />}
                    {loading ? <p>Kollar efter tider åt dig...</p> : null}
                    {!loading && isClosed ? (
                        <p>
                            Vi har stängt för dagen. Du kan boka en tid för imorgon, eller välja
                            någon annan dag.
                        </p>
                    ) : null}
                    {!loading && !isClosed && message ? (
                        <BookableMessage
                            showSuccess={showSuccessIcon}
                            showFailure={showFailureIcon}
                            text={message}
                        />
                    ) : null}
                    {!loading &&
                    !isClosed &&
                    !message &&
                    formState.view === 'normal' &&
                    deliveryOffers.length > 0 ? (
                        <>
                            <Label>Välj leveransalternativ</Label>
                            <TimeButtonGrid>
                                <TimeButtonsLeft alignmentCondition={!twoColumns}>
                                    {deliveryOffers
                                        .slice(0, 3)
                                        .map((deliveryOffer) =>
                                            deliveryOffer.active ? (
                                                <BookableTime
                                                    key={deliveryOffer.id}
                                                    deliveryOffer={deliveryOffer}
                                                    selected={
                                                        deliveryOffer.id === selectedDeliveryOfferId
                                                    }
                                                    onClick={() =>
                                                        handleBookableTimeClicked(deliveryOffer)
                                                    }
                                                />
                                            ) : (
                                                <NonBookableTime
                                                    deliveryOffer={deliveryOffer}
                                                    key={deliveryOffer.id}
                                                />
                                            )
                                        )}
                                </TimeButtonsLeft>
                                {twoColumns && (
                                    <TimeButtonsRight>
                                        {deliveryOffers
                                            .slice(3, deliveryOffers.length)
                                            .map((deliveryOffer) =>
                                                deliveryOffer.active ? (
                                                    <BookableTime
                                                        key={deliveryOffer.id}
                                                        deliveryOffer={deliveryOffer}
                                                        selected={
                                                            deliveryOffer.id ===
                                                            selectedDeliveryOfferId
                                                        }
                                                        onClick={() =>
                                                            handleBookableTimeClicked(deliveryOffer)
                                                        }
                                                    />
                                                ) : (
                                                    <NonBookableTime
                                                        deliveryOffer={deliveryOffer}
                                                        key={deliveryOffer.id}
                                                    />
                                                )
                                            )}
                                    </TimeButtonsRight>
                                )}
                            </TimeButtonGrid>
                        </>
                    ) : null}
                </Root>
            }
            actionRowContent={
                <ButtonRow>
                    <BackButton onClick={handleBackClicked} />
                    <ProgressIndicator currentStep={4} totalSteps={5} />
                    {formState.view === 'normal' && (
                        <Button disabled={!selectedDeliveryOfferId} onClick={handleNextClicked}>
                            Nästa
                        </Button>
                    )}
                    {formState.view === 'detailed' && (
                        <Button
                            disabled={timeWindowAvailable === false}
                            onClick={handleBookClicked}
                        >
                            Boka
                        </Button>
                    )}
                </ButtonRow>
            }
        />
    );
};

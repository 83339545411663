import styled from 'styled-components';
import { dimensions } from '../../dimensions';

export const Item = styled.div<{ hideForMobile?: boolean }>`
    font-size: 0.7rem;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.5rem;
    text-align: left;
    font-weight: 400;
    text-overflow: ellipsis;
    @media (max-width: ${dimensions.mobileBreakpoint}) {
        display: ${({ hideForMobile }) => (hideForMobile ? 'none' : 'block')};
        font-weight: bold;
        font-size: 0.85rem;
        overflow: auto;
        white-space: normal;
    }
`;

export const MobileHeader = styled.div`
    display: none;
    @media (max-width: ${dimensions.mobileBreakpoint}) {
        display: flex;
        font-weight: 300;
    }
`;

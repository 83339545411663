import './booking-filter.css';
import { useState } from 'react';

const filterOptions = [
    {
        // Last hour
        label: 'Senaste timman',
        date: new Date().setHours(new Date().getHours() - 1),
    },
    {
        // Last day
        label: 'Senaste dygnet',
        date: new Date().setDate(new Date().getDate() - 1),
    },
    {
        // Last week
        label: 'Senaste veckan',
        date: new Date().setDate(new Date().getDate() - 7),
    },
    {
        // Last month
        label: 'Senaste månaden',
        date: new Date().setMonth(new Date().getMonth() - 1),
    },
];

interface BookingFilterProps {
    labelStart: string;
    labelEnd: string;
    onChange: ({ start, end }: { start?: Date; end?: Date }) => void;
}

interface BookingFilterState {
    startDate: Date;
    endDate: Date;
}

export const BookingFilter = ({ labelStart, labelEnd, onChange }: BookingFilterProps) => {
    const [filters, setFilters] = useState<BookingFilterState>(() => {
        const defaultStartDate = new Date();
        defaultStartDate.setHours(0, 0, 0, 0);

        const defaultEndDate = new Date();
        defaultEndDate.setHours(23, 59, 59, 999);

        return {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        };
    });

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = new Date(e.target.value);
        const newFilterState = { ...filters, startDate: currentValue };
        setFilters(newFilterState);
        onChange({ start: newFilterState.startDate });
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = new Date(e.target.value);
        const newFilterState = { ...filters, endDate: currentValue };
        setFilters(newFilterState);
        onChange({ end: newFilterState.endDate });
    };

    const handleRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const date = new Date(parseInt(e.target.value));

        const newFilterState = {
            ...filters,
            startDate: date,
            endDate: new Date(),
        };
        setFilters(newFilterState);
        onChange({
            start: newFilterState.startDate,
            end: newFilterState.endDate,
        });
    };

    const formatDate = (date: Date) => {
        return [
            date.getFullYear(),
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2),
        ].join('-');
    };

    return (
        <div className="booking-filter-container">
            <div className="booking-filter-input-wrapper">
                <label className="booking-filter-label">{labelStart}</label>
                <input
                    className="booking-filter-input"
                    type="date"
                    pattern="\d{4}-\d{2}-\d{2}"
                    value={formatDate(filters.startDate)}
                    onChange={handleStartDateChange}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </div>
            <div className="booking-filter-input-wrapper">
                <label className="booking-filter-label">{labelEnd}</label>
                <input
                    className="booking-filter-input"
                    type="date"
                    pattern="\d{4}-\d{2}-\d{2}"
                    value={formatDate(filters.endDate)}
                    onChange={handleEndDateChange}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </div>

            <select className="booking-filter-dropdown-select" onChange={handleRangeChange}>
                <option unselectable="on" value={new Date().getTime()} disabled>
                    Filterera
                </option>
                {filterOptions.map((option: any) => (
                    <option key={option.label} value={option.date}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

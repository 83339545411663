import React from 'react';
import {
    ArrowRound,
    Arrow,
    Bag,
    Box,
    Calendar,
    Checkbox,
    Edit,
    Large,
    Marker,
    Medium,
    Other,
    Search,
    Small,
} from './icons/.';
import { Root } from './icon.styles';

export const icons = {
    'arrow-round': [ArrowRound],
    arrow: [Arrow],
    bag: [Bag],
    box: [Box],
    calendar: [Calendar],
    checkbox: [Checkbox],
    edit: [Edit],
    large: [Large],
    marker: [Marker],
    medium: [Medium],
    other: [Other],
    search: [Search],
    small: [Small],
    parcel: [Box],
};

export type IconType = keyof typeof icons;

interface IconProps {
    absolute?: boolean;
    checked?: boolean;
    fill?: string;
    isButton?: boolean;
    onClick?: () => void;
    ref?: React.MutableRefObject<HTMLDivElement>;
    size?: 'xs' | 's' | 'm';
    type?: IconType;
}

export function Icon({
    absolute,
    checked,
    fill,
    isButton,
    onClick,
    ref,
    type,
    ...rest
}: IconProps & React.HTMLAttributes<HTMLDivElement>) {
    if (!type) {
        return null;
    }

    const [InnerIcon, props = {}] = icons[type] as [React.FC<any>, any];

    return (
        <Root
            isButton={!!onClick || isButton}
            onClick={!!onClick ? () => onClick() : undefined}
            ref={ref}
            absolute={absolute}
            {...rest}
        >
            <InnerIcon {...props} checked={checked} fill={fill} />
        </Root>
    );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Api, Bzzt } from '../types';
import simpleApi from './simple-api/simple-api';
import { bookingCandidateItemsToParcelStringArray, getErrorMessage } from './booking-action-utils';
import { ERROR_MESSAGES } from '../constants';
import type { BookingState } from '../stores/useBookingStore';

const STATE_TRANSLATIONS = {
    booked: 'Bokad',
    sent_to_carrier: 'Bokad',
    requested: 'Frågad (väntar accept från förare)',
    approaching: 'På väg till upphämtning',
    arrived_at_origin: 'Framme vid upphämtning',
    ride_in_progress: 'På väg mot avlämning',
    picked_up: 'På väg mot avlämning',
    arrived_at_destination: 'Lämnar av',
    payment_complete: 'Levererad',
    done: 'Levererad',
    failed: 'Ingen förare tillgänglig',
    queued: 'Köad',
    canceled_by_driver: 'Avböjd',
    cancelled: 'Avbokad',
    canceled: 'Avbokad',
};

export const requestDeliveryOffers = async (
    state: BookingState,
    earliestPickupTime: string,
    latestDropoffTime: string
): Promise<{ deliveryOffers: Bzzt.DeliveryOffer[] | null; errorMessage: string | null }> => {
    try {
        const payload: Api.RequestDeliveryOffersPayload = {
            from: state.bookingCandidate.pickup!.location!,
            to: state.bookingCandidate.dropoff!.location!,
            earliest_pickup_time: earliestPickupTime,
            latest_dropoff_time: latestDropoffTime,
            parcels: bookingCandidateItemsToParcelStringArray(state.bookingCandidate.items),
            pickup: {
                name: state.bookingCandidate.pickup!.name,
                address: state.bookingCandidate.pickup!.address,
                phonenumber: state.bookingCandidate.pickup!.phoneNumber,
                instructions: state.bookingCandidate.pickup!.instructions,
            },
            dropoff: {
                name: state.bookingCandidate.dropoff!.name,
                address: state.bookingCandidate.dropoff!.address,
                phonenumber: state.bookingCandidate.dropoff!.phoneNumber,
                instructions: state.bookingCandidate.dropoff!.instructions,
            },
            external_order_id: state.bookingCandidate.customerReference!,
        };

        const { data, status } = await simpleApi.requestDeliveryOffers(payload);
        if (!data) throw new Error(`Failed to fetch delivery offers with status: ${status}`);
        if (status === 400) {
            return {
                deliveryOffers: null,
                errorMessage: getErrorMessage(data),
            };
        }

        const deliveryOffers = data.map((item) => ({
            id: item.id,
            active: item.active,
            message: item.message,
            earliestPickupTime: item.earliest_pickup_time,
            latestPickupTime: item.latest_pickup_time,
            earliestDropoffTime: item.earliest_dropoff_time,
            latestDropoffTime: item.latest_dropoff_time,
            priceCategory: item.price_category,
        }));

        return {
            deliveryOffers,
            errorMessage: null,
        };
    } catch (error: any) {
        console.error(error.message);
        return {
            deliveryOffers: null,
            errorMessage: ERROR_MESSAGES.default,
        };
    }
};

const normalizeBooking = (booking: Api.Booking): Bzzt.Booking => {
    const state = booking.state.toLowerCase() as keyof typeof STATE_TRANSLATIONS;

    const items: Bzzt.ItemsRecord = {};
    for (const item of booking.parcels) {
        const [type, size] = item.slug.split('-');
        if (items[item.slug]) {
            items[item.slug].count++;
        } else {
            items[item.slug] = {
                type: type as Bzzt.ItemType,
                size: size as Bzzt.ItemSize,
                count: 1,
            };
        }
    }

    return {
        user: booking.user,
        type: booking.type,
        state: booking.state,
        state_translated: STATE_TRANSLATIONS[state],
        reference: booking.reference,
        pickupAt: booking.pickupAt,
        pickup: {
            name: booking.pickup.name,
            phoneNumber: booking.pickup.phoneNumber,
            instructions: booking.pickup.instructions,
            address: booking.pickup.address,
        },
        items,
        location: booking.location,
        latestPickupTime: booking.latest_pickup_time,
        latestDropoffTime: booking.latest_dropoff_time,
        externalOrderId: booking.externalOrderId,
        estimatedDropoffTime: booking.estimatedDropoffTime,
        earliestPickupTime: booking.earliest_pickup_time,
        earliestDropoffTime: booking.earliest_dropoff_time,
        dropoffTime: booking.dropoffTime,
        dropoff: {
            name: booking.dropoff.name,
            phoneNumber: booking.dropoff.phoneNumber,
            instructions: booking.dropoff.instructions,
            address: booking.dropoff.address,
        },
        bookedAt: booking.bookedAt,
    };
};

export const getBookings = async (
    startTime: Date,
    endTime: Date
): Promise<{ data: Bzzt.Booking[] | null; errorMessage: string | null }> => {
    try {
        const { data, status } = await simpleApi.getBookings({
            start_time: startTime.toISOString(),
            end_time: endTime.toISOString(),
        });

        if (status === 400) {
            return {
                data: null,
                errorMessage: getErrorMessage(data),
            };
        } else if (data && status === 200) {
            return {
                data: data.map(normalizeBooking),
                errorMessage: null,
            };
        }

        return { data: null, errorMessage: null };
    } catch (error) {
        console.error('Failed to get bookings: ', error);
        return {
            data: null,
            errorMessage: ERROR_MESSAGES.default,
        };
    }
};

export const getBooking = async (
    reference: string
): Promise<{ data: Bzzt.Booking | null; errorMessage: string | null }> => {
    try {
        const { data, status } = await simpleApi.getBooking({ references: [reference] });
        if (status === 400) {
            return {
                data: null,
                errorMessage: getErrorMessage(data),
            };
        } else if (data && status === 200) {
            return {
                data: normalizeBooking(data[0]),
                errorMessage: null,
            };
        }

        return { data: null, errorMessage: null };
    } catch (error: any) {
        console.error('Failed to get booking: ', error);
        return {
            data: null,
            errorMessage: ERROR_MESSAGES.default,
        };
    }
};

export const cancelBooking = async (reference: string): Promise<any> => {
    try {
        const { data, status } = await simpleApi.cancelBooking({ reference });
        if (status === 400) {
            return {
                data: null,
                errorMessage: getErrorMessage(data),
            };
        } else if (data && status == 200) {
            return {
                data,
                errorMessage: null,
            };
        }

        return { data: null, errorMessage: null };
    } catch (error: any) {
        return {
            data: null,
            errorMessage: ERROR_MESSAGES.default,
        };
    }
};

export const checkInZone = async (from: Bzzt.Location, to: Bzzt.Location): Promise<boolean> => {
    try {
        const { data } = await simpleApi.checkInZone({ from, to });
        return !!data;
    } catch (error: any) {
        console.error(error.message);
        return false;
    }
};

export const acceptDeliveryOffer = async (
    offerId: string
): Promise<{ data: string | null; errorMessage: string | null }> => {
    try {
        const { data, status } = await simpleApi.acceptDeliveryOffer(offerId);
        if (status === 400) {
            return {
                data: null,
                errorMessage: getErrorMessage(data),
            };
        }

        return {
            data,
            errorMessage: null,
        };
    } catch (error: any) {
        console.error('Failed to accept delivery offer: ', error);
        return {
            data: null,
            errorMessage: ERROR_MESSAGES.default,
        };
    }
};

export const checkAvailability = async (
    payload: Api.CheckAvailabilityPayload
): Promise<{ data: Api.CheckAvailabilityData | null; errorMessage: string | null }> => {
    try {
        const { data, status } = await simpleApi.checkAvailability(payload);
        if (status === 400) {
            return {
                data: null,
                errorMessage: getErrorMessage(data),
            };
        } else if (data && status == 200) {
            return {
                data: data,
                errorMessage: null,
            };
        }

        return {
            data: null,
            errorMessage: null,
        };
    } catch (error: any) {
        console.error('Failed to book ride: ', error);
        return {
            data: null,
            errorMessage: ERROR_MESSAGES.default,
        };
    }
};

export const bookRide = async (
    payload: Api.BookRidePayload
): Promise<{ data: string | null; errorMessage: string | null }> => {
    try {
        const { data, status } = await simpleApi.bookRide(payload);
        if (status === 400) {
            return {
                data: null,
                errorMessage: getErrorMessage(data),
            };
        } else if (data && status == 200) {
            return {
                data: data.reference,
                errorMessage: null,
            };
        }

        return {
            data: null,
            errorMessage: null,
        };
    } catch (error: any) {
        console.error('Failed to book ride: ', error);
        return {
            data: null,
            errorMessage: ERROR_MESSAGES.default,
        };
    }
};

import React, { useState } from 'react';
import { Root } from './dropdown.styles';

interface DropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    isActivated?: boolean;
    options: string[];
    defaultOption?: string;
}

export function Dropdown(props: DropdownProps) {
    const { isActivated = false, options, defaultOption, ...rest } = props;
    const [selectedOption, setSelectedOption] = useState<string | undefined>(defaultOption || '');

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(event.target.value);
    };

    return (
        <Root isActivated={isActivated}>
            <select value={selectedOption} onChange={handleSelectChange} {...rest}>
                {!defaultOption && (
                    <option value="" disabled>
                        Välj ett alternativ
                    </option>
                )}
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </Root>
    );
}

import simpleApi from '../utils/simple-api/simple-api';

export enum AnalyticsEvent {
    OffersRequested = 'offers_requested',
    OfferSelected = 'offer_selected',
    BookingCreated = 'booking_created',
}

const useAnalytics = () => {
    const identify = async ({ email, traits }: { email: string; traits: Record<string, any> }) => {
        try {
            await simpleApi.identify({
                action: 'identify',
                user_id: email,
                email: email,
                traits,
            });
        } catch (e) {
            console.error('Identify request failed', e);
        }
    };

    const track = async ({
        email,
        event,
        properties,
    }: {
        email: string;
        event: AnalyticsEvent | string;
        properties: Record<string, any>;
    }): Promise<void> => {
        try {
            await simpleApi.track({
                action: 'track',
                user_id: email,
                event: event,
                properties: properties,
            });
        } catch (e) {
            console.error('Track request failed', e);
        }
    };

    return {
        track,
        identify,
    };
};

export default useAnalytics;

import './toggle-switch.css';

interface ToggleSwitchProps {
    labelText: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
}

export const ToggleSwitch = ({ labelText, checked, onChange }: ToggleSwitchProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked);
    };

    return (
        <div className="toggle-switch-container">
            <p className="toggle-switch-tag">Tid</p>
            <label className="toggle-switch-label">
                <input
                    className="toggle-switch-input"
                    type="checkbox"
                    onChange={handleChange}
                    checked={checked}
                />
                <div className="toggle-switch" />
                <span className="toggle-switch-label-text">{labelText}</span>
            </label>
        </div>
    );
};

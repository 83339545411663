import styled from 'styled-components';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';

export const Root = styled.div`
    display: flex;
    align-items: center;
`;

export const Button = styled.button`
    display: flex;
    height: 100%;
    width: 70px;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    font-size: 1rem;
`;

export const Input = styled.input<{ noMargin?: boolean; noBorder?: boolean }>`
    border: ${(props) => (props.noBorder ? 'none' : `3px solid ${colors.black}`)};
    line-height: 70px;
    height: 70px;
    width: 20px;
    margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '20px')};
    font-size: 20px;
    padding: 0;
    text-align: center;
    -moz-appearance: textfield; // hide arrows in firefox

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        height: 50px;
        width: 50px;
        line-height: 50px;
    }

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:focus {
        outline: none;
        border-color: ${colors.purple}; // Color?
    }

    ::placeholder {
        color: ${colors.grey};
    }
`;

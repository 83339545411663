import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import simpleApi from '../utils/simple-api/simple-api';
import { Api, Bzzt } from '../types';
import { createBookingItems, sortBookingItems } from '../utils/booking-action-utils';

export type BookingState = {
    apiItemTypes: Api.Item[];
    bookingItems: Bzzt.BookingItem[];
    bookingCandidate: Bzzt.BookingCandidate;
    offerSearchWindow: Bzzt.OfferSearchWindow;
};

type StepOne = {
    items: {
        type: Bzzt.ItemType;
        size: Bzzt.ItemSize;
        count: number;
    }[];
    description: string;
    customerReference: string;
};

type StepTwo = {
    pickup: Bzzt.Contact;
};

type StepThree = {
    dropoff: Bzzt.Contact;
};

type StepFourNormal = {
    view: 'normal';
    offerSearchWindow: Bzzt.OfferSearchWindow;
    earliestPickupTime: string;
    latestPickupTime: string;
    earliestDropoffTime: string;
    latestDropoffTime: string;
    deliveryOfferId: string;
};

type StepFourDetailed = {
    view: 'detailed';
    earliestPickupTime: string;
    latestPickupTime: string;
    earliestDropoffTime: string;
    latestDropoffTime: string;
};

type StepFive = {
    referenceId: string;
};

type BookingActions = {
    initialize: (token: string) => Promise<void>;
    initialized: () => boolean;
    reset: () => void;
    setBookingStore: (bookingStoreState: Partial<BookingState>) => void;
    setBookingCandidate: (bookingCandidate: Bzzt.BookingCandidate) => void;
    setOfferSearchWindow: (offerSearchWindow: Bzzt.OfferSearchWindow) => void;
    setBookingCandidateView: (view: Bzzt.BookingCandidateView) => void;
    resetDeliveryOfferId: () => void;
    completeStepOne: (payload: StepOne) => void;
    completeStepTwo: (payload: StepTwo) => void;
    completeStepThree: (payload: StepThree) => void;
    completeStepFourNormal: (payload: StepFourNormal) => void;
    completeStepFourDetailed: (payload: StepFourDetailed) => void;
    completeStepFive: (payload: StepFive) => void;
};

export type BookingStore = BookingState & BookingActions;

const initialState: BookingState = {
    offerSearchWindow: {
        start: null,
        end: null,
    },
    apiItemTypes: [],
    bookingItems: [],
    bookingCandidate: {
        view: 'normal',
        items: [],
        description: null,
        customerReference: null,
        pickup: null,
        dropoff: null,
        earliestPickupTime: null,
        latestPickupTime: null,
        earliestDropoffTime: null,
        latestDropoffTime: null,
        deliveryOfferId: null,
        referenceId: null,
    },
};

export const useBookingStore = create<BookingStore>()(
    persist(
        (set, get) => ({
            ...initialState,
            initialize: async (token: string) => {
                const { data: apiItemTypes, status } = await simpleApi.getItemTypes(token);

                // Under the assumption that the API is up and running, we should always get a 200 response
                // since it is pulling this data from the cache
                if (status !== 200) {
                    throw new Error('Failed to initialize booking context');
                }

                if (apiItemTypes && Array.isArray(apiItemTypes) && apiItemTypes.length > 0) {
                    const bookingItems = createBookingItems(apiItemTypes);
                    const sortedBookingItems = sortBookingItems(bookingItems, [
                        Bzzt.ItemType.Parcel,
                        Bzzt.ItemType.Bag,
                        Bzzt.ItemType.Other,
                    ]);

                    return set({
                        apiItemTypes,
                        bookingItems: sortedBookingItems,
                    });
                }
            },
            initialized: () => {
                return get().apiItemTypes.length > 0 && get().bookingItems.length > 0;
            },
            reset: () => {
                return set(initialState);
            },
            setBookingStore: (bookingStoreState: Partial<BookingState>) => {
                return set((state) => ({ ...state, ...bookingStoreState }));
            },
            setBookingCandidate: (bookingCandidate: Bzzt.BookingCandidate) => {
                return set((state) => ({ ...state, bookingCandidate }));
            },
            setOfferSearchWindow: (offerSearchWindow: Bzzt.OfferSearchWindow) => {
                return set((state) => ({ ...state, offerSearchWindow }));
            },
            setBookingCandidateView: (view: Bzzt.BookingCandidateView) => {
                return set((state) => ({
                    ...state,
                    bookingCandidate: {
                        ...state.bookingCandidate,
                        view,
                    },
                }));
            },
            resetDeliveryOfferId: () => {
                return set((state) => ({ ...state, deliveryOfferId: null }));
            },
            completeStepOne: (payload: StepOne) => {
                return set((state) => ({
                    ...state,
                    bookingCandidate: {
                        ...state.bookingCandidate,
                        items: payload.items,
                        description: payload.description,
                        customerReference: payload.customerReference,
                    },
                }));
            },
            completeStepTwo: (payload: StepTwo) => {
                return set((state) => ({
                    ...state,
                    bookingCandidate: {
                        ...state.bookingCandidate,
                        pickup: payload.pickup,
                    },
                }));
            },
            completeStepThree: (payload: StepThree) => {
                return set((state) => ({
                    ...state,
                    bookingCandidate: {
                        ...state.bookingCandidate,
                        dropoff: payload.dropoff,
                    },
                }));
            },
            completeStepFourNormal: (payload: StepFourNormal) => {
                return set((state) => ({
                    ...state,
                    offerSearchWindow: payload.offerSearchWindow,
                    bookingCandidate: {
                        ...state.bookingCandidate,
                        view: payload.view,
                        earliestPickupTime: payload.earliestPickupTime,
                        latestPickupTime: payload.latestPickupTime,
                        earliestDropoffTime: payload.earliestDropoffTime,
                        latestDropoffTime: payload.latestDropoffTime,
                        deliveryOfferId: payload.deliveryOfferId,
                    },
                }));
            },
            completeStepFourDetailed: (payload: StepFourDetailed) => {
                return set((state) => ({
                    ...state,
                    bookingCandidate: {
                        ...state.bookingCandidate,
                        view: payload.view,
                        earliestPickupTime: payload.earliestPickupTime,
                        latestPickupTime: payload.latestPickupTime,
                        earliestDropoffTime: payload.earliestDropoffTime,
                        latestDropoffTime: payload.latestDropoffTime,
                    },
                }));
            },
            completeStepFive: (payload: StepFive) => {
                return set((state) => ({
                    ...state,
                    bookingCandidate: {
                        ...state.bookingCandidate,
                        referenceId: payload.referenceId,
                    },
                }));
            },
        }),
        {
            name: 'bookingStore',
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);

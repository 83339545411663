import { useEffect, useState } from 'react';
import { useHistory, RouteComponentProps, useLocation } from 'react-router-dom';
import { Text } from '../../app.styles';
import { BackButton } from '../../fragments/back-button';
import { Button } from '../../fragments/button';
import { ButtonRow } from '../../fragments/button-row';
import { Checkbox } from '../../fragments/checkbox';
import { Icon } from '../../fragments/icon';
import { InfoPopup } from '../../fragments/info-popup';
import { ProgressIndicator } from '../../fragments/progress-indicator';
import { Search } from '../../fragments/search';
import { TextArea } from '../../fragments/text-area';
import { TextInput } from '../../fragments/text-input';
import { Title } from '../../fragments/title';
import { Screen } from '../../structure/screen';
import { HoverInfo } from '../../fragments/hover-info';
import { RouteCheckState } from '../pickup';
import { Root, SelectedContainer } from './dropoff.styles';
import { checkInZone } from '../../utils/booking-action';
import { ERROR_MESSAGES, WARNING_MESSAGES } from '../../constants';
import { Bzzt } from '../../types';
import { bookingFlowAccessControl } from '../../utils/booking-action-utils';
import { BookingFlowStep } from '../../types/bzzt';
import { containsNumber } from '../../utils';
import { useBookingStore } from '../../stores/useBookingStore';
import restApi from '../../utils/simple-api/rest-api';

interface RouteProps extends RouteComponentProps<{}>, React.Props<{}> {}

export const Dropoff = ({}: RouteProps) => {
    const history = useHistory();
    const location = useLocation();
    const bookingStore = useBookingStore();

    const [shouldSaveContact, setShouldSaveContact] = useState(false);
    const [routeState, setRouteState] = useState<RouteCheckState>(RouteCheckState.NotChecked);
    const [, setWarningModalVisible] = useState(false);
    const [showOneTimeWarning, setShowOneTimeWarning] = useState<boolean>(false);
    const [warning, setWarning] = useState<{
        header: string | null;
        text: string | null;
    }>({
        header: null,
        text: null,
    });

    const [formState, setFormState] = useState<{
        dropoffName: string;
        dropoffPhoneNumber: string;
        dropoffInstructions: string;
        dropoffAddress: string;
        dropoffLocation: Bzzt.Location | null;
    }>(() => ({
        dropoffName: bookingStore.bookingCandidate.dropoff?.name ?? '',
        dropoffPhoneNumber: bookingStore.bookingCandidate.dropoff?.phoneNumber ?? '',
        dropoffInstructions: bookingStore.bookingCandidate.dropoff?.instructions ?? '',
        dropoffAddress: bookingStore.bookingCandidate.dropoff?.address ?? '',
        dropoffLocation: bookingStore.bookingCandidate.dropoff?.location ?? null,
    }));

    useEffect(() => {
        if (!bookingStore.initialized) return;

        const redirectTo = bookingFlowAccessControl(location, bookingStore.bookingCandidate);
        if (redirectTo) {
            history.replace(redirectTo);
        }
    }, [location, history, bookingStore.initialized, bookingStore.bookingCandidate]);

    useEffect(() => {
        const formStateIsEmpty =
            formState.dropoffName === '' &&
            formState.dropoffPhoneNumber === '' &&
            formState.dropoffInstructions === '' &&
            formState.dropoffAddress === '' &&
            formState.dropoffLocation === null;
        // If the formState is already populated, don't do anything
        if (!formStateIsEmpty) {
            return;
        }

        // If the booking context has a dropoff address, populate the form state from context state
        if (bookingStore.bookingCandidate.dropoff !== null && formStateIsEmpty) {
            setFormState({
                ...formState,
                dropoffName: bookingStore.bookingCandidate.dropoff?.name ?? '',
                dropoffPhoneNumber: bookingStore.bookingCandidate.dropoff?.phoneNumber ?? '',
                dropoffInstructions: bookingStore.bookingCandidate.dropoff?.instructions ?? '',
                dropoffAddress: bookingStore.bookingCandidate.dropoff?.address ?? '',
                dropoffLocation: bookingStore.bookingCandidate.dropoff?.location ?? null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingStore.bookingCandidate.dropoff]);

    useEffect(() => {
        if (!formState.dropoffLocation || !bookingStore.bookingCandidate.pickup?.location) {
            return setRouteState(RouteCheckState.NotChecked);
        }

        const runCheckInZone = async (from: Bzzt.Location, to: Bzzt.Location) => {
            const inZone = await checkInZone(from, to);
            if (!inZone) {
                setRouteState(RouteCheckState.OutOfZone);
                setWarning(WARNING_MESSAGES.out_of_zone);
                setShowOneTimeWarning(true);
            } else {
                if (formState.dropoffAddress && !containsNumber(formState.dropoffAddress)) {
                    setRouteState(RouteCheckState.Ok);
                    setWarning(WARNING_MESSAGES.address);
                    setShowOneTimeWarning(true);
                } else {
                    setWarning({ header: null, text: null });
                    setShowOneTimeWarning(false);
                    setRouteState(RouteCheckState.Ok);
                }
            }
        };
        runCheckInZone(bookingStore.bookingCandidate.pickup.location, formState.dropoffLocation);
    }, [
        bookingStore.bookingCandidate.pickup?.location,
        formState.dropoffLocation,
        formState.dropoffAddress,
    ]);

    const handleOneTimeWarningClosed = () => {
        if (containsNumber(formState.dropoffAddress)) {
            if (routeState === RouteCheckState.OutOfZone) {
                setShowOneTimeWarning(false);
                setWarning(WARNING_MESSAGES.out_of_zone);
            } else {
                setShowOneTimeWarning(false);
                setWarning({ header: null, text: null });
            }
        } else {
            if (routeState === RouteCheckState.OutOfZone) {
                setShowOneTimeWarning(false);
                setWarning(WARNING_MESSAGES.out_of_zone);
            } else {
                setShowOneTimeWarning(false);
                setWarning(WARNING_MESSAGES.address);
            }
        }
    };

    const handleSearchSelect = (type: 'contact' | 'place', item: Bzzt.Contact | object) => {
        if (type === 'contact') {
            const contact = item as Bzzt.Contact;
            setFormState({
                ...formState,
                dropoffName: contact.name,
                dropoffPhoneNumber: contact.phoneNumber,
                dropoffInstructions: contact.instructions,
                dropoffAddress: contact.address,
                dropoffLocation: contact.location ?? null,
            });
        } else {
            // NOTE(charlieroth): This is a hack, due to a lack of available types
            // from the npm package we use, this is a best guess at the shape of
            // the type
            const dropoffLocation = item as {
                address: string;
                lat: () => number;
                lng: () => number;
            };

            setFormState({
                ...formState,
                dropoffAddress: dropoffLocation.address,
                dropoffLocation: {
                    latitude: dropoffLocation.lat(),
                    longitude: dropoffLocation.lng(),
                },
            });

            if (!containsNumber(dropoffLocation.address)) {
                setWarning(WARNING_MESSAGES.address);
                setShowOneTimeWarning(true);
            }
        }
    };

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFormState({ ...formState, dropoffName: e.currentTarget.value });
    };

    const handlePhoneNumberChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFormState({ ...formState, dropoffPhoneNumber: e.currentTarget.value });
    };

    const handleInstructionsChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFormState({ ...formState, dropoffInstructions: e.currentTarget.value });
    };

    const showHoverWarning = () => {
        return warning.header !== null && warning.text !== null && showOneTimeWarning === false;
    };

    const formInputsDisabled = () => {
        return routeState !== RouteCheckState.Ok || !formState.dropoffAddress;
    };

    const canContinue = () => {
        return (
            formState.dropoffPhoneNumber !== '' &&
            formState.dropoffName !== '' &&
            formState.dropoffAddress !== '' &&
            formState.dropoffInstructions !== '' &&
            formState.dropoffLocation !== null &&
            routeState === RouteCheckState.Ok
        );
    };

    const handleBackClicked = () => {
        history.push(BookingFlowStep.DeliveryPickup);
    };

    const handleNextClicked = () => {
        // TODO(charlieroth): Show validation errors in the UI
        if (!canContinue()) return;

        if (shouldSaveContact) {
            restApi.createContact({
                name: formState.dropoffName,
                phoneNumber: formState.dropoffPhoneNumber,
                instructions: formState.dropoffInstructions,
                address: formState.dropoffAddress,
                location: formState.dropoffLocation ?? undefined,
            });
        }

        bookingStore.completeStepThree({
            dropoff: {
                name: formState.dropoffName,
                phoneNumber: formState.dropoffPhoneNumber,
                instructions: formState.dropoffInstructions,
                address: formState.dropoffAddress,
                location: formState.dropoffLocation ?? undefined,
            },
        });

        history.push(BookingFlowStep.DeliveryTime);
    };

    return (
        <Screen
            topContent={<Title marginTop={'1rem'}>Var ska vi lämna?</Title>}
            content={
                <Root>
                    <Search
                        placeholder="Sök kontakt eller plats"
                        name="search"
                        onSelect={handleSearchSelect}
                    />

                    <SelectedContainer>
                        <Icon type="marker" size="xs" />
                        <Text disabled>
                            {formState.dropoffAddress || 'Sök och välj en address'}
                        </Text>
                        {showHoverWarning() ? (
                            <HoverInfo
                                header={warning.header || 'Obs!'}
                                inline
                                icon="!"
                                text={warning.text || ERROR_MESSAGES.default}
                                onMouseOver={() => setWarningModalVisible(true)}
                                onMouseLeave={() => setWarningModalVisible(false)}
                            />
                        ) : null}
                    </SelectedContainer>
                    <TextInput
                        placeholder="Namn:"
                        value={formState.dropoffName}
                        onChange={handleNameChange}
                        disabled={formInputsDisabled()}
                    />
                    <TextInput
                        placeholder="Telefonnummer:"
                        value={formState.dropoffPhoneNumber}
                        onChange={handlePhoneNumberChange}
                        disabled={formInputsDisabled()}
                    />
                    <TextArea
                        placeholder="Instruktioner (portkod och annat viktigt)"
                        value={formState.dropoffInstructions}
                        onChange={handleInstructionsChange}
                        disabled={formInputsDisabled()}
                    />
                    <Checkbox
                        reverse
                        label="Spara kontakt"
                        onChange={(e) => setShouldSaveContact(e.target.checked)}
                        checked={shouldSaveContact}
                    />
                    {showOneTimeWarning && (
                        <InfoPopup
                            header={warning.header || 'Obs!'}
                            text={warning.text || ERROR_MESSAGES.default}
                            closePress={handleOneTimeWarningClosed}
                        />
                    )}
                </Root>
            }
            actionRowContent={
                <ButtonRow>
                    <BackButton onClick={handleBackClicked} />
                    <ProgressIndicator currentStep={3} totalSteps={5} />
                    <Button disabled={canContinue() === false} onClick={handleNextClicked}>
                        Nästa
                    </Button>
                </ButtonRow>
            }
        />
    );
};

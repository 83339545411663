import { colors } from '../../colors';
import { Bzzt } from '../../types';
import { TimeButton, TimeButtonLeft, TimeButtonRight } from './time.styles';

type BookableTimeProps = {
    deliveryOffer: Bzzt.DeliveryOffer;
    selected: boolean;
    onClick: () => void;
};

const BookableTime: React.FC<BookableTimeProps> = ({
    selected = false,
    deliveryOffer,
    onClick,
}) => {
    const earliestPickupTime = new Date(deliveryOffer.earliestPickupTime);
    const latestDropoffTime = new Date(deliveryOffer.latestDropoffTime);

    return (
        <TimeButton active={true} selected={selected} onClick={onClick}>
            <TimeButtonLeft>
                <div
                    style={{
                        backgroundColor: colors.yellow,
                        padding: '0px 5px',
                        borderRadius: '2px',
                    }}
                >
                    {earliestPickupTime.toLocaleTimeString(['sv-SE'], {
                        hour: '2-digit',
                        minute: '2-digit',
                    })}
                    {' - '}
                    {latestDropoffTime.toLocaleTimeString(['sv-SE'], {
                        hour: '2-digit',
                        minute: '2-digit',
                    })}
                </div>
            </TimeButtonLeft>
            <TimeButtonRight>{deliveryOffer.priceCategory}</TimeButtonRight>
        </TimeButton>
    );
};

export default BookableTime;

import {
    BookingCandidate,
    BookingCandidateView,
    BookingTimeFormState,
    DatePickerOption,
    OfferSearchWindow,
    TimePickerOption,
} from '../../types/bzzt';
import { capitalize } from '../../utils';

export const formatTime = (date: Date) => {
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
    return `${hours}:${minutes}`;
};

const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
};

export const roundUpToNearestMinuteInterval = (date: Date, interval: number) => {
    const minutes = date.getMinutes();

    const remainder = minutes % interval;
    if (remainder === 0) {
        return date;
    }
    const newDate = new Date(date.getTime() + (interval - remainder) * 60000);
    newDate.setSeconds(0, 0);
    return newDate;
};

export const timePickerOptions = (start: Date, end: Date, minuteInterval: number) => {
    const startingHour = start.getHours();
    const startingMinute = start.getMinutes();
    const endingHour = end.getHours();
    const endingMinute = end.getMinutes();

    const options: TimePickerOption[] = [];

    for (let hour = startingHour; hour <= endingHour; hour += 1) {
        // On the first iteration we want to use the starting minute, otherwise we want to start from 0
        const startingMinuteForIteration = hour === startingHour ? startingMinute : 0;
        const endingMinuteForIteration = hour === endingHour ? endingMinute : 59;
        for (
            let minute = startingMinuteForIteration;
            minute <= endingMinuteForIteration;
            minute += minuteInterval
        ) {
            const value = new Date(start);
            value.setHours(hour, minute, 0, 0);

            const label = formatTime(value);

            const timePickerOption: TimePickerOption = {
                label,
                value: value.toISOString(),
            };

            options.push(timePickerOption);
        }
    }
    return options;
};

export const differenceInDays = (start: Date, end: Date): number => {
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const diffInMs = Math.abs(end.getTime() - start.getTime());
    return Math.round(diffInMs / oneDay);
};

export const datePickerOptions = (start: Date, end: Date) => {
    const options = [];
    const numberOfDays = differenceInDays(start, end);
    for (let i = 0; i < numberOfDays; i++) {
        const date = new Date(new Date().setDate(start.getDate() + i));
        date.setHours(12, 0, 0, 0);
        let day = i == 0 ? 'idag' : date.toLocaleDateString('sv-SE', { weekday: 'long' });
        day = capitalize(day);
        options.push({
            label: `${formatDate(date)}, ${day}`,
            value: date.toISOString(),
        });
    }

    return options;
};

/**
 * As of 2023-10-27 the closing time of deliveries is hardcoded to 21:00
 *
 * Normal Booking: Offer windows are >= 1 hour
 * Detailed Booking: Window can be as small as 30 minutes but this is rare
 *
 * A reasonable cutoff time for deliveries is 1 hour before closing time
 * until operations tells us they can fufill orders in a tighter window
 *
 * @param now The current time
 * @param closing A configured closing time (currently 21:00)
 * @returns true if now is after the delivery cutoff time
 */
export const afterDeliveryCutoff = (now: Date, closing: Date): boolean => {
    const deliveryCuttoffDateTime = new Date(closing);
    deliveryCuttoffDateTime.setMinutes(deliveryCuttoffDateTime.getMinutes() - 60);
    return now.getDate() === closing.getDate() && now > deliveryCuttoffDateTime;
};

export const deliveryDateOptions = ({}: {}): DatePickerOption[] => {
    const start = new Date();
    start.setHours(12, 0, 0, 0);

    const end = new Date(start);
    end.setDate(end.getDate() + 14);
    end.setHours(12, 0, 0, 0);

    const options = [];
    const numberOfDays = differenceInDays(start, end);
    for (let i = 0; i < numberOfDays; i++) {
        const date = new Date(start);
        date.setDate(start.getDate() + i);
        date.setHours(12, 0, 0, 0);

        let day: string;
        if (date.toDateString() === new Date().toDateString()) {
            day = 'idag';
        } else {
            day = date.toLocaleDateString('sv-SE', { weekday: 'long' });
        }

        day = capitalize(day);
        options.push({
            label: `${formatDate(date)}, ${day}`,
            value: date.toISOString(),
        });
    }

    return options;
};

export const defaultDeliveryDate = ({
    now,
    selectedDeliveryDate,
    previousBooking,
    previousOfferWindow,
}: {
    now: Date;
    selectedDeliveryDate?: Date;
    previousBooking?: BookingCandidate;
    previousOfferWindow?: OfferSearchWindow;
}): Date => {
    let deliveryDate: Date;
    if (selectedDeliveryDate) {
        deliveryDate = new Date(selectedDeliveryDate);
    } else if (previousBooking && previousOfferWindow && previousBooking.earliestDropoffTime) {
        deliveryDate = new Date(previousBooking.earliestDropoffTime);
    } else {
        deliveryDate = new Date(now);
    }

    deliveryDate.setHours(12, 0, 0, 0);
    return deliveryDate;
};

export const offerSearchWindowOptions = ({
    now,
    opening,
    closing,
    interval,
    selectedDeliveryDate,
}: {
    now: Date;
    opening: Date;
    closing: Date;
    interval: number;
    selectedDeliveryDate: Date;
}) => {
    let startFloor: Date;
    // If the selected delivery date is today AND now is after opening, use the current time
    if (selectedDeliveryDate.toDateString() === new Date().toDateString() && now > opening) {
        startFloor = new Date(now);
        startFloor.setHours(now.getHours(), now.getMinutes(), 0, 0);
    } else {
        startFloor = opening;
    }

    startFloor = roundUpToNearestMinuteInterval(startFloor, interval);

    const startCeiling = new Date(startFloor);
    startCeiling.setHours(closing.getHours() - 1, 0, 0, 0);

    const startOptions = timePickerOptions(startFloor, startCeiling, interval);

    const endFloor = new Date(startFloor);
    endFloor.setHours(startFloor.getHours() + 1, startFloor.getMinutes(), 0, 0);

    const endCeiling = new Date(closing);
    const endOptions = timePickerOptions(endFloor, endCeiling, interval);

    return {
        start: startOptions,
        end: endOptions,
    };
};

export const defaultOfferSearchWindowDates = ({
    now,
    closing,
    opening,
    selectedDeliveryDate,
    previousBooking,
    previousOfferWindow,
}: {
    now: Date;
    opening: Date;
    closing: Date;
    selectedDeliveryDate: Date;
    previousBooking?: BookingCandidate;
    previousOfferWindow?: OfferSearchWindow;
}): { start: Date; end: Date } => {
    let start: Date;
    let end: Date;

    if (
        previousBooking &&
        previousOfferWindow &&
        previousOfferWindow.start &&
        previousOfferWindow.end &&
        new Date(previousOfferWindow.start) > now
    ) {
        start = new Date(previousOfferWindow.start);
        end = new Date(previousOfferWindow.end);
    } else if (selectedDeliveryDate.toDateString() === now.toDateString() && now > opening) {
        // If the selected delivery date is today AND now is after opening, use the current time
        start = new Date(now);
        start.setHours(now.getHours(), now.getMinutes(), 0, 0);
        end = closing;
    } else {
        start = new Date(opening);
        end = new Date(selectedDeliveryDate);
        end.setHours(closing.getHours(), closing.getMinutes(), 0, 0);
    }

    return {
        start: start,
        end: end,
    };
};

export const detailedTimeOptions = ({
    now,
    opening,
    closing,
    interval,
    selectedDeliveryDate,
}: {
    now: Date;
    opening: Date;
    closing: Date;
    interval: number;
    selectedDeliveryDate: Date;
}): {
    earliestPickup: TimePickerOption[];
    latestPickup: TimePickerOption[];
    earliestDropoff: TimePickerOption[];
    latestDropoff: TimePickerOption[];
} => {
    const epFloor = new Date(selectedDeliveryDate);
    if (selectedDeliveryDate.toDateString() === now.toDateString() && now > opening) {
        epFloor.setHours(now.getHours(), now.getMinutes(), 0, 0);
    } else {
        epFloor.setHours(opening.getHours(), opening.getMinutes(), 0, 0);
    }

    const epCeiling = new Date(epFloor);
    epCeiling.setHours(closing.getHours(), closing.getMinutes() - interval, 0, 0);

    const lpFloor = new Date(selectedDeliveryDate);
    if (selectedDeliveryDate.toDateString() === now.toDateString() && now > opening) {
        lpFloor.setHours(now.getHours(), now.getMinutes(), 0, 0);
    } else {
        lpFloor.setHours(opening.getHours(), opening.getMinutes(), 0, 0);
    }

    const lpCeiling = new Date(lpFloor);
    lpCeiling.setHours(closing.getHours(), closing.getMinutes() - interval, 0, 0);

    const edFloor = new Date(selectedDeliveryDate);
    if (selectedDeliveryDate.toDateString() === now.toDateString() && now > opening) {
        edFloor.setHours(now.getHours(), now.getMinutes() + interval, 0, 0);
    } else {
        edFloor.setHours(opening.getHours(), interval, 0, 0);
    }

    const edCeiling = new Date(edFloor);
    edCeiling.setHours(closing.getHours(), closing.getMinutes(), 0, 0);

    const ldFloor = new Date(selectedDeliveryDate);
    if (selectedDeliveryDate.toDateString() === now.toDateString() && now > opening) {
        ldFloor.setHours(now.getHours(), now.getMinutes() + interval, 0, 0);
    } else {
        ldFloor.setHours(opening.getHours(), interval, 0, 0);
    }

    const ldCeiling = new Date(ldFloor);
    ldCeiling.setHours(closing.getHours(), closing.getMinutes(), 0, 0);

    return {
        earliestPickup: timePickerOptions(epFloor, epCeiling, interval),
        latestPickup: timePickerOptions(epFloor, epCeiling, interval),
        earliestDropoff: timePickerOptions(edFloor, edCeiling, interval),
        latestDropoff: timePickerOptions(ldFloor, ldCeiling, interval),
    };
};

export const defaultDetailedTimeDates = ({
    now,
    opening,
    closing,
    interval,
    selectedDeliveryDate,
    previousBooking,
}: {
    now: Date;
    opening: Date;
    closing: Date;
    interval: number;
    selectedDeliveryDate: Date;
    previousBooking?: BookingCandidate;
}): {
    earliestPickup: Date;
    latestPickup: Date;
    earliestDropoff: Date;
    latestDropoff: Date;
} => {
    let earliestPickup: Date;
    let latestPickup: Date;
    let earliestDropoff: Date;
    let latestDropoff: Date;

    if (
        previousBooking &&
        previousBooking.earliestPickupTime &&
        previousBooking.latestPickupTime &&
        previousBooking.earliestDropoffTime &&
        previousBooking.latestDropoffTime &&
        new Date(previousBooking.earliestPickupTime) > now
    ) {
        earliestPickup = roundUpToNearestMinuteInterval(
            new Date(previousBooking.earliestPickupTime),
            interval
        );
        latestPickup = roundUpToNearestMinuteInterval(
            new Date(previousBooking.latestPickupTime),
            interval
        );
        earliestDropoff = roundUpToNearestMinuteInterval(
            new Date(previousBooking.earliestDropoffTime),
            interval
        );
        latestDropoff = roundUpToNearestMinuteInterval(
            new Date(previousBooking.latestDropoffTime),
            interval
        );
    } else if (selectedDeliveryDate.toDateString() === now.toDateString() && now > opening) {
        earliestPickup = new Date(now);
        earliestPickup.setHours(now.getHours(), now.getMinutes(), 0, 0);

        latestPickup = new Date(closing);
        latestPickup.setHours(closing.getHours() - 1, closing.getMinutes(), 0, 0);

        earliestDropoff = new Date(now);
        earliestDropoff.setHours(now.getHours(), now.getMinutes() + interval, 0, 0);

        latestDropoff = new Date(closing);
        latestDropoff.setHours(closing.getHours(), closing.getMinutes() - interval, 0, 0);
    } else {
        earliestPickup = new Date(selectedDeliveryDate);
        earliestPickup.setHours(opening.getHours(), 0, 0, 0);

        latestPickup = new Date(selectedDeliveryDate);
        latestPickup.setHours(closing.getHours() - 1, closing.getMinutes(), 0, 0);

        earliestDropoff = new Date(selectedDeliveryDate);
        earliestDropoff.setHours(opening.getHours(), interval, 0, 0);

        latestDropoff = new Date(selectedDeliveryDate);
        latestDropoff.setHours(closing.getHours(), closing.getMinutes() - interval, 0, 0);
    }

    return {
        earliestPickup: earliestPickup,
        latestPickup: latestPickup,
        earliestDropoff: earliestDropoff,
        latestDropoff: latestDropoff,
    };
};

export const bookingView = (
    previousBooking?: BookingCandidate,
    userPreferredBookingView?: string
) => {
    let view: BookingCandidateView = 'normal';
    if (previousBooking) {
        view = previousBooking.view;
    } else if (userPreferredBookingView) {
        view = userPreferredBookingView as BookingCandidateView;
    }

    return view;
};

export const createFormState = ({
    opening,
    closing,
    interval,
    userPreferredBookingView,
    previousBooking,
    previousOfferWindow,
    selectedDeliveryDate,
}: {
    opening: Date;
    closing: Date;
    interval: number;
    userPreferredBookingView?: string;
    previousBooking?: BookingCandidate;
    previousOfferWindow?: OfferSearchWindow;
    selectedDeliveryDate?: Date;
}): BookingTimeFormState => {
    const nowDate = new Date();
    nowDate.setMinutes(nowDate.getMinutes() + 1); // Add 1 minute to avoid booking in the past
    const now = roundUpToNearestMinuteInterval(nowDate, interval);

    const deliveryDateOpts = deliveryDateOptions({
        now,
        closing,
    });
    const deliveryDate = defaultDeliveryDate({
        now,
        selectedDeliveryDate,
        previousBooking,
        previousOfferWindow,
    });

    const offerSearchWindowOpts = offerSearchWindowOptions({
        now,
        opening,
        closing,
        interval,
        selectedDeliveryDate: deliveryDate,
    });
    const offerSearchWindowDates = defaultOfferSearchWindowDates({
        now,
        opening,
        closing,
        selectedDeliveryDate: deliveryDate,
        previousBooking,
        previousOfferWindow,
    });

    const detailedTimeOpts = detailedTimeOptions({
        now,
        opening,
        closing,
        interval,
        selectedDeliveryDate: deliveryDate,
    });
    const detailedTimeDates = defaultDetailedTimeDates({
        now,
        opening,
        closing,
        interval,
        selectedDeliveryDate: deliveryDate,
        previousBooking,
    });

    const view = bookingView(previousBooking, userPreferredBookingView);

    return {
        view: view,
        deliveryDate: {
            date: deliveryDate,
            options: deliveryDateOpts,
        },
        offerSearchWindow: {
            start: {
                date: offerSearchWindowDates.start,
                options: offerSearchWindowOpts.start,
            },
            end: {
                date: offerSearchWindowDates.end,
                options: offerSearchWindowOpts.end,
            },
        },
        earliestPickupTime: {
            date: detailedTimeDates.earliestPickup,
            options: detailedTimeOpts.earliestPickup,
        },
        latestPickupTime: {
            date: detailedTimeDates.latestPickup,
            options: detailedTimeOpts.latestPickup,
        },
        earliestDropoffTime: {
            date: detailedTimeDates.earliestDropoff,
            options: detailedTimeOpts.earliestDropoff,
        },
        latestDropoffTime: {
            date: detailedTimeDates.latestDropoff,
            options: detailedTimeOpts.latestDropoff,
        },
    };
};

import { useEffect, useState } from 'react';
import { useHistory, RouteComponentProps, useParams } from 'react-router-dom';
import { Text } from '../../../app.styles';
import { Button, Variant } from '../../../fragments/button';
import { ButtonRow } from '../../../fragments/button-row';
import { Icon } from '../../../fragments/icon';
import { InfoPopup } from '../../../fragments/info-popup';
import { Search } from '../../../fragments/search';
import { TextInput } from '../../../fragments/text-input';
import { Title } from '../../../fragments/title';
import { Screen } from '../../../structure/screen';
import { HoverInfo } from '../../../fragments/hover-info';
import { SelectedContainer } from '../../pickup/pickup.styles';
import { Root } from './edit-contact.styles';
import { Bzzt } from '../../../types';
import { ERROR_MESSAGES } from '../../../constants';
import restApi from '../../../utils/simple-api/rest-api';

interface RouteProps extends RouteComponentProps<{}>, React.Props<{}> {}

export const EditContact = ({}: RouteProps) => {
    const history = useHistory();
    const { id }: any = useParams();

    const [removeContactModalVisible, setRemoveContactModalVisible] = useState(false);
    const [warningModalVisible, setWarningModalVisible] = useState(false);
    const [formState, setFormState] = useState<{
        name: string;
        phoneNumber: string;
        instructions: string;
        address: string;
        location: Bzzt.Location | null;
    }>({
        name: '',
        phoneNumber: '',
        instructions: '',
        address: '',
        location: null,
    });

    useEffect(() => {
        const fetchContact = async () => {
            const { data: selectedContact } = await restApi.getContact(id);
            if (selectedContact) {
                setFormState({
                    name: selectedContact.name,
                    phoneNumber: selectedContact.phoneNumber,
                    instructions: selectedContact.instructions,
                    address: selectedContact.address,
                    location: selectedContact.location ?? null,
                });
            }
        };
        fetchContact();
    }, [id]);

    const handleSearchSelect = (typeOfItem: 'contact' | 'place', item: any) => {
        setFormState({
            ...formState,
            location: { latitude: item.lat(), longitude: item.lng() },
            address: item.address,
        });
    };

    const handleNameChange = (e: any) => {
        setFormState({
            ...formState,
            name: e.target.value,
        });
    };

    const handlePhoneNumberChange = (e: any) => {
        setFormState({
            ...formState,
            phoneNumber: e.target.value,
        });
    };

    const handleInstructionsChange = (e: any) => {
        setFormState({
            ...formState,
            instructions: e.target.value,
        });
    };

    const toggleWarningModal = () => {
        setWarningModalVisible((curr: boolean) => !curr);
    };

    const onDeletePress = () => {
        setRemoveContactModalVisible(true);
    };

    const onDeleteConfirm = async () => {
        setRemoveContactModalVisible(false);
        await restApi.deleteContact(id);
        history.goBack();
    };

    const containsNumber = (value: string) => /.*[0-9].*/.test(value);

    const canSave = () => {
        return (
            formState.name !== '' &&
            formState.phoneNumber !== '' &&
            formState.address !== '' &&
            containsNumber(formState.address) === true &&
            formState.instructions !== '' &&
            formState.location !== null
        );
    };

    const handleSaveClicked = () => {
        if (canSave()) {
            restApi.updateContact({
                id: id,
                name: formState.name,
                phoneNumber: formState.phoneNumber,
                instructions: formState.instructions,
                address: formState.address,
                location: formState.location ?? undefined,
            });
            history.goBack();
        }
    };

    return (
        <Screen
            topContent={<Title marginTop={'1rem'}>Redigera kontakt.</Title>}
            content={
                <Root>
                    <Search
                        placeholder="Sök plats"
                        name="search"
                        hideContacts
                        onSelect={handleSearchSelect}
                    />

                    <SelectedContainer>
                        <Icon type="marker" size="xs" />
                        <Text disabled>{formState.address || 'Sök och välj en address'}</Text>
                        {formState.address && !containsNumber(formState.address) ? (
                            <HoverInfo
                                header={'Obs!'}
                                inline
                                icon="!"
                                text={ERROR_MESSAGES.address_must_contain_number}
                                onMouseOver={() => {}}
                                onMouseLeave={() => {}}
                            />
                        ) : null}
                    </SelectedContainer>
                    <TextInput
                        placeholder="Namn/Företag:"
                        value={formState.name}
                        onChange={handleNameChange}
                    />
                    <TextInput
                        placeholder="Telefonnummer:"
                        value={formState.phoneNumber}
                        onChange={handlePhoneNumberChange}
                    />
                    <TextInput
                        placeholder="Upphämtningsinstruktioner:"
                        value={formState.instructions}
                        onChange={handleInstructionsChange}
                    />
                    {removeContactModalVisible && (
                        <InfoPopup
                            header="Vill du ta bort denna kontakten?"
                            positiveButtonText="Ja"
                            closePress={() => setRemoveContactModalVisible(false)}
                            onSubmit={onDeleteConfirm}
                        />
                    )}
                    {warningModalVisible && (
                        <InfoPopup
                            header={'Obs!'}
                            text={ERROR_MESSAGES.address_must_contain_number}
                            closePress={toggleWarningModal}
                        />
                    )}
                </Root>
            }
            actionRowContent={
                <ButtonRow>
                    <Button variant={Variant.Negative} onClick={onDeletePress}>
                        Ta bort
                    </Button>
                    <Button disabled={canSave() === false} onClick={handleSaveClicked}>
                        Spara
                    </Button>
                </ButtonRow>
            }
        />
    );
};

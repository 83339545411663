import { Api } from '../../types';
import { Bzzt } from '../../types';

const REACT_APP_BZZT_REST_API_URL = process.env.REACT_APP_BZZT_REST_API_URL;

export class RestApi {
    private async call<T>(
        endpoint: string,
        method = 'GET',
        data?: {},
        token?: string
    ): Promise<Api.Response<T>> {
        const headers = new Headers({
            'Content-Type': 'application/json',
        });

        let authToken: string | null = null;
        if (token) {
            authToken = token;
        } else {
            const userStoreJSON = sessionStorage.getItem('userStore');
            const userStore = userStoreJSON ? JSON.parse(userStoreJSON) : null;
            authToken = userStore.state.token;
        }

        if (authToken) {
            headers.set('Authorization', `Bearer ${authToken}`);
        }

        const response = await fetch(REACT_APP_BZZT_REST_API_URL + endpoint, {
            headers,
            method,
            body: JSON.stringify(data),
        });

        try {
            const responseData = await response.json();
            if (responseData.type === 'authentication_failed' || response.status === 401) {
                window.location.replace('/logout');
            }
            return { data: responseData.data, status: response.status };
        } catch {
            return { data: null, status: response.status };
        }
    }

    async getContacts(): Promise<Api.Response<Array<Bzzt.Contact>>> {
        const response = await this.call<Array<Api.Contact>>('/contacts', 'GET');
        if (response.status == 200 && response.data !== null) {
            return {
                data: this.contactListFromResponseData(response.data),
                status: response.status,
            };
        }
        return { data: [], status: response.status };
    }

    async getContact(id: String): Promise<Api.Response<Bzzt.Contact>> {
        const response = await this.call<Api.Contact>('/contacts/' + id, 'GET');
        if (response.status == 200 && response.data !== null) {
            return {
                data: this.apiContactToBzztContact(response.data),
                status: response.status,
            };
        }
        return { data: null, status: response.status };
    }

    async createContact(contact: Bzzt.Contact): Promise<Api.Response<any>> {
        return this.call<Api.Contact>('/contacts/', 'POST', {
            contact: this.bzztContactToApiContact(contact),
        });
    }

    async updateContact(contact: Bzzt.Contact): Promise<Api.Response<any>> {
        return this.call<Api.Contact>('/contacts/' + contact.id, 'PATCH', {
            contact: this.bzztContactToApiContact(contact),
        });
    }

    async deleteContact(id: String): Promise<Api.Response<any>> {
        return this.call<Api.Contact>('/contacts/' + id, 'DELETE');
    }

    contactListFromResponseData(data: Array<Api.Contact>): Array<Bzzt.Contact> {
        return data.map((c) => this.apiContactToBzztContact(c));
    }

    apiContactToBzztContact(contact: Api.Contact): Bzzt.Contact {
        return {
            id: contact.id,
            name: contact.contact_name,
            phoneNumber: contact.contact_phone_number,
            instructions: contact.instructions,
            address: contact.address_formatted,
            location: {
                longitude: contact.point.coordinates[0],
                latitude: contact.point.coordinates[1],
            },
        };
    }

    bzztContactToApiContact(contact: Bzzt.Contact): Api.Contact {
        if (contact.location?.longitude && contact.location?.latitude) {
            return {
                id: contact.id,
                contact_name: contact.name,
                contact_phone_number: contact.phoneNumber,
                instructions: contact.instructions,
                address_formatted: contact.address,
                point: {
                    type: 'Point',
                    coordinates: [contact.location?.longitude, contact.location?.latitude],
                },
            };
        } else {
            throw new Error('missing contact location data');
        }
    }
}

const restApi = new RestApi();
export default restApi;

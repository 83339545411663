import styled from 'styled-components';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';

export const Root = styled.div``;

export const BookingList = styled.div`
    width: 100%;
`;

export const BookingListItem = styled.div<{ header?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    &:nth-child(even) {
        background-color: ${colors.lightyellow};
    }

    cursor: ${({ header }) => (header ? 'default' : 'pointer')};

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        &:first-child {
            display: none;
        }
    }
`;

import { dimensions } from '../../dimensions';
import styled, { createGlobalStyle } from 'styled-components';

type Props = {
    fontWeight?: string;
    marginTop?: string;
};

interface GlobalStyleProps {
    printOption: '' | 'A4 Liggandes' | 'A5 Ståendes';
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`

@media print {
    @page {
      size: ${(props) =>
          props.printOption === 'A4 Liggandes'
              ? 'A4 landscape'
              : props.printOption === 'A5 Ståendes'
              ? 'A5 portrait'
              : 'A4 landscape'};
      margin: 0;
    }

    body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin: 0;
    }

#print-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: ${(props) => (props.printOption === 'A4 Liggandes' ? '50%' : '100%')};
      height: 100%;
      padding-top: 20mm;
      ${(props) =>
          props.printOption === 'A4 Liggandes'
              ? `
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            transform: translateX(20mm) scale(1.41);
            transform-origin: right top;
            padding-top: 20mm;
          `
              : ''}
    }
  }
`;

export const BusinessName = styled.h2`
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0.5rem 0 0;
    text-align: center;
    font-family: Russo One, sans-serif !important;

    @media print {
        text-align: center;
        margin-top: 10px;
`;

export const Title = styled.h2`
    font-family: sofia pro, sans-serif !important;
    font-size: 1.2rem;
    topmargin: 10px;
    margin: 0.5rem 0 0;
    margin-bottom: 15px;

    @media (max-width: ${dimensions.mobileBreakpoint}) @media print {
        font-size: '1rem'; /* Adjust font size for print */
        text-align: center;
    }

    @media print {
        font-size: 1rem; /* Adjust font size for print */
        text-align: center;
        margin-top: 10px;
        margin-bottom: 25px;
    }
`;

export const Root = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: sofia pro, sans-serif !important;
    grid-template-columns: 1fr, 1fr;
    width: auto;
    margin: auto;
    }

    @media (max-width: ${dimensions.tabletBreakpoint}) {
        width: auto;
        padding-left: 20px;

    @media (min-width: ${dimensions.tabletBreakpoint}) {
        justify-content: center; /* Adjust alignment for desktop view */
    }

    @media print {
        width: auto;
        margin: 0;
    }
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    width: 100%;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 8px;
`;

export const TopContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media print {
        width: auto;
        margin: 0;
        align-items: center;
    }
`;

export const Container = styled.div`
    padding-bottom: 10px;
    justify-content: center;
`;

export const Name = styled.p<Props>`
    font-size: 0.75rem;
    padding-right: 10px;
    margin-bottom: 0px;
    margin-top: ${(props) => (props.theme.marginTop ? props.theme.marginTop : '0px')};
    font-weight: ${(props) => (props.theme.fontWeight ? props.theme.fontWeight : 'normal')};

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        font-size: 0.8rem;
    }

    @media print {
        font-size: 10pt; /* Adjust font size for print */
    }
`;

export const Value = styled.p<{ color?: string }>`
    font-size: 0.75rem;
    font-weight: normal;
    margin: 0px;
    text-align: left;
    ${(props) => (props.color ? `color: ${props.color};` : '')}

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        font-size: 0.8rem;
    }

    @media print {
        font-size: 10pt; /* Adjust font size for print */
    }
`;

export const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    width: min-content;
    align-items: flex-start;
    text-align: left;
    gap: 0 3px;

    // Remove right borderRadius from first button
    & > :first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    // Remove left borderRadius from second button
    & > :last-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
`;

export const Logo = styled.img`
    display: block;
    width: 300px;
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 25px;
    @media (max-width: ${dimensions.mobileBreakpoint}) {
        display: block;
        width: 300px;
        align-self: center;
    }
    @media print {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 25px;
    }
`;

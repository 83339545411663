import { Root, H2 } from './title.styles';

interface TitleProps {
    children: string;
    noPaddingBottom?: boolean;
    marginTop?: string;
    show?: boolean;
}

export function Title(props: TitleProps) {
    const { children, noPaddingBottom = false, marginTop = '5rem' } = props;

    return (
        <Root marginTop={marginTop}>
            <H2 noPaddingBottom={noPaddingBottom}>{children}</H2>
        </Root>
    );
}

import styled from 'styled-components';
import { dimensions } from '../../dimensions';

export const Root = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;

    @media (min-width: ${dimensions.mobileBreakpoint}) {
        margin-top: ${dimensions.header};
    }

    @media print {
        margin-top: 0;
    }
`;

import styled from 'styled-components';
import { dimensions } from '../../dimensions';

export const Root = styled.div`
    display: grid;
    justify-content: center;
    gap: 15px;
    font-family: sofia pro, sans-serif !important;
    grid-template-columns: 1fr;
    width: 40vw;
    margin: 0 auto;

    @media (max-width: ${dimensions.tabletBreakpoint}) {
        margin-left: 60%;
    }
`;

export const Text = styled.p`
    font-size: 0.6rem;
    margin: 0;
    text-align: left;
    font-weight: 400;
`;

import { Button, Variant } from '../button';

type BackButtonProps = {
    onClick: () => void;
};

export const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
    return (
        <Button onClick={onClick} variant={Variant.Primary}>
            Bakåt
        </Button>
    );
};

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserStore } from '../../stores/useUserStore';
// import { useBookingStore } from '../../hooks/useBookingStore';
import useAnalytics from '../../hooks/useAnalytics';
import { Title } from '../../fragments/title';
import { Screen } from '../../structure/screen';

export const Logout = () => {
    const history = useHistory();
    const userStore = useUserStore();
    // const bookingStore = useBookingStore();
    const analytics = useAnalytics();

    useEffect(() => {
        void analytics.track({
            email: userStore.email ? userStore.email : 'unknown',
            event: 'logout',
            properties: {},
        });

        setTimeout(() => {
            userStore.logout();
            history.push('/login');
        }, 1000);

        // Ignore because we only want this to run one time when the component is mounted to the DOM
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Screen
            compact
            topContent={<Title marginTop={'5rem'}>Du är nu utloggad!</Title>}
            content={null}
        />
    );
};

import styled from 'styled-components';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';

export const Root = styled.div<{ size?: 'xs' | 's' | 'm'; isButton?: boolean; absolute?: boolean }>`
    width: ${({ size }) => (size === 'xs' ? '1rem' : size === 's' ? '2rem' : '7rem')};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    cursor: ${({ isButton }) => (isButton ? 'pointer' : 'default')};
    position: ${({ absolute }) => (absolute ? 'absolute' : 'auto')}; // Hack for checkbox icon
    left: 50%;
    transform: ${({ absolute }) =>
        absolute ? 'translateX(-50%);' : 'auto'}; // Hack for checkbox icon

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        width: ${({ size }) => (size === 'xs' ? '1rem' : size === 's' ? '2.5rem' : '6rem')};
    }

    @media (max-width: ${dimensions.mobileBreakpointExtraSmall}) {
        width: ${({ size }) => (size === 'xs' ? '1rem' : size === 's' ? '2.5rem' : '5rem')};
    }
`;

export const Svg = styled.svg`
    width: 100%;
`;

export const CircleChecked = styled.path`
    fill: ${colors.yellow};
`;

export const Circle = styled.path`
    fill: ${colors.grey};
`;

export const LetterCircle = styled.circle`
    fill: ${colors.darkIndicator};
`;

export const LetterCircleChecked = styled.circle`
    fill: ${colors.black};
    margin: 5px;
    border: 2px solid ${colors.black};
`;

export const Text = styled.text`
    fill: ${colors.white};
    font-size: 12rem;

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        font-size: 20rem;
    }
`;

import styled from 'styled-components';
import { dimensions } from '../../dimensions';

export const Root = styled.div`
    @media (max-width: ${dimensions.mobileBreakpoint}) {
        padding: 6px 20px;
    }
`;

export const SelectedContainer = styled.div`
    padding: 20px 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    & > *:first-child {
        margin: 0 18px;
    }
`;

import styled from 'styled-components';
import { dimensions } from '../../dimensions';

export const HoverInfoRoot = styled.div<{ inline: boolean }>`
    ${({ inline }) =>
        inline
            ? 'margin-top: -5px; position: relative; z-index: 1000;'
            : `position: absolute;
  right: -5px;
  top: 0px;`}
`;

export const HoverInfoPopup = styled.div<{ index?: number }>`
    position: absolute;
    top: 0.15em;
    left: calc(-200px);
    min-width: 536px;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        display: none;
    }
`;

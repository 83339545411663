import styled from 'styled-components';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';

export const Root = styled.button<{
    paddingWidth: string;
    paddingHeight: string;
    backgroundColor: string;
    fillParent: boolean;
    textSize: 'small' | 'medium';
}>`
    min-width: 200px;
    font-size: ${({ textSize }) => (textSize === 'small' ? '1rem' : '1.4rem')};
    padding-top: ${({ textSize, paddingHeight }) =>
        textSize === 'small' ? `calc(${paddingHeight} + 0.4rem)` : paddingHeight};
    padding-bottom: ${({ textSize, paddingHeight }) =>
        textSize === 'small' ? `calc(${paddingHeight} + 0.4rem)` : paddingHeight};
    padding-right: ${({ paddingWidth }) => paddingWidth};
    padding-left: ${({ paddingWidth }) => paddingWidth};
    border-radius: 0.8rem;
    border: none;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${colors.white};
    font-family: Russo One;
    cursor: pointer;
    max-height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ fillParent }) => fillParent && 'flex: 0 1;'}

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        min-width: 130px;
    }

    :hover {
        opacity: 0.9;
    }

    :active {
        background-color: ${({ backgroundColor }) => backgroundColor}90;
    }

    :disabled {
        cursor: not-allowed;
        background-color: ${({ backgroundColor }) => backgroundColor}50;
    }
`;

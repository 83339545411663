import { colors } from '../../colors';
import { Root } from './button.styles';

export enum Variant {
    Negative,
    Primary,
    Print,
    Secondary,
}

function getBackgroundColor(variant: Variant) {
    switch (variant) {
        case Variant.Negative:
            return colors.pink;
        case Variant.Secondary:
            return colors.lightvoilet;
        case Variant.Print:
            return colors.darkvoilet;
        case Variant.Primary:
        default:
            return colors.black;
    }
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    paddingWidth?: string;
    paddingHeight?: string;
    variant?: Variant;
    fillParent?: boolean;
    textSize?: 'small' | 'medium';
}

export function Button(props: ButtonProps) {
    const {
        paddingWidth = '0.5rem',
        paddingHeight = '0.5rem',
        variant = Variant.Primary,
        children,
        fillParent = false,
        textSize = 'medium',
        ...rest
    } = props;

    return (
        <Root
            paddingWidth={paddingWidth}
            paddingHeight={paddingHeight}
            fillParent={fillParent}
            backgroundColor={getBackgroundColor(variant)}
            textSize={textSize}
            {...rest}
        >
            {children}
        </Root>
    );
}

import styled from 'styled-components';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';

export const CheckboxWrapper = styled.div<{ reverse: boolean; noMargin?: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '30px')};

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        margin-top: 0px;
    }
`;

export const CheckboxCont = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

export const Label = styled.label<{ align: 'left' | 'right' }>`
    font-size: 20px;
    font-weight: normal;
    margin: ${(props) => (props.align === 'right' ? '0 0 0 10px' : '0 10px 0 0')};
`;

export const Input = styled.input<{ reverse: boolean }>`
    width: 25px;
    height: 25px;
    ${(props) => (props.reverse ? 'margin-left: 10px;' : 'margin-right: 10px;')};
`;

export const CheckboxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const HiddenCheckbox = styled.input`
    // border: 0;
    // clip: rect(0 0 0 0);
    // clippath: inset(50%);
    // height: 1px;
    // margin: -1px;
    // overflow: hidden;
    // padding: 0;
    // position: absolute;
    // white-space: nowrap;
    // width: 1px;
    width: 40px;
    height: 40px;
    margin-right: -40px;
    z-index: 20;
    position: relative;
    opacity: 0;
`;

export const StyledCheckbox = styled.div`
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    transition: all 150ms;
    background: ${colors.yellow};
    z-index: 1;
    position: relative;

    &:focus {
        outline: 0;
        border: 0;
    }
`;

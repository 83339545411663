/* PrivateRoute component definition */
import React, { ReactElement } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useUserStore } from '../../stores/useUserStore';
import { AuthStatus } from '../../types/bzzt';
import { Loading } from '../../screens/loading';

type PrivateRouteProps = RouteProps & {
    component: React.FunctionComponent;
};

export const PrivateRoute = ({
    component: Component,
    ...rest
}: PrivateRouteProps): ReactElement => {
    const userStore = useUserStore();
    return (
        <Route
            {...rest}
            render={(props) => {
                if (userStore.status === AuthStatus.PENDING) {
                    return <Loading />;
                } else if (userStore.authenticated()) {
                    return <Component {...props} />;
                } else if (!userStore.authenticated()) {
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
                }
            }}
        />
    );
};

import styled from 'styled-components';

export const Root = styled.label`
    padding: 0.2rem;
    -webkit-tap-highlight-color: transparent;
`;

export const Input = styled.input`
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
`;

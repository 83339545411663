import { CircleChecked, Svg } from '../icon.styles';

export const Bag = (props: any) => {
    const { checked } = props;

    return (
        <Svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 554 554">
            {checked && (
                <CircleChecked d="M244 1.1C149.7 12.5 70.3 67.3 28.7 149.7 8.8 189.1 0 226.7 0 272.5s8.8 83.4 28.7 122.8c42.1 83.4 123.4 138.9 218.1 148.8 15 1.5 57.3.6 70.7-1.5 50.3-8.2 92-26 132-56.5 11.9-9 36.4-33.2 45.9-45.1 29.2-37 47.5-78.1 55.8-125.6 2-11.4 2.3-16.3 2.3-42.9 0-26.6-.3-31.5-2.3-42.9-5.4-30.8-13.3-54.6-27.3-81.6-13.3-25.6-27.7-44.9-49.8-67C431.2 38.4 380 12.7 317.5 2.4 305.8.5 256.1-.4 244 1.1z" />
            )}
            <path d="M244 1.1C149.7 12.5 70.3 67.3 28.7 149.7 8.8 189.1 0 226.7 0 272.5s8.8 83.4 28.7 122.8c42.1 83.4 123.4 138.9 218.1 148.8 15 1.5 57.3.6 70.7-1.5 50.3-8.2 92-26 132-56.5 11.9-9 36.4-33.2 45.9-45.1 29.2-37 47.5-78.1 55.8-125.6 2-11.4 2.3-16.3 2.3-42.9 0-26.6-.3-31.5-2.3-42.9-5.4-30.8-13.3-54.6-27.3-81.6-13.3-25.6-27.7-44.9-49.8-67C431.2 38.4 380 12.7 317.5 2.4 305.8.5 256.1-.4 244 1.1zm83 20.3C387.2 31.7 435.9 59 472.7 103c50.9 60.9 70.4 149.6 50.1 228-13.2 51.1-46.8 101.4-90.3 135.3-68.4 53.3-159.6 71.5-236.3 47.2-94.7-30-161.8-114.1-175.3-220-2-15.2-1.7-50.5.5-64.5 9.2-59.5 39-110.1 87.8-149.1 39.7-31.7 90.3-52.6 144.1-59.4 15.3-1.9 60.4-1.4 73.7.9z" />
            <path d="m297.4 91.2-10.1 7.2-.7 16c-.4 8.9-.8 16.2-1 16.3-.1.2-9.2-3.4-20.2-7.8l-19.9-8.1-53.5 24-53.5 23.9-.3 108-.2 107.9 3.7 1.8c15.6 7.5 170 77.9 170.8 77.9.6 0 24.1-11.9 52.3-26.4l51.2-26.4-.2-110.8-.3-110.9-25.3-10.1-25.3-10.2.7-24.1.7-24-5.1-7.1-5.2-7-23-8.7c-12.7-4.7-23.6-8.6-24.3-8.6-.7 0-5.8 3.2-11.3 7.2zm31.1 11.8c9.2 3.3 17.5 6.4 18.4 7 1 .5 2.8 3.7 4.1 7.1 2.1 5.8 2.2 7.5 1.8 23.1-.2 9.2-.7 17-1 17.3-.3.3-12.4-4-26.9-9.7l-26.4-10.3v-33.9l5.5-3.2c3-1.8 6-3.3 6.7-3.4.6 0 8.7 2.7 17.8 6zm-91.9 51.4c22.6 9.5 23.7 10.2 30.1 18.5.9 1.1 1.3 8.3 1.4 25.1 0 12.9.4 25.9.7 28.8l.7 5.4 18.8 8.5 18.7 8.6v96.3c0 53-.2 96.4-.5 96.4-.9 0-148.7-70-151.1-71.5-2-1.3-2-2.2-3.2-97.1-.6-52.6-1-95.9-.8-96.1.2-.2 9.1 3.7 19.7 8.7l19.4 9 .5-18 .5-18 10-7c5.5-3.8 10.7-6.9 11.5-6.9.8 0 11.5 4.1 23.6 9.3zm166.5 132.8c-.6 45.3-1.1 88.8-1.1 96.8v14.5l-40.1 21.3c-22 11.8-40.2 21.2-40.4 21-.1-.2-.5-43.4-.8-96l-.5-95.6 41.6-22.5c22.9-12.4 41.9-22.4 42-22.2.2.2-.1 37.4-.7 82.7z" />
            <path d="m209 159.5-5.5 2.3v40.5l25.4 11.4c14 6.2 25.5 11.2 25.7 11.1.1-.2.4-9.6.5-21l.4-20.6-4-5.8c-3.9-5.6-4.3-5.8-19.9-13-8.8-4.1-16.2-7.4-16.5-7.4-.3.1-3.1 1.1-6.1 2.5z" />
        </Svg>
    );
};

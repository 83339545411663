import { Api } from '../../types';

const REACT_APP_AUTH_URL = process.env.REACT_APP_AUTH_URL;
const REACT_APP_BZZT_API_URL = process.env.REACT_APP_BZZT_API_URL;

export const Endpoint = {
    login: `${REACT_APP_AUTH_URL}/login`,
    authenticated: `${REACT_APP_BZZT_API_URL}/user_config`, // TODO FIX`${REACT_APP(_AUTH_URL}/authenticated`,
    userConfig: `${REACT_APP_BZZT_API_URL}/user_config`,
    bookRide: `${REACT_APP_BZZT_API_URL}/book_ride`,
    bookableTimes: `${REACT_APP_BZZT_API_URL}/find_bookable_times`,
    bookings: `${REACT_APP_BZZT_API_URL}/get_bookings`,
    cancelBooking: `${REACT_APP_BZZT_API_URL}/cancel_booking`,
    in_zone: `${REACT_APP_BZZT_API_URL}/in_zone`,
    location_info: `${REACT_APP_BZZT_API_URL}/location_info`,
    requestDeliveryOffers: `${REACT_APP_BZZT_API_URL}/request_delivery_offers`,
    parcelTypes: `${REACT_APP_BZZT_API_URL}/get_parcel_types`,
    acceptDeliveryOffer: `${REACT_APP_BZZT_API_URL}/accept_delivery_offer`,
    checkAvailability: `${REACT_APP_BZZT_API_URL}/check_availability`,
    juneProxy: `${REACT_APP_BZZT_API_URL}/script`,
};

export class SimpleApi {
    private async call<T>(
        endpoint: string,
        method = 'GET',
        data?: {},
        token?: string
    ): Promise<Api.Response<T>> {
        const headers = new Headers({
            'Content-Type': 'application/json',
        });

        let authToken: string | null = null;
        if (token) {
            authToken = token;
        } else {
            const userStoreJSON = sessionStorage.getItem('userStore');
            const userStore = userStoreJSON ? JSON.parse(userStoreJSON) : null;
            authToken = userStore.state.token;
        }

        if (authToken) {
            headers.set('Authorization', `Bearer ${authToken}`);
        }

        const response = await fetch(endpoint, {
            headers,
            method,
            body: JSON.stringify(data),
        });

        try {
            const responseData = await response.json();
            if (responseData.type === 'authentication_failed' || response.status === 401) {
                if (endpoint !== Endpoint.login && endpoint !== Endpoint.juneProxy) {
                    window.location.replace('/logout');
                }
            }
            return { data: responseData, status: response.status };
        } catch {
            return { data: null, status: response.status };
        }
    }

    async login(username: string, password: string): Promise<Api.Response<any>> {
        return this.call(Endpoint.login, 'POST', { username, password });
    }

    async authenticated(token: string): Promise<Api.Response<any>> {
        return this.call(Endpoint.authenticated, 'POST', undefined, token);
    }

    async getUserConfig(token: string): Promise<Api.Response<any>> {
        return this.call(Endpoint.userConfig, 'POST', undefined, token);
    }

    // TODO: Rename data to payload
    async findBookableTimes(data: any): Promise<Api.Response<{ times: any }>> {
        return this.call(Endpoint.bookableTimes, 'POST', data);
    }

    // TODO: Rename data to payload
    async getBookings(data: Api.GetBookingsPayload): Promise<Api.Response<Api.GetBookingsData>> {
        return this.call(Endpoint.bookings, 'POST', data);
    }

    // TODO: Rename data to payload
    async getBooking(data: Api.GetBookingPayload): Promise<Api.Response<Api.GetBookingData>> {
        return this.call(Endpoint.bookings, 'POST', data);
    }

    // TODO: Rename data to payload
    async cancelBooking(data: any): Promise<Api.Response<any>> {
        return this.call(Endpoint.cancelBooking, 'POST', data);
    }

    // TODO: Rename data to payload
    async checkInZone(
        data: Api.CheckInZonePayload
    ): Promise<Api.Response<Api.CheckInZoneResponseData>> {
        return this.call(Endpoint.in_zone, 'POST', data);
    }

    async getLocationInfo(data: Api.LocationInfoPayload): Promise<Api.Response<any>> {
        return this.call(Endpoint.location_info, 'POST', data);
    }

    // TODO: Rename data to payload
    async requestDeliveryOffers(
        data: Api.RequestDeliveryOffersPayload
    ): Promise<Api.Response<Api.RequestDeliveryOffersData>> {
        return this.call(Endpoint.requestDeliveryOffers, 'POST', data);
    }

    async getItemTypes(token: string): Promise<Api.Response<Api.GetItemTypesResponseData>> {
        return this.call(Endpoint.parcelTypes, 'POST', undefined, token);
    }

    // TODO: Add response data type
    async acceptDeliveryOffer(id: string): Promise<Api.Response<any>> {
        return this.call(Endpoint.acceptDeliveryOffer, 'POST', { id });
    }

    async bookRide(payload: Api.BookRidePayload): Promise<Api.Response<Api.BookRideData>> {
        return this.call(Endpoint.bookRide, 'POST', payload);
    }

    async checkAvailability(
        payload: Api.CheckAvailabilityPayload
    ): Promise<Api.Response<Api.CheckAvailabilityData>> {
        return this.call(Endpoint.checkAvailability, 'POST', payload);
    }

    async identify(payload: Api.IdentifyPayload): Promise<Api.Response<Api.IdentifyData>> {
        return this.call(Endpoint.juneProxy, 'POST', payload);
    }

    async track(payload: Api.TrackPayload): Promise<Api.Response<Api.TrackData>> {
        return this.call(Endpoint.juneProxy, 'POST', payload);
    }
}

const simpleApi = new SimpleApi();
export default simpleApi;

import { Spinner } from '../../fragments/spinner';
import { Root } from './loading.styles';

export const Loading: React.FunctionComponent = () => {
    return (
        <Root>
            <Spinner />
        </Root>
    );
};

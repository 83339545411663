import styled from 'styled-components';
import { Flex } from '../../app.styles';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';

export const Root = styled.div`
    display: grid;
    gap: 20px;
    background-color: ${colors.white};
`;

export const RadioWrapper = styled.div`
    padding: 0;
    position: relative;
`;

export const Line = styled.div`
    width: 100%;
`;

export const ItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.white};
    z-index: 3;
`;

export const SizeBarWrapper = styled.div<{ show?: boolean }>`
    display: flex;
    border-top: 5px solid ${colors.black};
    border-bottom: 5px solid ${colors.black};
    padding: ${({ show }) => (show ? '20px 0' : '0')};
    margin: ${({ show }) => (show ? '20px 0' : '-10px 0')};
    background-color: ${colors.white};
    transform: ${({ show }) =>
        show
            ? 'translateY(0)'
            : 'translateY(calc(-100% - 20px + 14px))'}; // 20px padding + 5px border
    transition: all 0.3s ease-in-out;
    z-index: 2;

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        justify-content: center;
    }
`;

export const SizeBar = styled.div<{ show?: boolean }>`
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    height: ${({ show }) => (show ? 'auto' : '20px')};
    opacity: ${({ show }) => (show ? '1' : '0')};
    margin-bottom: ${({ show }) => (show ? '0' : '-10px')};
    background-color: ${colors.white};
`;

export const Circle = styled.div<{ color?: string }>`
    background: ${({ color }) => color || colors.black};
    border-color: ${({ color }) => color || colors.black};
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
`;

export const Container = styled.div<{ show?: boolean }>`
    display: flex;
    flex-direction: column;
    margin-top: ${({ show }) => (show ? '0' : '-50%')};
    z-index: ${({ show }) => (show ? '1' : '-10')};
    transition: all 0.3s ease-in-out;
`;

export const Spacer = styled.div`
    flex: 1;
    @media (max-width: ${dimensions.mobileBreakpoint}) {
        display: none;
    }
`;

export const TextRow = styled(Flex)`
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    justify-content: center;
`;

export const Label = styled.label<{ column?: boolean; noWrap?: boolean }>`
    font-size: 1em;
    padding-right: 10px;
    align-items: center;
    font-weight: normal;
    justify-content: ${({ column }) => (column ? 'flex-start' : 'center')};
    display: flex;
    white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};

    margin-bottom: -20px;
    margin-top: 20px;

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        margin-bottom: 6px;
    }
`;

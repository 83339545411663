import { BookingListItem } from '../../screens/bookings/bookings.styles';
import { Bzzt } from '../../types';
import { Item, MobileHeader } from './booking-item.styles';

type BookingItemProps = {
    booking: Bzzt.Booking;
    onClick: () => void;
};

export const BookingItem: React.FC<BookingItemProps> = ({ booking, onClick }) => {
    return (
        <BookingListItem onClick={onClick}>
            <Item>
                <MobileHeader>Orderid/Referens:</MobileHeader>
                {booking.externalOrderId}
            </Item>
            <Item>
                <MobileHeader>Status:</MobileHeader>
                {booking.state_translated || booking.state}
            </Item>
            <Item>
                <MobileHeader>Hämtar:</MobileHeader>
                {new Date(booking.earliestPickupTime || 0).toLocaleTimeString([], {
                    timeStyle: 'short',
                })}{' '}
                -{' '}
                {new Date(booking.latestPickupTime || 0).toLocaleTimeString([], {
                    timeStyle: 'short',
                })}
            </Item>
            <Item>
                <MobileHeader>Lämnar:</MobileHeader>
                {`${new Date(booking.earliestDropoffTime || 0).toLocaleTimeString([], {
                    timeStyle: 'short',
                })}
                -
                ${new Date(booking.latestDropoffTime || 0).toLocaleTimeString([], {
                    timeStyle: 'short',
                })}`}
            </Item>
            <Item>
                <MobileHeader>Till:</MobileHeader>
                {booking.dropoff.name}
            </Item>
            <Item>
                <MobileHeader>Datum:</MobileHeader>
                {new Date(booking.pickupAt).toLocaleDateString()}
            </Item>
        </BookingListItem>
    );
};

import { BookingFlowStep } from './types/bzzt';

export const SessionStorageKeys = {
    authToken: 'bzzt-t',
    bookingCandidateCopy: 'bookingCandidateCopy',
} as const;

export const POLICY_TEXT = `
Sammanfattning av generella villkor för användning av Crosstowns budtjänst:

• Endast företag får beställa tjänster enligt dessa villkor.
• Vi ansvarar för godsets säkerhet under transporten. Eventuella skador ersätts upp till ett värde av 500 kr.
• Godset ska vara rätt förpackat för att minimera risken för skador under transporten.
• Kunden ansvarar för att leveransinformation och annan data är korrekt. Vi använder denna information för utförande av transporten och statistikändamål.
• Vi strävar efter att leverera gods i tid enligt överenskommelse/beställning och kan inte hållas ansvariga för förseningar som följd av oförutsedda omständigheter
• Betalning sker enligt beställning och genom faktura. Priset bestäms av vald transportprodukt och överenskommen prislista.
• Betalningstid är 20 dagar och fakturering sker var 14:e dag med en fakturaavgift på 29 kr.
• Personuppgiftsbehandling regleras av vår personuppgiftspolicy som finns på vår hemsida.
• Särskilda önskemål eller alternativa villkor kan diskuteras genom att kontakta oss.

För eventuella frågor eller diskussion om alternativa villkor, vänligen kontakta oss. ` as const;

export const ERROR_MESSAGES = {
    address_must_contain_number:
        'Adressen du angivit saknar gatunummer. Du kan välja att lägga till eller fortsätta utan.',
    in_the_past: 'Prova att boka ett senare klockslag eller dag.',
    during_peak_hours:
        'Sorry, det går inte att boka den tiden. (Det är rusningstid och vi är fullbokade.)',
    not_opening_hours: 'Vi har stängt när du vill boka bud.',
    too_far_in_advance: 'Sorry men du kan inte boka så långt fram i tiden.',
    fully_booked: 'Attans, vi har fullbokat just då. Testa en annan tid.',
    too_soon:
        'Det ser ut som du vill boka bud typ nu på direkten. Vi är dessvärre upptagna nu, men testa att boka en senare tid.  ',
    out_of_zone:
        'Platsen du har valt ligger tyvärr inte i vår leveranszon. Ändra och gör ett nytt försök.',
    default: 'Något gick fel, försök med en annan tid.',
    validation_failed: 'Valideringen misslyckades, försök igen.',
    earliest_pickup_must_be_before_latest_pickup:
        'Tidigaste upphämtning måste vara före senaste upphämtning.',
    earliest_dropoff_must_be_before_latest_dropoff:
        'Tidigaste avlämning måste vara före senaste avlämning.',
    not_available: 'Tyvärr, tidsfönstret du valde är inte tillgängligt. Försök med en annan.',
    NOT_ALLOWED: 'Tyvärr kan du inte boka bud för tillfället. Kontakta oss för mer information.',
    temp_closed: 'Det är tillfälligt stängt när du försöker boka.',
};

export const WARNING_MESSAGES: Record<'address' | 'out_of_zone', { header: string; text: string }> =
    {
        address: {
            header: 'Obs!',
            text: ERROR_MESSAGES.address_must_contain_number,
        },
        out_of_zone: {
            header: 'Inte i vår leveranszon',
            text: ERROR_MESSAGES.out_of_zone,
        },
    };

export const BookingFlowStepOrder: Record<BookingFlowStep, number> = {
    [BookingFlowStep.Delivery]: 1,
    [BookingFlowStep.DeliveryPickup]: 2,
    [BookingFlowStep.DeliveryDropoff]: 3,
    [BookingFlowStep.DeliveryTime]: 4,
    [BookingFlowStep.DeliveryOverview]: 5,
    [BookingFlowStep.DeliveryConfirmation]: 6,
};

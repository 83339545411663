export const colors = {
    // ---- Crosstown color Palette After 2024-10-something ----
    darkIndicator: '#161F2A',
    black: '#1C0F10',
    yellow: '#ED8027',
    white: '#ffffff',
    nearlywhite: '#dddddd',
    lightyellow: '#F8AA6B',
    lipstickred: '#8d1f30',
    orangishred: '#cc523f',
    forestgreen: '#567e4e',
    lightvoilet: '#898dc2',
    darkvoilet: '#2e2f60ff',
    purple: '#2e2f60',
    barneypurple: '#743d8b',
    lightbarneypurple: '#a979ae',
    pink: '#dc6d7e',
    grey: '#767676',
};

import styled from 'styled-components';

export const Root = styled.div``;

export const Text = styled.p`
    font-size: 0.8rem;
    margin: 0;
    text-align: left;
    font-weight: 300;
`;

export const Header = styled.h3`
    font-size: 1rem;
    margin: 1rem 0;
    text-align: left;
    font-family: sofia-pro, sans-serif;
    font-weight: 500;
`;

import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 80px - 29px);
    overflow: hidden;
`;

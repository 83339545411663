import { useEffect, useState } from 'react';
import { useHistory, RouteComponentProps, useLocation } from 'react-router-dom';
import { Text } from '../../app.styles';
import { BackButton } from '../../fragments/back-button';
import { Button } from '../../fragments/button';
import { ButtonRow } from '../../fragments/button-row';
import { Checkbox } from '../../fragments/checkbox';
import { Icon } from '../../fragments/icon';
import { InfoPopup } from '../../fragments/info-popup';
import { ProgressIndicator } from '../../fragments/progress-indicator';
import { Search } from '../../fragments/search';
import { TextInput } from '../../fragments/text-input';
import { Title } from '../../fragments/title';
import { Screen } from '../../structure/screen';
import { HoverInfo } from '../../fragments/hover-info';
import { Root, SelectedContainer } from './pickup.styles';
import { Bzzt } from '../../types';
import { checkInZone } from '../../utils/booking-action';
import { WARNING_MESSAGES, ERROR_MESSAGES } from '../../constants';
import { bookingFlowAccessControl } from '../../utils/booking-action-utils';
import { BookingFlowStep } from '../../types/bzzt';
import { containsNumber } from '../../utils';
import { useBookingStore } from '../../stores/useBookingStore';
import restApi from '../../utils/simple-api/rest-api';

export enum RouteCheckState {
    NotChecked,
    Ok,
    OutOfZone,
}

interface RouteProps extends RouteComponentProps<{}>, React.Props<{}> {}

export const Pickup = ({}: RouteProps) => {
    const history = useHistory();
    const location = useLocation();
    const bookingStore = useBookingStore();

    const [shouldSaveContact, setShouldSaveContact] = useState(false);
    const [routeState, setRouteState] = useState<RouteCheckState>(RouteCheckState.NotChecked);
    const [showOneTimeWarning, setShowOneTimeWarning] = useState<boolean>(false);
    const [warning, setWarning] = useState<{
        header: string | null;
        text: string | null;
    }>({
        header: null,
        text: null,
    });
    const [, setWarningModalVisible] = useState(false);

    const [formState, setFormState] = useState<{
        pickupName: string;
        pickupPhoneNumber: string;
        pickupInstructions: string;
        pickupAddress: string;
        pickupLocation: Bzzt.Location | null;
    }>({
        pickupName: bookingStore.bookingCandidate.pickup?.name ?? '',
        pickupPhoneNumber: bookingStore.bookingCandidate.pickup?.phoneNumber ?? '',
        pickupInstructions: bookingStore.bookingCandidate.pickup?.instructions ?? '',
        pickupLocation: bookingStore.bookingCandidate.pickup?.location ?? null,
        pickupAddress: bookingStore.bookingCandidate.pickup?.address ?? '',
    });

    useEffect(() => {
        if (!bookingStore.initialized) return;

        const redirectTo = bookingFlowAccessControl(location, bookingStore.bookingCandidate);
        if (redirectTo) {
            history.replace(redirectTo);
        }
    }, [location, history, bookingStore.initialized, bookingStore.bookingCandidate]);

    useEffect(() => {
        const formStateIsEmpty =
            formState.pickupName === '' &&
            formState.pickupPhoneNumber === '' &&
            formState.pickupInstructions === '' &&
            formState.pickupAddress === '' &&
            formState.pickupLocation === null;

        // If the formState is already populated, don't do anything
        if (!formStateIsEmpty) {
            return;
        }

        // If the booking context has a pickup address, populate the form state from context state
        if (bookingStore.bookingCandidate.pickup !== null && formStateIsEmpty) {
            setFormState({
                ...formState,
                pickupName: bookingStore.bookingCandidate.pickup?.name ?? '',
                pickupPhoneNumber: bookingStore.bookingCandidate.pickup?.phoneNumber ?? '',
                pickupInstructions: bookingStore.bookingCandidate.pickup?.instructions ?? '',
                pickupAddress: bookingStore.bookingCandidate.pickup?.address ?? '',
                pickupLocation: bookingStore.bookingCandidate.pickup?.location ?? null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingStore.bookingCandidate.pickup]);

    useEffect(() => {
        if (!formState.pickupLocation) {
            return setRouteState(RouteCheckState.NotChecked);
        }

        const runCheckInZone = async (from: Bzzt.Location, to: Bzzt.Location) => {
            const inZone = await checkInZone(from, to);
            if (!inZone) {
                setRouteState(RouteCheckState.OutOfZone);
                setWarning(WARNING_MESSAGES.out_of_zone);
                setShowOneTimeWarning(true);
            } else {
                if (formState.pickupAddress && !containsNumber(formState.pickupAddress)) {
                    setRouteState(RouteCheckState.Ok);
                    setWarning(WARNING_MESSAGES.address);
                    setShowOneTimeWarning(true);
                } else {
                    setWarning({ header: null, text: null });
                    setShowOneTimeWarning(false);
                    setRouteState(RouteCheckState.Ok);
                }
            }
        };
        runCheckInZone(formState.pickupLocation, formState.pickupLocation);
    }, [formState.pickupLocation, formState.pickupAddress]);

    const handleOneTimeWarningClosed = () => {
        if (containsNumber(formState.pickupAddress)) {
            if (routeState === RouteCheckState.OutOfZone) {
                setShowOneTimeWarning(false);
                setWarning(WARNING_MESSAGES.out_of_zone);
            } else {
                setShowOneTimeWarning(false);
                setWarning({ header: null, text: null });
            }
        } else {
            if (routeState === RouteCheckState.OutOfZone) {
                setShowOneTimeWarning(false);
                setWarning(WARNING_MESSAGES.out_of_zone);
            } else {
                setShowOneTimeWarning(false);
                setWarning(WARNING_MESSAGES.address);
            }
        }
    };

    const handleSearchSelect = (type: 'contact' | 'place', item: Bzzt.Contact | object) => {
        if (type === 'contact') {
            const contact = item as Bzzt.Contact;
            setFormState({
                ...formState,
                pickupName: contact.name,
                pickupPhoneNumber: contact.phoneNumber,
                pickupInstructions: contact.instructions,
                pickupAddress: contact.address,
                pickupLocation: contact.location ?? null,
            });
        } else {
            // NOTE(charlieroth): This is a hack, due to a lack of available types
            // from the npm package we use, this is a best guess at the shape of
            // the type
            const pickupLocation = item as {
                address: string;
                lat: () => number;
                lng: () => number;
            };

            setFormState({
                ...formState,
                pickupAddress: pickupLocation.address,
                pickupLocation: {
                    latitude: pickupLocation.lat(),
                    longitude: pickupLocation.lng(),
                },
            });

            if (!containsNumber(pickupLocation.address)) {
                setWarning(WARNING_MESSAGES.address);
                setShowOneTimeWarning(true);
            }
        }
    };

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFormState({ ...formState, pickupName: e.currentTarget.value });
    };

    const handlePhoneNumberChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFormState({ ...formState, pickupPhoneNumber: e.currentTarget.value });
    };

    const handleInstructionsChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFormState({ ...formState, pickupInstructions: e.currentTarget.value });
    };

    const showHoverWarning = () => {
        return warning.header !== null && warning.text !== null && showOneTimeWarning === false;
    };

    const formInputsDisabled = () => {
        return routeState !== RouteCheckState.Ok || !formState.pickupAddress;
    };

    const canContinue = () => {
        return (
            formState.pickupPhoneNumber !== '' &&
            formState.pickupName !== '' &&
            formState.pickupAddress !== '' &&
            formState.pickupInstructions !== '' &&
            formState.pickupLocation !== null &&
            routeState === RouteCheckState.Ok
        );
    };

    const handleBackClicked = () => {
        history.push(BookingFlowStep.Delivery);
    };

    const handleNextClicked = () => {
        // TODO(charlieroth): Show validation errors in the UI
        if (!canContinue()) return;

        if (shouldSaveContact) {
            restApi.createContact({
                name: formState.pickupName,
                phoneNumber: formState.pickupPhoneNumber,
                instructions: formState.pickupInstructions,
                address: formState.pickupAddress,
                location: formState.pickupLocation ?? undefined,
            });
        }

        bookingStore.completeStepTwo({
            pickup: {
                name: formState.pickupName,
                phoneNumber: formState.pickupPhoneNumber,
                instructions: formState.pickupInstructions,
                address: formState.pickupAddress,
                location: formState.pickupLocation ?? undefined,
            },
        });

        history.push(BookingFlowStep.DeliveryDropoff);
    };

    return (
        <Screen
            topContent={<Title marginTop={'1rem'}>Var ska vi hämta?</Title>}
            content={
                <Root>
                    <Search
                        placeholder="Sök kontakt eller plats"
                        name="search"
                        onSelect={handleSearchSelect}
                    />

                    <SelectedContainer>
                        <Icon type="marker" size="xs" />
                        <Text disabled>{formState.pickupAddress || 'Sök och välj en address'}</Text>
                        {showHoverWarning() ? (
                            <HoverInfo
                                header={warning.header || 'Obs!'}
                                inline
                                icon="!"
                                text={warning.text || ERROR_MESSAGES.default}
                                onMouseOver={() => setWarningModalVisible(true)}
                                onMouseLeave={() => setWarningModalVisible(false)}
                            />
                        ) : null}
                    </SelectedContainer>

                    <TextInput
                        placeholder="Namn/Företag"
                        value={formState.pickupName}
                        onChange={handleNameChange}
                        disabled={formInputsDisabled()}
                    />
                    <TextInput
                        placeholder="Telefonnummer"
                        value={formState.pickupPhoneNumber}
                        onChange={handlePhoneNumberChange}
                        disabled={formInputsDisabled()}
                    />
                    <TextInput
                        placeholder="Upphämtningsinstruktioner"
                        value={formState.pickupInstructions}
                        onChange={handleInstructionsChange}
                        disabled={formInputsDisabled()}
                    />
                    <Checkbox
                        reverse
                        label="Spara kontakt"
                        onChange={(e) => setShouldSaveContact(e.target.checked)}
                        checked={shouldSaveContact}
                    />

                    {showOneTimeWarning && (
                        <InfoPopup
                            header={warning.header || 'Ops!'}
                            text={warning.text || ERROR_MESSAGES.default}
                            closePress={handleOneTimeWarningClosed}
                        />
                    )}
                </Root>
            }
            actionRowContent={
                <ButtonRow>
                    <BackButton onClick={handleBackClicked} />
                    <ProgressIndicator currentStep={2} totalSteps={5} />
                    <Button disabled={!canContinue()} onClick={handleNextClicked}>
                        Nästa
                    </Button>
                </ButtonRow>
            }
        />
    );
};

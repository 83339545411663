import styled from 'styled-components';
import { colors } from '../../colors';

export const Wrapper = styled.div<{ icon?: boolean }>`
    border: ${({ icon }) => (icon ? `3px solid ${colors.black}` : '0')};
    display: flex;
    max-height: 2.5rem;
    width: 100%;
`;

export const Root = styled.input<{ noMargin?: boolean; rounded?: boolean; icon?: boolean }>`
    border: ${({ icon }) => (icon ? '0' : `3px solid ${colors.black}`)};
    line-height: 2.2rem;
    height: 2.2rem;
    width: 100%;
    margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '20px')};
    font-size: 0.65rem;
    padding: 0 10px;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:disabled {
        background-color: transparent;
        border-color: ${colors.grey};
    }

    &:focus {
        outline: none;
        border-color: ${colors.yellow};
    }

    ::placeholder {
        color: ${colors.grey};
    }
`;

export const IconWrapper = styled.div`
    padding: 15px;
`;

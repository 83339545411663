import { CircleChecked, Svg } from '../icon.styles';

export const Box = (props: any) => {
    const { checked } = props;

    return (
        <Svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 554 554">
            {checked && (
                <CircleChecked d="M244 1.1C149.7 12.5 70.3 67.3 28.7 149.7 8.8 189.1 0 226.7 0 272.5s8.8 83.4 28.7 122.8c42.1 83.4 123.4 138.9 218.1 148.8 15 1.5 57.3.6 70.7-1.5 50.3-8.2 92-26 132-56.5 11.9-9 36.4-33.2 45.9-45.1 29.2-37 47.5-78.1 55.8-125.6 2-11.4 2.3-16.3 2.3-42.9 0-26.6-.3-31.5-2.3-42.9-5.4-30.8-13.3-54.6-27.3-81.6-13.3-25.6-27.7-44.9-49.8-67C431.2 38.4 380 12.7 317.5 2.4 305.8.5 256.1-.4 244 1.1z" />
            )}
            <path d="M244 1.1C149.7 12.5 70.3 67.3 28.7 149.7 8.8 189.1 0 226.7 0 272.5s8.8 83.4 28.7 122.8c42.1 83.4 123.4 138.9 218.1 148.8 15 1.5 57.3.6 70.7-1.5 50.3-8.2 92-26 132-56.5 11.9-9 36.4-33.2 45.9-45.1 29.2-37 47.5-78.1 55.8-125.6 2-11.4 2.3-16.3 2.3-42.9 0-26.6-.3-31.5-2.3-42.9-5.4-30.8-13.3-54.6-27.3-81.6-13.3-25.6-27.7-44.9-49.8-67C431.2 38.4 380 12.7 317.5 2.4 305.8.5 256.1-.4 244 1.1zm83 20.3C387.2 31.7 435.9 59 472.7 103c50.8 60.8 70.3 149.3 50.2 227.7-13 51-46.9 101.7-90.4 135.6-68.4 53.3-159.6 71.5-236.3 47.2-94.7-30-161.8-114.1-175.3-220-2-15.2-1.7-50.5.5-64.5 9.2-59.5 39-110.1 87.8-149.1 39.7-31.7 90.3-52.6 144.1-59.4 15.3-1.9 60.4-1.4 73.7.9z" />
            <path d="M201.3 125.8 114 167.5l.3 107.9.2 107.9 87 39.9 87 39.9 4-1.9c6.6-3.2 160.9-88.2 161.8-89.1.9-.9-.9-186.7-1.9-200.6l-.5-7.1-80.5-40.2c-44.2-22.1-81-40.2-81.7-40.1-.6 0-40.5 18.8-88.4 41.7zm104.1-19.2 15.2 6.6-28.1 14.3c-15.4 7.9-48 24.5-72.4 36.9l-44.4 22.7-18.9-8.4c-10.4-4.6-18.9-8.8-18.9-9.2 0-1.2 149.4-70.4 151-69.9.7.2 8.1 3.4 16.5 7zm97.9 42.8c18 7.9 32.8 14.7 32.9 15.2.1 1-144.9 72.3-147.1 72.4-2 0-63.1-27.3-62.5-27.9 1-1 141.9-74 142.9-74.1.6 0 15.8 6.5 33.8 14.4zm-251.7 42.9 21.9 10.3-.3 27.5c-.1 15.1-.5 40.4-.8 56.4l-.6 29 23.2 9.4c12.7 5.1 23.4 9 23.8 8.7.3-.4.8-25 1-54.7.4-43.7.7-53.9 1.8-53.7.7.2 14.8 6.6 31.3 14.3l30.1 13.9V447l-4.7-2.4c-2.7-1.3-37-17.5-76.3-36.1s-71.6-33.9-71.7-33.9c-.1-.1-.7-36.6-1.3-81.2-.6-44.5-1.1-87.8-1.3-96.2-.1-10.4.1-15.2.9-15.2.6 0 10.9 4.6 23 10.3zm287.2 82.6c-.8 61.3-1.5 92.7-2.2 93.6-.6.7-32 18.3-69.7 39.2l-68.6 37.9-.6-24.5c-.4-13.5-.7-56.8-.7-96.1v-71.5l71.3-35.6c39.1-19.6 71.3-35.6 71.4-35.5.1.1-.3 41.7-.9 92.5z" />
        </Svg>
    );
};

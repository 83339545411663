import styled from 'styled-components';
import { colors } from '../../colors';

export const Root = styled.div`
    position: relative;
    font-family: sofia-pro, sans-serif !important;
`;

export const Context = styled.div`
    position: absolute;
    top: calc(100% - 10px);
    left: 0px;
    width: 100%;
    z-index: 10;
    // border-top: 3px solid ${colors.black};
`;

export const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    // & > *:last-child {
    //     margin-left: 15px;
    // }
`;

export const ContextItem = styled.div`
    display: flex;
    flex-direction: rows;
    gap: 8px;
    flex: 1 1;
    border: 3px solid ${colors.black};
    border-bottom: none;
    background: #fff;
    box-sizing: border-box;
    padding: 12px;

    cursor: pointer;

    &:last-child {
        border-bottom: 3px solid ${colors.black};
        border-radius: 0 0 12px 12px;
    }
`;

export const ContextHeaderItem = styled(ContextItem)`
    background-color: ${colors.lightyellow};
    font-weight: bold;
    cursor: default;
    pointer-events: none;
`;

export const ContextDescription = styled.div`
    &,
    & p {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
    }
`;

export const Text = styled.p`
    font-size: 0.6rem;
    font-weight: normal;
    margin: 0;
`;

import { Button, Input, Root } from './number-input.styles';

type NumberInputProps = {
    value: number;
    min?: number;
    max?: number;
    noBorder?: boolean;
    width?: number;
    height?: number;
    onIncrement: () => void;
    onDecrement: () => void;
};

export function NumberInput({
    min,
    max,
    value,
    noBorder,
    onDecrement,
    onIncrement,
}: NumberInputProps) {
    const handleDecrementClicked = () => {
        const currentValue = value;
        if (min && currentValue - 1 < min) {
            return;
        }
        onDecrement();
    };

    const handleIncrementClicked = () => {
        const currentValue = value;
        if (max && currentValue + 1 > max) {
            return;
        }

        onIncrement();
    };

    return (
        <Root>
            <Button onClick={handleDecrementClicked}>-</Button>
            <Input type="number" value={value} onChange={() => {}} noMargin noBorder={noBorder} />
            <Button onClick={handleIncrementClicked}>+</Button>
        </Root>
    );
}

import { ContactListItem } from '../../screens/contacts/contacts.styles';
import { Bzzt } from '../../types';
import { Item, MobileHeader } from './contact-item.styles';

type ContactItemProps = {
    contact: Bzzt.Contact;
    onClick: () => void;
};

export const ContactItem: React.FC<ContactItemProps> = ({ contact, onClick }) => {
    return (
        <ContactListItem onClick={onClick}>
            <Item>
                <MobileHeader>Namn/företag:</MobileHeader>
                {contact.name}
            </Item>
            <Item>
                <MobileHeader>Telefonnummer:</MobileHeader>
                {contact.phoneNumber}
            </Item>
            <Item>
                <MobileHeader>Adress:</MobileHeader>
                {contact.address}
            </Item>
            <Item>
                <MobileHeader>Instruktioner:</MobileHeader>
                {contact.instructions}
            </Item>
        </ContactListItem>
    );
};

import React, { useState, useEffect } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import {
    Root,
    Context,
    ContextItem,
    ContextDescription,
    Text,
    ContextHeaderItem,
    SearchWrapper,
} from './search.styles';

import { useParams } from 'react-router-dom';
import GooglePlacesLogo from './powered_by_google_on_white.png';
import { TextInput } from '../text-input';
import { Bzzt } from '../../types';
import restApi from '../../utils/simple-api/rest-api';

export function Search({
    name,
    disabled,
    placeholder = '',
    onSelect = () => {},
    hideContacts = false,
}: {
    name: string;
    disabled?: boolean;
    placeholder?: string;
    onSelect?: (type: 'contact' | 'place', item: Bzzt.Contact | object) => void;
    hideContacts?: boolean;
}) {
    const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } =
        useGoogle({
            apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        });

    const [contacts, setContacts] = useState<Bzzt.Contact[]>([]);
    const params = useParams();

    useEffect(() => {
        const fetchContacts = async () => {
            const { data } = await restApi.getContacts();
            if (data) {
                setContacts(data);
            }
        };
        fetchContacts();
    }, [params]);

    const [contactPredictions, setContactPredictions] = useState<Bzzt.Contact[]>([]);

    const [open, setOpen] = useState(false);
    const [localValue, setLocalValue] = useState('');

    const onSuggestChange = (e: any) => {
        const newValue = e.target.value;
        getPlacePredictions({ input: newValue });

        if (!hideContacts) {
            const newContactPredictions = contacts.filter((contact) => {
                return contact.name.toLowerCase().includes(newValue.toLowerCase());
            });
            setContactPredictions(newContactPredictions);
        }

        setOpen(newValue !== '');
        setLocalValue(newValue);
    };

    const onPlaceSelected = async (item: any) => {
        placesService?.getDetails({ placeId: item.place_id }, (details: any) => {
            setOpen(false);
            onSelect('place', {
                ...details.geometry.location,
                address: details.formatted_address.replace(', Sverige', ''),
            });
            setLocalValue(item.description);
        });
    };

    const onContactSelected = (item: Bzzt.Contact) => {
        const newValue = item.name;
        setOpen(false);
        onSelect('contact', item);
        setLocalValue(newValue);
    };

    const onBlur = () => setTimeout(() => setOpen(false), 250);

    // const validPredictions = placePredictions?.filter((p) => /.*[0-9].*/.test(p.description));

    return (
        <Root>
            <SearchWrapper>
                <TextInput
                    name={name}
                    value={localValue}
                    onChange={onSuggestChange}
                    disabled={disabled}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    icon="search"
                    autoFocus
                />
            </SearchWrapper>

            {!isPlacePredictionsLoading && open && (
                <Context>
                    {contactPredictions.length > 0 && (
                        <ContextHeaderItem>
                            <ContextDescription>
                                <Text>Kontakter</Text>
                            </ContextDescription>
                        </ContextHeaderItem>
                    )}
                    {contactPredictions.map((item: Bzzt.Contact) => {
                        return (
                            <ContextItem
                                key={item.id || item.name}
                                onClick={() => onContactSelected(item)}
                                aria-hidden="true"
                            >
                                <ContextDescription>
                                    <Text>{item.name}</Text>
                                </ContextDescription>
                            </ContextItem>
                        );
                    })}
                    {placePredictions.length > 0 && (
                        <ContextHeaderItem>
                            <ContextDescription>
                                <Text>Platssökning</Text>
                            </ContextDescription>
                        </ContextHeaderItem>
                    )}
                    {placePredictions.map((item: any) => {
                        return (
                            <ContextItem
                                key={item.place_id}
                                onClick={() => onPlaceSelected(item)}
                                aria-hidden="true"
                            >
                                <ContextDescription>
                                    <Text>{item.description}</Text>
                                </ContextDescription>
                            </ContextItem>
                        );
                    })}
                    <ContextItem>
                        <img src={GooglePlacesLogo} />
                    </ContextItem>
                </Context>
            )}
        </Root>
    );
}

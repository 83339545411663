import styled from 'styled-components';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';
import { HoverInfoPopup } from '../hover-info/hover-info.styles';

export const Root = styled.button<{ isActivated: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid white;
    background-color: ${({ isActivated }) => (isActivated ? colors.black : colors.yellow)};
    min-height: auto;
    min-width: 50px;
    font-size: 30px;
    font-family: 'Russo One';
    border-radius: 50%;
    font-weight: normal;
    cursor: pointer;
    color: ${colors.black};

    :hover,
    :focus {
        background-color: ${colors.black};
        color: white;
    }

    :hover + ${HoverInfoPopup} {
        opacity: 1;
    }

    :focus ~ ${HoverInfoPopup} {
        opacity: 1;
    }

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        min-height: 40px;
        min-width: 40px;
        border-radius: 40px;
        font-size: 20px;
        padding: 0;
    }
`;

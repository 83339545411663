import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { Flex, Text } from '../../app.styles';
import { Button } from '../../fragments/button';
import { ButtonRow } from '../../fragments/button-row';
import { Icon } from '../../fragments/icon';
import { InfoButton } from '../../fragments/info-button';
import { InfoPopup } from '../../fragments/info-popup';
import { NumberInput } from '../../fragments/number-input';
import { RadioButton } from '../../fragments/radio-button';
import { TextArea } from '../../fragments/text-area';
import { TextInput } from '../../fragments/text-input';
import { Title } from '../../fragments/title';
import { HoverInfo } from '../../fragments/hover-info';
import { Screen } from '../../structure/screen';
import {
    Container,
    ItemWrapper,
    Label,
    RadioWrapper,
    Root,
    SizeBar,
    SizeBarWrapper,
    Spacer,
    TextRow,
} from './delivery.styles';
import {
    bookingFlowAccessControl,
    getItemInfoDetails,
    getItemInfoHeader,
} from '../../utils/booking-action-utils';
import { POLICY_TEXT } from '../../constants';
import { Bzzt } from '../../types';
import { BookingFlowStep } from '../../types/bzzt';
import { useBookingStore } from '../../stores/useBookingStore';

interface RouteProps extends RouteComponentProps<{}>, React.Props<{}> {}

type FormItem = {
    type: Bzzt.ItemType;
    size: Bzzt.ItemSize;
    count: number;
};

export const Delivery: React.FC<RouteProps> = () => {
    const bookingStore = useBookingStore();
    const history = useHistory();
    const location = useLocation();
    const [policyModalVisible, setPolicyModalVisibility] = useState(false);
    const [, setItemTypeInfoModalVisibility] = useState(false);

    const [formState, setFormState] = useState<{
        selectedItemType: Bzzt.ItemType | null;
        items: FormItem[];
        description: string;
        customerReference: string;
    }>({
        selectedItemType:
            bookingStore.bookingCandidate.items.length > 0
                ? bookingStore.bookingCandidate.items[0].type
                : null,
        items: bookingStore.bookingCandidate.items,
        description: bookingStore.bookingCandidate.description ?? '',
        customerReference: bookingStore.bookingCandidate.customerReference ?? '',
    });

    useEffect(() => {
        if (!bookingStore.initialized) return;

        const redirectTo = bookingFlowAccessControl(location, bookingStore.bookingCandidate);
        if (redirectTo) {
            history.replace(redirectTo);
        }
    }, [location, history, bookingStore]);

    useEffect(() => {
        if (formState.selectedItemType === null && bookingStore.bookingCandidate.items.length > 0) {
            setFormState({
                ...formState,
                selectedItemType: bookingStore.bookingCandidate.items[0].type,
                items: bookingStore.bookingCandidate.items,
                description: bookingStore.bookingCandidate.description ?? '',
                customerReference: bookingStore.bookingCandidate.customerReference ?? '',
            });
        }
    }, [bookingStore.bookingCandidate, formState]);

    const selectedItemTypeSizes = useMemo(() => {
        const currentSelectedItemType = formState.selectedItemType;
        let sizes: Bzzt.ItemSize[] = [];
        for (const item of bookingStore.bookingItems) {
            if (item.type === currentSelectedItemType) {
                sizes = item.sizes;
                break;
            }
        }

        return sizes;
    }, [formState.selectedItemType, bookingStore.bookingItems]);

    const selectedItemTypeInfo = useMemo(() => {
        if (!formState.selectedItemType) {
            return null;
        }

        const currentSelectedItemType = formState.selectedItemType;
        const bookingItem = bookingStore.bookingItems.find(
            (item: Bzzt.BookingItem) => item.type === currentSelectedItemType
        );
        if (bookingItem === undefined) {
            return null;
        }

        return {
            header: getItemInfoHeader(bookingItem),
            info: getItemInfoDetails(bookingItem, bookingStore.apiItemTypes),
        };
    }, [formState.selectedItemType, bookingStore.bookingItems, bookingStore.apiItemTypes]);

    const selectItemType = (itemType: Bzzt.ItemType) => {
        setFormState({
            ...formState,
            selectedItemType: itemType,
        });
    };

    const handleIncrementClicked = (itemType: Bzzt.ItemType, itemSize: Bzzt.ItemSize) => {
        const alreadyHasItem = formState.items.some(
            (item: FormItem) => item.type == itemType && item.size == itemSize
        );

        if (alreadyHasItem) {
            const newItems = formState.items.map((item: FormItem) => {
                if (item.type == itemType && item.size == itemSize) {
                    return { ...item, count: item.count + 1 };
                }
                return item;
            });
            return setFormState({ ...formState, items: newItems });
        }

        const newItem: FormItem = { type: itemType, size: itemSize, count: 1 };
        const newItems = [newItem, ...formState.items];
        setFormState({ ...formState, items: newItems });
    };

    const handleDecrementClicked = (itemType: Bzzt.ItemType, itemSize: Bzzt.ItemSize) => {
        let itemCount = 0;
        for (const item of formState.items) {
            if (item.type == itemType && item.size == itemSize) {
                itemCount = item.count;
                break;
            }
        }

        // NOTE(charlieroth): itemCount should never be 0 but if it is something
        // has gone wrong and this should be a no-op. Could possibly log an error
        // if/when a error logging service is put in place?
        if (itemCount == 0) {
            return;
        } else if (itemCount == 1) {
            const newItems = formState.items.filter(
                (item: FormItem) => !(item.type == itemType && item.size == itemSize)
            );
            return setFormState({ ...formState, items: newItems });
        }

        const newItems = formState.items.map((item: FormItem) => {
            if (item.type == itemType && item.size == itemSize) {
                return { ...item, count: item.count - 1 };
            }
            return item;
        });
        setFormState({ ...formState, items: newItems });
    };

    const updateDescription = (description: string) => {
        setFormState({ ...formState, description });
    };

    const updateCustomerReference = (customerReference: string) => {
        setFormState({ ...formState, customerReference });
    };

    const canContinue = () => {
        return (
            formState.items.length > 0 &&
            formState.description !== '' &&
            formState.customerReference !== ''
        );
    };

    const handleNextClicked = () => {
        if (!canContinue()) return;

        bookingStore.completeStepOne({
            items: formState.items,
            description: formState.description,
            customerReference: formState.customerReference,
        });

        history.push(BookingFlowStep.DeliveryPickup);
    };

    const togglePolicyInfoModal = () => {
        setPolicyModalVisibility((currentVisibility: boolean) => !currentVisibility);
    };

    return (
        <Screen
            topContent={
                <Title marginTop={'1rem'} noPaddingBottom={true}>
                    Vad ska vi hämta?
                </Title>
            }
            content={
                <Root>
                    <ItemWrapper>
                        {bookingStore.bookingItems.map((bookingItem) => {
                            const header = getItemInfoHeader(bookingItem);
                            return (
                                <RadioWrapper key={bookingItem.type}>
                                    <Label>{header}</Label>
                                    <RadioButton
                                        icon={bookingItem.type}
                                        id={bookingItem.type}
                                        name="item-type"
                                        type="radio"
                                        value={bookingItem.type}
                                        checked={formState.selectedItemType === bookingItem.type}
                                        onChange={() => selectItemType(bookingItem.type)}
                                    />
                                </RadioWrapper>
                            );
                        })}
                    </ItemWrapper>
                    <SizeBarWrapper show={!!formState.selectedItemType}>
                        <SizeBar show={!!formState.selectedItemType}>
                            {selectedItemTypeSizes.map((itemSize: Bzzt.ItemSize) => {
                                return (
                                    <div
                                        key={`${selectItemType}-${itemSize}`}
                                        style={{
                                            height: '2rem',
                                            display: 'flex',
                                            flex: 0,
                                            position: 'relative',
                                        }}
                                    >
                                        <RadioButton
                                            icon={itemSize}
                                            id={itemSize}
                                            name="package-size"
                                            type="radio"
                                            value={itemSize}
                                            size="s"
                                            onClick={() =>
                                                handleIncrementClicked(
                                                    formState.selectedItemType!,
                                                    itemSize
                                                )
                                            }
                                        />
                                    </div>
                                );
                            })}
                            {formState.selectedItemType && selectedItemTypeInfo && (
                                <HoverInfo
                                    header={selectedItemTypeInfo.header}
                                    text={selectedItemTypeInfo.info}
                                    onMouseOver={() => setItemTypeInfoModalVisibility(true)}
                                    onMouseLeave={() => setItemTypeInfoModalVisibility(false)}
                                />
                            )}
                        </SizeBar>
                    </SizeBarWrapper>
                    <Container show={formState.items.length > 0}>
                        {formState.items.map((item: FormItem) => {
                            const combinedSlug = `${item.type}-${item.size}`;
                            const bookingItem = bookingStore.bookingItems.find(
                                (bi: Bzzt.BookingItem) => bi.type === item.type
                            );
                            const header = bookingItem ? getItemInfoHeader(bookingItem) : '';
                            return (
                                <Flex key={combinedSlug}>
                                    <Flex>
                                        <Text>{header}</Text>
                                        <Icon
                                            style={{ fontFamily: 'Russo One' }}
                                            type={item.size}
                                            size="xs"
                                        />
                                    </Flex>
                                    <NumberInput
                                        min={0}
                                        value={item.count}
                                        onIncrement={() =>
                                            handleIncrementClicked(item.type, item.size)
                                        }
                                        onDecrement={() =>
                                            handleDecrementClicked(item.type, item.size)
                                        }
                                        noBorder
                                    />
                                </Flex>
                            );
                        })}

                        <Flex direction="column">
                            <TextArea
                                placeholder={'Beskrivning'}
                                value={formState.description}
                                onChange={(e: any) => updateDescription(e.target.value)}
                            />
                            <TextInput
                                style={{ marginTop: '0.3rem' }}
                                placeholder="Orderid/Referens"
                                value={formState.customerReference}
                                onChange={(e: any) => updateCustomerReference(e.target.value)}
                            />
                            <TextRow style={{ marginTop: '0.5rem' }}>
                                <Text align="right">Villkor</Text>
                                <InfoButton
                                    isActivated={policyModalVisible}
                                    onClick={togglePolicyInfoModal}
                                >
                                    i
                                </InfoButton>
                            </TextRow>
                        </Flex>
                    </Container>

                    {policyModalVisible && (
                        <InfoPopup
                            header="Villkor för bud."
                            text={POLICY_TEXT}
                            links={[
                                {
                                    text: 'Läs de fullständiga generella villkoren',
                                    link: '/page/villkor',
                                },
                                {
                                    text: 'Läs vår personuppgiftspolicy',
                                    link: '/page/personuppgiftspolicy',
                                },
                            ]}
                            linkText="här"
                            closePress={togglePolicyInfoModal}
                        />
                    )}
                </Root>
            }
            actionRowContent={
                <ButtonRow>
                    <Spacer />
                    <Button disabled={canContinue() === false} onClick={handleNextClicked}>
                        Nästa
                    </Button>
                </ButtonRow>
            }
        />
    );
};

import { Svg } from '../icon.styles';

export const Arrow = (props: any) => {
    const { fill } = props;
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 172 89">
            <path
                fill={fill}
                d="M3.3.6C2 .9 1 1.7 1 2.4c0 4.5 19.7 26.4 46 51.1C73.2 78 84.5 88 86.2 88c7.3-.1 41.1-30.8 63.9-58.2C161.2 16.4 171.7 2 170.3 1.9c-.4 0-30.7-.2-67.3-.4-36.6-.2-73.5-.6-82-.8C12.5.4 4.5.4 3.3.6z"
            />
        </Svg>
    );
};

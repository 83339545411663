import styled from 'styled-components';
import { dimensions } from '../../dimensions';
import { colors } from '../../colors';

export const Root = styled.div`
    display: flex;
    justify-content: center;
`;

export const LoginForm = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 400px;
    @media (max-width: ${dimensions.mobileBreakpoint}) {
        width: 100%;
    }
`;

export const Text = styled.p`
    font-size: 0.56rem;
    font-weight: normal;
    margin-right: auto;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Link = styled.a`
    color: ${colors.black};
    font-size: 0.8em;
    text-align: right;
    margin-top: 0.4em;
`;

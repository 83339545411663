import { Root } from './progress-indicator.styles';

export function ProgressIndicator({
    currentStep,
    totalSteps,
}: {
    currentStep: number;
    totalSteps: number;
}) {
    return (
        <Root>
            {currentStep}/{totalSteps}
        </Root>
    );
}

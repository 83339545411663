import { HoverInfoPopup, HoverInfoRoot } from './hover-info.styles';
import { InfoButton } from '../info-button';
import { InfoPopupComponent } from '../info-popup';

export const HoverInfo = ({
    header,
    text,
    onMouseOver,
    onMouseLeave,
    inline = false,
    icon = '?',
}: {
    header: string;
    text: string;
    onMouseOver: () => void;
    onMouseLeave: () => void;
    inline?: boolean;
    icon?: string;
}) => {
    return (
        <HoverInfoRoot inline={inline}>
            <InfoButton onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
                {icon}
            </InfoButton>
            <HoverInfoPopup>
                <InfoPopupComponent header={header} text={text} />
            </HoverInfoPopup>
        </HoverInfoRoot>
    );
};

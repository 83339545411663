import styled from 'styled-components';
import { dimensions } from '../../dimensions';

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    & > * {
        margin: 0.6rem;
    }
`;

export const Text = styled.div`
    margin-bottom: 50px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    justify-content: space-between;
    align-items: stretch;
    column-gap: 0.875rem;

    & > * {
        margin-bottom: 30px;
    }

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        width: 100%;
    }
`;

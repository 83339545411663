import './time-picker.css';
import { TimePickerOption } from '../../types/bzzt';

interface TimePickerProps {
    label: string;
    selectedValue: string;
    options: TimePickerOption[];
    onChange: (date: Date) => void;
}

export const TimePicker = ({ label, selectedValue, options, onChange }: TimePickerProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const currentValue = e.target.value;
        const date = new Date(currentValue);
        onChange(date);
    };

    return (
        <div className="time-picker-container">
            <label className="time-picker-label">{label}</label>
            <div className="time-picker">
                <select value={selectedValue} onChange={handleChange}>
                    {options.map((option: TimePickerOption) => (
                        <option key={option.label} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

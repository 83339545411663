import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../fragments/button';
import { ButtonRow } from '../../fragments/button-row';
import { ContactItem } from '../../fragments/contact-item';
import { Item } from '../../fragments/contact-item/contact-item.styles';
import { Title } from '../../fragments/title';
import { Screen } from '../../structure/screen';
import { Spacer } from '../delivery/delivery.styles';
import { ContactList, ContactListItem } from './contacts.styles';
import restApi from '../../utils/simple-api/rest-api';
import { Bzzt } from '../../types';

export const Contacts = () => {
    const history = useHistory();
    const params = useParams();
    const [contacts, setContacts] = useState<Bzzt.Contact[] | null>(null);

    useEffect(() => {
        const fetchContacts = async () => {
            const { data } = await restApi.getContacts();
            if (data) {
                setContacts(data);
            }
        };
        fetchContacts();
    }, [params]);

    const onItemPress = (id?: string) => {
        if (id) {
            history.push(`/contacts/${id}/edit`);
        }
    };

    const onAdd = () => {
        history.push('/contacts/add');
    };

    return (
        <Screen
            slim={false}
            topContent={<Title marginTop={'1rem'}>Kontakter.</Title>}
            content={
                <ContactList>
                    <ContactListItem header>
                        <Item>Namn/Företag:</Item>
                        <Item>Telefonnummer:</Item>
                        <Item>Adress:</Item>
                        <Item>Instruktioner:</Item>
                    </ContactListItem>

                    {contacts &&
                        contacts.map((contact) => {
                            const id = contact.id;
                            return (
                                <ContactItem
                                    key={id}
                                    contact={contact}
                                    onClick={() => onItemPress(id)}
                                />
                            );
                        })}
                </ContactList>
            }
            actionRowContent={
                <ButtonRow>
                    <Spacer />
                    <Button onClick={onAdd}>Lägg till!</Button>
                </ButtonRow>
            }
        />
    );
};

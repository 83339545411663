import { useLocation } from 'react-router-dom';
import { capitalize } from '.';
import { BookingFlowStepOrder } from '../constants';
import { Api, Bzzt } from '../types';
import { ERROR_MESSAGES } from '../constants';
import { ItemTypeTranslation } from './index';
import { BookingCandidateItem } from '../types/bzzt';

export const sortBookingItems = (
    bookingItems: Bzzt.BookingItem[],
    itemTypeOrder: Bzzt.ItemType[]
): Bzzt.BookingItem[] => {
    const sortedBookingItems: Bzzt.BookingItem[] = [];
    for (const orderedItemType of itemTypeOrder) {
        const bookingItem = bookingItems.find((item) => item.type === orderedItemType);
        if (bookingItem) {
            sortedBookingItems.push(bookingItem);
        }
    }
    return sortedBookingItems;
};

export const sortItemSizes = (
    itemSizes: Bzzt.ItemSize[],
    itemSizeOrder: Bzzt.ItemSize[]
): Bzzt.ItemSize[] => {
    const sortedItemSizes: Bzzt.ItemSize[] = [];
    for (const orderedItemSize of itemSizeOrder) {
        const size = itemSizes.find((s: Bzzt.ItemSize) => s === orderedItemSize);
        if (size) {
            sortedItemSizes.push(size);
        }
    }
    return sortedItemSizes;
};

export const createBookingItems = (data: Api.GetItemTypesResponseData): Bzzt.BookingItem[] => {
    // Initialize map with empty arrays for each supported item type
    const supportedItemTypes = Object.values(Bzzt.ItemType);
    const itemTypeToItemSizeMap: Map<Bzzt.ItemType, Bzzt.ItemSize[]> = new Map();
    for (const supportedItemType of supportedItemTypes) {
        itemTypeToItemSizeMap.set(supportedItemType, []);
    }

    // Build map of item type to supported sizes
    for (const entry of data) {
        const [entryType, entrySize] = entry.slug.split('-');
        const type = entryType as Bzzt.ItemType;
        const size = entrySize as Bzzt.ItemSize;
        if (itemTypeToItemSizeMap.has(type)) {
            itemTypeToItemSizeMap.get(type)!.push(size);
        }
    }

    // Convert map to array of booking items
    const bookingItems: Bzzt.BookingItem[] = [];
    for (const [type, sizes] of itemTypeToItemSizeMap.entries()) {
        // Skip item types that have no supported sizes
        if (sizes.length === 0) {
            continue;
        }

        const bookingItem: Bzzt.BookingItem = {
            type,
            sizes: sortItemSizes(sizes, [Bzzt.ItemSize.S, Bzzt.ItemSize.M, Bzzt.ItemSize.L]),
        };
        bookingItems.push(bookingItem);
    }

    return bookingItems;
};

export const bookingCandidateItemsToParcelStringArray = (items: BookingCandidateItem[]) => {
    return items.flatMap((item) => {
        if (item.count > 1) {
            const parcels = [];
            for (let i = 0; i < item.count; i++) {
                parcels.push(`${item.type}-${item.size}`);
            }
            return parcels;
        }

        return `${item.type}-${item.size}`;
    });
};

export const getItemInfoDetails = (item: Bzzt.BookingItem, apiItemTypes: Api.Item[]) => {
    const smallSlug = `${item.type}-${Bzzt.ItemSize.S}`;
    const mediumSlug = `${item.type}-${Bzzt.ItemSize.M}`;
    const largeSlug = `${item.type}-${Bzzt.ItemSize.L}`;

    const small = apiItemTypes.find((apiItem) => apiItem.slug === smallSlug);
    const medium = apiItemTypes.find((apiItem) => apiItem.slug === mediumSlug);
    const large = apiItemTypes.find((apiItem) => apiItem.slug === largeSlug);
    return `Max mått: BxDxH (cm)

${
    small
        ? `S:\t${small.length / 10}x${small.width / 10}x${small.height / 10} ${small.weight} kg (${
              small.name
          })`
        : ''
}
${
    medium
        ? `M:\t${medium.length / 10}x${medium.width / 10}x${medium.height / 10} ${
              medium.weight
          } kg (${medium.name})`
        : ''
}
${
    large
        ? `L:\t${large.length / 10}x${large.width / 10}x${large.height / 10} ${large.weight} kg (${
              large.name
          })`
        : ''
}
`;
};

export const getItemInfoHeader = (bookingItem: Bzzt.BookingItem): string => {
    switch (bookingItem.type) {
        case Bzzt.ItemType.Box:
            return capitalize(ItemTypeTranslation[Bzzt.ItemType.Box].singular);
        case Bzzt.ItemType.Parcel:
            return capitalize(ItemTypeTranslation[Bzzt.ItemType.Parcel].singular);
        case Bzzt.ItemType.Bag:
            return capitalize(ItemTypeTranslation[Bzzt.ItemType.Bag].singular);
        case Bzzt.ItemType.Other:
            return capitalize(ItemTypeTranslation[Bzzt.ItemType.Other].singular);
        default:
            throw new Error(`Unable to find item type info header for ${bookingItem.type}`);
    }
};

export const stepOneComplete = (bookingCandidate: Bzzt.BookingCandidate) => {
    return (
        bookingCandidate.items.length > 0 &&
        bookingCandidate.description !== null &&
        bookingCandidate.description !== '' &&
        bookingCandidate.customerReference !== null &&
        bookingCandidate.customerReference !== ''
    );
};

export const stepTwoComplete = (bookingCandidate: Bzzt.BookingCandidate) => {
    return (
        bookingCandidate.pickup !== null &&
        bookingCandidate.pickup.name !== '' &&
        bookingCandidate.pickup.phoneNumber !== '' &&
        bookingCandidate.pickup.address !== '' &&
        bookingCandidate.pickup.instructions !== '' &&
        bookingCandidate.pickup.location !== undefined &&
        !!bookingCandidate.pickup.location.latitude &&
        !!bookingCandidate.pickup.location.longitude
    );
};

export const stepThreeComplete = (bookingCandidate: Bzzt.BookingCandidate) => {
    return (
        bookingCandidate.dropoff !== null &&
        bookingCandidate.dropoff.name !== '' &&
        bookingCandidate.dropoff.phoneNumber !== '' &&
        bookingCandidate.dropoff.address !== '' &&
        bookingCandidate.dropoff.instructions !== '' &&
        bookingCandidate.dropoff.location !== undefined &&
        !!bookingCandidate.dropoff.location.latitude &&
        !!bookingCandidate.dropoff.location.longitude
    );
};

export const stepFourComplete = (bookingCandidate: Bzzt.BookingCandidate) => {
    const hasRequiredTimeFields =
        bookingCandidate.earliestPickupTime !== null &&
        bookingCandidate.earliestPickupTime !== '' &&
        bookingCandidate.latestPickupTime !== null &&
        bookingCandidate.latestPickupTime !== '' &&
        bookingCandidate.earliestDropoffTime !== null &&
        bookingCandidate.earliestDropoffTime !== '' &&
        bookingCandidate.latestDropoffTime !== null &&
        bookingCandidate.latestDropoffTime !== '';

    if (bookingCandidate.view === 'normal') {
        return (
            hasRequiredTimeFields &&
            bookingCandidate.deliveryOfferId !== null &&
            bookingCandidate.deliveryOfferId !== ''
        );
    } else {
        return hasRequiredTimeFields;
    }
};

export const stepFiveComplete = (bookingCandidate: Bzzt.BookingCandidate) => {
    return bookingCandidate.referenceId !== null && bookingCandidate.referenceId !== '';
};

/**
 * Function used when a customer enters our booking flow "from the side";
 * meaning, they type (or click a bookmark) a path to a step in our booking
 * flow in the browser that they may or may not have access to yet due to
 * the state of the booking candidate retrieved from Session Storage in
 * the booking context.
 */
export const deriveBookingFlowStepFromBookingCandidate = (
    bookingCandidate: Bzzt.BookingCandidate
): Bzzt.BookingFlowStep => {
    if (!stepOneComplete(bookingCandidate)) {
        return Bzzt.BookingFlowStep.Delivery;
    }

    if (!stepTwoComplete(bookingCandidate)) {
        return Bzzt.BookingFlowStep.DeliveryPickup;
    }

    if (!stepThreeComplete(bookingCandidate)) {
        return Bzzt.BookingFlowStep.DeliveryDropoff;
    }

    if (!stepFourComplete(bookingCandidate)) {
        return Bzzt.BookingFlowStep.DeliveryTime;
    }

    if (!stepFiveComplete(bookingCandidate)) {
        return Bzzt.BookingFlowStep.DeliveryOverview;
    }

    return Bzzt.BookingFlowStep.DeliveryConfirmation;
};

export const canAccessBookingFlowStep = (
    pathTryingToAccess: Bzzt.BookingFlowStep,
    bookingCandidate: Bzzt.BookingCandidate
): { canAccess: boolean; redirectTo: string | null } => {
    const derivedBookingFlowStepFromBookingCandidate =
        deriveBookingFlowStepFromBookingCandidate(bookingCandidate);
    const derivedBookingFlowStepRank =
        BookingFlowStepOrder[derivedBookingFlowStepFromBookingCandidate];
    const pathTryingToAccessRank = BookingFlowStepOrder[pathTryingToAccess];

    if (pathTryingToAccessRank > derivedBookingFlowStepRank) {
        return {
            canAccess: false,
            redirectTo: derivedBookingFlowStepFromBookingCandidate,
        };
    }

    return {
        canAccess: true,
        redirectTo: null,
    };
};

type ReactRouterLocation = ReturnType<typeof useLocation>;

export const bookingFlowAccessControl = (
    location: ReactRouterLocation,
    bookingCandidate: Bzzt.BookingCandidate
): string | null => {
    const { canAccess, redirectTo } = canAccessBookingFlowStep(
        location.pathname as Bzzt.BookingFlowStep,
        bookingCandidate
    );

    if (canAccess && redirectTo == null) {
        return null;
    }

    return redirectTo;
};

export const getErrorMessage = (data: any): string => {
    if (data.errors) {
        const messages = Object.keys(data.errors).map((key) => data.errors[key]) as Array<string>;
        if (messages[0] && ERROR_MESSAGES[messages[0] as Bzzt.ErrorKey]) {
            return ERROR_MESSAGES[messages[0] as Bzzt.ErrorKey];
        }
    }
    if (data.message) {
        return ERROR_MESSAGES[(data.message.toLowerCase() as Bzzt.ErrorKey) || 'default'];
    }
    return ERROR_MESSAGES.default;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Screen } from '../../structure/screen';
import { ItemTypeTranslation } from '../../utils';
import {
    Root,
    Container,
    Row,
    Value,
    Name,
    BusinessName,
    TopContainer,
    Title,
    Logo,
    GlobalStyle,
} from './print.styles';
import { getBooking } from '../../utils/booking-action';
import { Bzzt } from '../../types';
import { ButtonRow } from '../../fragments/button-row';
import { Button, Variant } from '../../fragments/button';
import { Dropdown } from '../../fragments/dropdown/dropdown';
import { useUserStore } from '../../stores/useUserStore';

interface LocationState {
    bookingCandidate: Bzzt.Booking;
}

interface PrintProps extends RouteComponentProps<{}, {}, LocationState> {}

export const Print: React.FC<PrintProps> = () => {
    const { id }: any = useParams();
    const [booking, setBooking] = useState<Bzzt.Booking | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isPrintOpen, setIsPrintOpen] = useState<boolean>(false);
    const [printOption, setPrintOption] = useState<'' | 'A4 Liggandes' | 'A5 Ståendes'>('');

    /* const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
*/
    const [bookingCandidate, setBookingCandidate] = useState(null);

    const userStore = useUserStore();
    let businessName = userStore.config?.businessName || 'N/A';

    // Determine if the logo should be displayed
    const showLogo = businessName === 'Agnes & Lisa Blommor';

    // If businessName is not 'Agnes & Lisa Blommor', set it to 'Crosstown'
    if (!showLogo) {
        businessName = 'Crosstown';
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const dataParam = params.get('data');
        const printOptionParam = params.get('printOption');

        if (dataParam) {
            try {
                const parsedData = JSON.parse(decodeURIComponent(dataParam));
                setBookingCandidate(parsedData);
            } catch (error) {
                console.error('Error parsing booking candidate:', error);
                // Handle the error, e.g., set a default value or show an error message.
            }
        } else {
            console.error('No data parameter found in URL.');
            // Handle the absence of data parameter if necessary.
        }

        if (printOptionParam) {
            setPrintOption(printOptionParam as 'A4 Liggandes' | 'A5 Ståendes');
        } else {
            console.warn('No printOption parameter found in URL.');
            // You might want to set a default print option or prompt the user.
        }

        setLoading(false);
    }, []);

    const theme = {
        fontWeight: 'bold',
    };

    useEffect(() => {
        if (!booking) {
            // If BookingCandidate is provided, use it directly
            if (bookingCandidate) {
                setBooking(bookingCandidate);
            } else if (id) {
                const fetchBooking = async (reference: string) => {
                    setLoading(true);
                    try {
                        const { data, errorMessage } = await getBooking(reference);
                        if (errorMessage) {
                            // TODO: Handle error
                            setBooking(null);
                        } else if (data) {
                            setBooking(data);
                        }
                    } finally {
                        setLoading(false);
                    }
                };
                fetchBooking(id);
            }
        }
    }, [id, bookingCandidate, booking]);

    // Add the useEffect to open the print dialog when conditions are met
    useEffect(() => {
        if (!loading && booking && !isPrintOpen && printOption !== '') {
            window.print();
            setIsPrintOpen(true);
        }
    }, [loading, booking, isPrintOpen, printOption]);

    const handlePrintOptionSelected = (option: 'A4 Liggandes' | 'A5 Ståendes') => {
        setPrintOption(option);
        // Wait for state update before printing
        setTimeout(() => {
            window.print();
            // Reset the printOption to allow re-selecting the same option
            setPrintOption('');
        }, 0);
    };

    return (
        <>
            <GlobalStyle printOption={printOption} />
            <div id="print-container">
                <Screen
                    slim={false}
                    topContent={
                        <>
                            <TopContainer>
                                {showLogo ? (
                                    <Logo src="/agnes-logotype1_540x.png" alt="Logo" />
                                ) : (
                                    <BusinessName>{businessName}</BusinessName>
                                )}
                                <Title>Fraktsedel</Title>
                            </TopContainer>
                        </>
                    }
                    content={
                        <Root>
                            <Container>
                                <Row>
                                    <Name>Leveransdag:</Name>
                                    <Value>
                                        {new Date(
                                            booking?.dropoffTime ||
                                                booking?.earliestDropoffTime ||
                                                0
                                        ).toLocaleDateString()}
                                    </Value>
                                </Row>
                                <Row>
                                    <Name>Orderid/referens:</Name>
                                    <Value>
                                        {booking?.externalOrderId ??
                                            (booking as any)?.customerReference ??
                                            ''}
                                    </Value>
                                </Row>
                                {booking &&
                                    Object.entries(booking.items).map(([slug, item]) => {
                                        const itemType =
                                            (item.type && ItemTypeTranslation[item.type]?.plural) ||
                                            '';
                                        const itemSize = item.size
                                            ? item.size.substring(0, 1).toUpperCase()
                                            : '';

                                        return (
                                            <Row key={`item-${slug}`}>
                                                <Name>{`Antal ${itemType}:`}</Name>
                                                <Value>
                                                    {itemSize} {item.count}
                                                </Value>
                                            </Row>
                                        );
                                    })}
                            </Container>
                            <Container>
                                <Row>
                                    <Name theme={theme}>Hämtas:</Name>
                                </Row>
                                <Row>
                                    <Name>Tid:</Name>
                                    <Value>
                                        {new Date(
                                            booking?.earliestPickupTime || 0
                                        ).toLocaleTimeString([], {
                                            timeStyle: 'short',
                                        })}{' '}
                                        -{' '}
                                        {new Date(
                                            booking?.latestPickupTime || 0
                                        ).toLocaleTimeString([], {
                                            timeStyle: 'short',
                                        })}
                                    </Value>
                                </Row>
                                <Row>
                                    <Name>Namn/Företag:</Name>
                                    <Value>{booking?.pickup?.name ?? ''}</Value>
                                </Row>
                                <Row>
                                    <Name>Telefonnummer:</Name>
                                    <Value>{booking?.pickup?.phoneNumber ?? ''}</Value>
                                </Row>
                                <Row>
                                    <Name>Adress:</Name>
                                    <Value>{booking?.pickup?.address ?? ''}</Value>
                                </Row>
                                <Row>
                                    <Name>Instruktioner:</Name>
                                    <Value>{booking?.pickup?.instructions ?? ''}</Value>
                                </Row>
                            </Container>
                            <Container>
                                <Row>
                                    <Name theme={theme}>Lämnas:</Name>
                                </Row>
                                <Row>
                                    <Name>Beräknad tid:</Name>
                                    <Value>
                                        {new Date(
                                            booking?.earliestDropoffTime || 0
                                        ).toLocaleTimeString([], {
                                            timeStyle: 'short',
                                        })}{' '}
                                        -{' '}
                                        {new Date(
                                            booking?.latestDropoffTime || 0
                                        ).toLocaleTimeString([], {
                                            timeStyle: 'short',
                                        })}
                                    </Value>
                                </Row>
                                <Row>
                                    <Name>Namn/företag:</Name>
                                    <Value>{booking?.dropoff?.name ?? ''}</Value>
                                </Row>
                                <Row>
                                    <Name>Telefonnummer:</Name>
                                    <Value>{booking?.dropoff?.phoneNumber ?? ''}</Value>
                                </Row>
                                <Row>
                                    <Name>Gatuadress:</Name>
                                    <Value>{booking?.dropoff?.address ?? ''}</Value>
                                </Row>
                                <Row>
                                    <Name>Instruktioner:</Name>
                                    <Value>{booking?.dropoff?.instructions ?? ''}</Value>
                                </Row>
                            </Container>
                        </Root>
                    }
                    actionRowContent={
                        <ButtonRow>
                            <Button
                                onClick={() => handlePrintOptionSelected('A4 Liggandes')}
                                variant={Variant.Primary}
                            >
                                Skriv ut
                            </Button>
                            <Dropdown
                                options={['Välj utskriftsformat', 'A4 Liggandes', 'A5 Ståendes']}
                                value={printOption}
                                defaultOption="Välj utskriftsformat"
                                onChange={(e) => {
                                    const selectedOption = e.target.value as
                                        | 'A4 Liggandes'
                                        | 'A5 Ståendes';
                                    handlePrintOptionSelected(selectedOption);
                                }}
                            />
                        </ButtonRow>
                    }
                />
            </div>
        </>
    );
};
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Route, Switch, Redirect } from 'react-router-dom';
import { Bookings as BookingsScreen } from '../screens/bookings';
import { Contacts as ContactsScreen } from '../screens/contacts';
import { Time } from '../screens/time';
import { Dropoff } from '../screens/dropoff';
import { Pickup } from '../screens/pickup';
import { Layout } from '../structure/layout';

import { Delivery as DeliveryScreen } from '../screens/delivery';
import { DeliveryBookingOverview } from '../screens/overview';
import { Confirmation } from '../screens/confirmation';
import { BookingDetails } from '../screens/booking';
import { EditContact } from '../screens/contacts/edit-contact';
import { AddContact } from '../screens/contacts/add-contact';
import { BookingFlowStep } from '../types/bzzt';
import { Print } from '../screens/print';
import { Page } from '../screens/page';

export function Authenticated() {
    return (
        <Layout>
            <Switch>
                <Route
                    exact
                    path="/"
                    component={() => <Redirect to={BookingFlowStep.Delivery} />}
                />
                <Route path={BookingFlowStep.Delivery} exact component={DeliveryScreen} />
                <Route path={BookingFlowStep.DeliveryPickup} exact component={Pickup} />
                <Route path={BookingFlowStep.DeliveryDropoff} exact component={Dropoff} />
                <Route path={BookingFlowStep.DeliveryTime} exact component={Time} />
                <Route
                    path={BookingFlowStep.DeliveryOverview}
                    exact
                    component={DeliveryBookingOverview}
                />
                <Route path={BookingFlowStep.DeliveryConfirmation} exact component={Confirmation} />
                <Route path="/bookings" exact component={BookingsScreen} />
                <Route path="/bookings/:id" exact component={BookingDetails} />
                <Route path="/contacts" exact component={ContactsScreen} />
                <Route path="/print/:id?" exact component={Print} />
                <Route path="/contacts/:id/edit" exact component={EditContact} />
                <Route path="/contacts/add" exact component={AddContact} />
                <Route path="/page/:slug" exact component={Page} />
            </Switch>
        </Layout>
    );
}

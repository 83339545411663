import { ERROR_MESSAGES } from '../constants';

export enum BookingFlowStep {
    Delivery = '/delivery',
    DeliveryPickup = '/delivery/pickup',
    DeliveryDropoff = '/delivery/dropoff',
    DeliveryTime = '/delivery/time',
    DeliveryConfirmation = '/delivery/confirmation',
    DeliveryOverview = '/delivery/overview',
}

export enum ItemType {
    Box = 'box',
    Bag = 'bag',
    Other = 'other',
    Parcel = 'parcel',
}

export enum ItemSize {
    S = 'small',
    M = 'medium',
    L = 'large',
}

export type Item = {
    slug: string;
    name: string;
    height: number;
    width: number;
    weight: number;
    size: ItemSize;
    stackable: boolean;
    length: number;
    keepUpright: boolean;
};

export type ItemsRecord = Record<string, { type: ItemType; size: ItemSize; count: number }>;

export type Location = {
    latitude: number;
    longitude: number;
};

export interface Contact {
    id?: string;
    name: string;
    phoneNumber: string;
    instructions: string;
    address: string;
    location?: Location;
}

export type DeliveryOffer = {
    id: string;
    active: boolean;
    message: string;
    earliestPickupTime: string;
    latestPickupTime: string;
    earliestDropoffTime: string;
    latestDropoffTime: string;
    priceCategory: string;
};

export type OfferSearchWindow = {
    start: Date | null;
    end: Date | null;
};

export type BookingCandidateItem = {
    type: ItemType;
    size: ItemSize;
    count: number;
};

export type BookingCandidateView = 'normal' | 'detailed';

export type BookingCandidate = {
    // Step 1
    items: BookingCandidateItem[];
    description: string | null;
    customerReference: string | null;
    // Step 2
    pickup: Contact | null;
    // Step 3
    dropoff: Contact | null;
    // Step 4
    view: BookingCandidateView;
    earliestPickupTime: string | null;
    latestPickupTime: string | null;
    earliestDropoffTime: string | null;
    latestDropoffTime: string | null;
    deliveryOfferId: string | null;
    // Step 5
    referenceId: string | null;
};

export type BookingItem = {
    type: ItemType;
    sizes: ItemSize[];
};

export type Booking = {
    user: string;
    type: string;
    state: string;
    state_translated: string;
    reference: string;
    pickupAt: string;
    pickup: Contact;
    items: ItemsRecord;
    location: string;
    latestPickupTime: string;
    latestDropoffTime: string;
    externalOrderId: string;
    estimatedDropoffTime: string;
    earliestPickupTime: string;
    earliestDropoffTime: string;
    dropoffTime: string | null;
    dropoff: Contact;
    bookedAt: string;
};

export enum AuthStatus {
    UNAUTHENTICATED,
    AUTHENTICATED,
    PENDING,
    ERROR,
}

export interface Config {
    defaultBookingView?: string;
    [key: string]: any;
}

export type UserConfig = {
    businessName: string;
    config: Config;
    featureFlags: Record<string, any>;
};

export type ErrorKey = keyof typeof ERROR_MESSAGES;

export type TimePickerOption = {
    label: string;
    value: string;
};

export type DatePickerOption = {
    label: string;
    value: string;
};

export type TimeFormField = {
    date: Date;
    options: TimePickerOption[];
};

export type DeliveryDateState = TimeFormField;

export type OfferSearchWindowState = {
    start: TimeFormField;
    end: TimeFormField;
};

export type BookingDetailedState = {
    earliestPickupTime: TimeFormField;
    latestPickupTime: TimeFormField;
    earliestDropoffTime: TimeFormField;
    latestDropoffTime: TimeFormField;
};

export type BookingTimeFormState = {
    view: BookingCandidateView;
    deliveryDate: DeliveryDateState;
    offerSearchWindow: OfferSearchWindowState;
    earliestPickupTime: TimeFormField;
    latestPickupTime: TimeFormField;
    earliestDropoffTime: TimeFormField;
    latestDropoffTime: TimeFormField;
};

import { Root } from './info-button.styles';

interface InfoIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isActivated?: boolean;
    children?: string;
}

export function InfoButton(props: InfoIconProps) {
    const { isActivated = false, children, ...rest } = props;
    return (
        <Root isActivated={isActivated} {...rest}>
            {children}
        </Root>
    );
}

import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { AuthStatus, UserConfig } from '../types/bzzt';
import simpleApi from '../utils/simple-api/simple-api';

type UserState = {
    status: AuthStatus;
    token: string | null;
    error: Record<string, any> | null;
    email: string | null;
    config: UserConfig | null;
};

type UserActions = {
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
    authenticate: (token: string) => Promise<void>;
    unauthenticate: () => void;
    unauthenticated: () => boolean;
    authenticated: () => boolean;
};

const initialState: UserState = {
    status: AuthStatus.UNAUTHENTICATED,
    token: null,
    error: null,
    email: null,
    config: null,
};

export const useUserStore = create<UserState & UserActions>()(
    persist(
        (set, get) => ({
            ...initialState,
            authenticated: () => {
                return get().status === AuthStatus.AUTHENTICATED && get().token !== null;
            },
            unauthenticated: () => {
                return get().status === AuthStatus.UNAUTHENTICATED && !get().token;
            },
            unauthenticate: () => set({ status: AuthStatus.UNAUTHENTICATED }),
            login: async (email: string, password: string) => {
                try {
                    set({ status: AuthStatus.PENDING });
                    const loginResponse = await simpleApi.login(email, password);
                    if (loginResponse.status !== 200) {
                        return set({ status: AuthStatus.ERROR });
                    }
                    const { token } = loginResponse.data as { token: string };
                    const userConfigResponse = await simpleApi.getUserConfig(token);
                    if (userConfigResponse.status !== 200) {
                        return set({ status: AuthStatus.ERROR });
                    }

                    const newState = {
                        status: AuthStatus.AUTHENTICATED,
                        token,
                        email,
                        config: userConfigResponse.data,
                    };

                    return set(newState);
                } catch (err) {
                    console.error(err);
                    return set({ status: AuthStatus.ERROR });
                }
            },
            logout: () => {
                set(initialState);
            },
            authenticate: async (token: string) => {
                // TODO: update this endpoint to also return email
                const authenticatedResponse = await simpleApi.authenticated(token);
                if (authenticatedResponse.status !== 200) {
                    set(initialState);
                    throw new Error('Failed to authenticate');
                }

                return set({ token, config: authenticatedResponse.data });
            },
        }),
        {
            name: 'userStore',
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);

import { useState } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { Text } from '../../../app.styles';
import { Button, Variant } from '../../../fragments/button';
import { ButtonRow } from '../../../fragments/button-row';
import { Icon } from '../../../fragments/icon';
import { InfoPopup } from '../../../fragments/info-popup';
import { Search } from '../../../fragments/search';
import { TextInput } from '../../../fragments/text-input';
import { Title } from '../../../fragments/title';
import { Screen } from '../../../structure/screen';
import { HoverInfo } from '../../../fragments/hover-info';
import { SelectedContainer } from '../../pickup/pickup.styles';
import { Root } from './add-contact.styles';
import { Bzzt } from '../../../types';
import { ERROR_MESSAGES } from '../../../constants';
import restApi from '../../../utils/simple-api/rest-api';

interface RouteProps extends RouteComponentProps<{}>, React.Props<{}> {}

export const AddContact = ({}: RouteProps) => {
    const history = useHistory();
    const [formState, setFormState] = useState<{
        name: string;
        phoneNumber: string;
        instructions: string;
        address: string;
        location: Bzzt.Location | null;
    }>({
        name: '',
        phoneNumber: '',
        instructions: '',
        address: '',
        location: null,
    });

    const [warningModalVisible, setWarningModalVisible] = useState(false);

    const toggleWarningModal = () => {
        setWarningModalVisible((curr: boolean) => !curr);
    };

    const containsNumber = (value: string) => /.*[0-9].*/.test(value);

    const handleSearchSelect = (typeOfItem: 'contact' | 'place', item: any) => {
        setFormState({
            ...formState,
            address: item.address,
            location: { latitude: item.lat(), longitude: item.lng() },
        });
    };

    const handleNameChange = (e: any) => {
        setFormState({ ...formState, name: e.target.value });
    };

    const handlePhoneNumberChange = (e: any) => {
        setFormState({ ...formState, phoneNumber: e.target.value });
    };

    const handleInstructionsChange = (e: any) => {
        setFormState({ ...formState, instructions: e.target.value });
    };

    const canSubmit = () => {
        return (
            formState.phoneNumber !== '' &&
            formState.name !== '' &&
            formState.address !== '' &&
            containsNumber(formState.address) &&
            formState.location !== null &&
            formState.instructions !== ''
        );
    };

    const onSubmitClicked = async () => {
        if (canSubmit()) {
            await restApi.createContact({
                name: formState.name,
                phoneNumber: formState.phoneNumber,
                instructions: formState.instructions,
                address: formState.address,
                location: formState.location ?? undefined,
            });
            history.goBack();
        }
    };

    return (
        <Screen
            topContent={<Title marginTop={'1rem'}>Lägg till kontakt.</Title>}
            content={
                <Root>
                    <Search
                        placeholder="Sök plats"
                        name="search"
                        hideContacts
                        onSelect={handleSearchSelect}
                    />

                    <SelectedContainer>
                        <Icon type="marker" size="xs" />
                        <Text disabled>{formState.address || 'Sök och välj en address'}</Text>
                        {formState.address && containsNumber(formState.address) === false ? (
                            <HoverInfo
                                header={'Obs!'}
                                inline
                                icon="!"
                                text={ERROR_MESSAGES.address_must_contain_number}
                                onMouseOver={() => {}}
                                onMouseLeave={() => {}}
                            />
                        ) : null}
                    </SelectedContainer>
                    <TextInput
                        placeholder="Namn/Företag:"
                        value={formState.name}
                        onChange={handleNameChange}
                    />
                    <TextInput
                        placeholder="Telefonnummer:"
                        value={formState.phoneNumber}
                        onChange={handlePhoneNumberChange}
                    />
                    <TextInput
                        placeholder="Upphämtningsinstruktioner:"
                        value={formState.instructions}
                        onChange={handleInstructionsChange}
                    />
                    {warningModalVisible && (
                        <InfoPopup
                            header={'Obs!'}
                            text={ERROR_MESSAGES.address_must_contain_number}
                            closePress={toggleWarningModal}
                        />
                    )}
                </Root>
            }
            actionRowContent={
                <ButtonRow>
                    <Button variant={Variant.Primary} onClick={() => history.goBack()}>
                        Avbryt
                    </Button>
                    <Button disabled={canSubmit() === false} onClick={onSubmitClicked}>
                        Spara
                    </Button>
                </ButtonRow>
            }
        />
    );
};

import styled from 'styled-components';
import { colors } from '../../colors';

export const Root = styled.div<{ isActivated: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 100%;
    max-height: 85px;
    font-size: 1.4rem;
    padding: 0.5rem; /* Adjust padding as needed */
    border-radius: 0.8rem;
    background-color: ${colors.black};
    color: ${colors.white};
    font-family: 'Russo One';
    cursor: pointer;

    &:before {
        content: '\u25BE'; /* Unicode character for a down arrow */
        font-size: 1.4rem;
    }

    select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    :hover,
    :focus-within {
        background-color: ${colors.black};
        color: ${colors.white};
    }
`;
